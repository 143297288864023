/* eslint-disable react/no-danger */
import * as React from 'react';
import * as styles from './AboutBuilder.css';

interface IAboutBuilderProps {
  content: string;
  title?: string;
}

export function AboutBuilder({ title, content }: IAboutBuilderProps) {
  return (
    <div className={styles['container']}>
      {title && <h3 className={styles['title']}>{title}</h3>}
      <div className={styles['description']} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
