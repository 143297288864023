import * as React from 'react';
import { useSelector } from 'react-redux';
import { PromoActionsLayout } from '../../components/PromoActionsLayout';
import { getIsPromoPage } from '../../selectors/specialPromo';
import { ScrollTop } from '../ScrollTop';

export const PromoActionsContainer = () => {
  const isPromoPage = useSelector(getIsPromoPage);

  if (!isPromoPage) {
    return null;
  }

  return (
    <PromoActionsLayout>
      <ScrollTop />
    </PromoActionsLayout>
  );
};
