import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';

export function trackPreInfiniteSaveSearchShow() {
  ca('eventSite', {
    action: 'show_saved_search_infinite',
    category: 'ListingJK',
    name: 'oldevent',
    sc: jsonQueryHelper.dump(),
  });
}
