import * as React from 'react';

import * as styles from './styles.css';
import { ActionLink, UIText1, UIText2 } from '@cian/ui-kit';

interface IRCOfferBlockProps {
  count: number;
  promoColor?: string;
  subTitle?: string;
  title: string;
}

export function RCOfferBlock({ count, promoColor, subTitle, title }: IRCOfferBlockProps) {
  const style = React.useMemo(
    () => ({
      color: promoColor || `var(--text-main-default)`,
    }),
    [promoColor],
  );

  return (
    <>
      <div className={styles['content']}>
        <div style={style} data-testid="OffersBlockTitle">
          <ActionLink color="current_color">{title}</ActionLink>
        </div>
        {subTitle && <UIText2 color="text-secondary-default">{subTitle}</UIText2>}
      </div>
      <UIText1 color="text-secondary-default" verticalAlign="middle">
        {count}
      </UIText1>
    </>
  );
}
