import { getConfig } from '@cian/config/browser';
import { getLogger } from '@cian/logger/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { registry } from '@cian/mf-registry/browser';

export const createContext = () => {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    custom: {
      abGroup: config.getStrict<number>('newbuilding-search-mobile-frontend.abGroup'),
      subdomain: config.getStrict<string>('newbuilding-search-mobile-frontend.subdomain'),
    },
    microfrontendsRegistry: registry(),
  };
};
