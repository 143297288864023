import { IResponseDataSchema } from '../repositories/search-engine/entities/response/ResponseDataSchema';
import { ISeoData } from '../types/seoData';
import { mapNullToVoid } from '../utils/mapNullToVoid';
import { IHelmetScript } from '../types/helmet';
import { prepareMarkedList } from './prepareMarkedList';

export const prepareSeoData = (data: IResponseDataSchema, scripts?: IHelmetScript[]): ISeoData => {
  const { seoData, markedList } = data;
  const meta = seoData.meta || {};

  const metaTags = {
    description: meta.description,
    keywords: meta.keywords,
    noindex: meta.noindex || false,
  };

  const links = {
    canonical: meta.canonical,
  };

  const seoSchemas = {
    mortgageDiscount: seoData.seoSchemas?.mortgageDiscount || '',
    onlineShow: seoData.seoSchemas?.onlineShow || '',
    organizationInfo: seoData.seoSchemas?.organizationInfo || '',
    product: seoData.seoSchemas?.product || '',
    wideSelection: seoData.seoSchemas?.wideSelection || '',
  };

  return mapNullToVoid({
    title: meta.title || 'ЦИАН – база недвижимости',
    h1: seoData.h1,
    subtitle: seoData.subtitle,
    subtitles: seoData.subtitles,
    bottomText: seoData.bottomText,
    offersCountTitle: seoData.offersCountTitle,
    metaTags: mapNullToVoid(metaTags),
    links: mapNullToVoid(links),
    scripts: scripts || [],
    markedList: markedList ? prepareMarkedList(markedList) : null,
    seoSchemas,
  });
};
