import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { Button, ArticleHeading1, ArticleParagraph2 } from '@cian/ui-kit';
import * as styles from './NewbuildingPromosBanner.css';

export interface INewbuildingPromosBannerProps {
  description: string;
  onClick?(event: React.MouseEvent): void;
  onShow?(): void;
  title: string;
  url: string;
}

export const NewbuildingPromosBanner: React.FC<INewbuildingPromosBannerProps> = ({
  description,
  onClick,
  onShow,
  title,
  url,
}) => {
  const handleShow = React.useCallback(() => {
    if (onShow) {
      onShow();
    }
  }, [onShow]);

  return (
    <ActionAfterViewObserver percentVisible={0.8} callback={handleShow} triggerOnce>
      <a className={styles['container']} href={url} rel="noopener noreferrer" target="_blank" onClick={onClick}>
        <div className={styles['content']}>
          <div className={styles['title']}>
            <ArticleHeading1 as="h2">{title}</ArticleHeading1>
          </div>
          <div className={styles['description']}>
            <ArticleParagraph2>{description}</ArticleParagraph2>
          </div>
        </div>
        <div className={styles['button']}>
          <Button theme="fill_primary" type="button" fullWidth={true} size="M">
            Смотреть предложения
          </Button>
        </div>
      </a>
    </ActionAfterViewObserver>
  );
};
