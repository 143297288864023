import { createReduxStore } from '../../../shared/redux';
import { IApplicationState, IReduxStore } from '../../../shared/types/redux';
import { fetchSearchInitialState } from './internal';
import { IApplicationContext } from '../../../shared/types/application';

let store: IReduxStore;

export function createApplicationState(context: IApplicationContext) {
  if (store) {
    return store;
  }

  const projectName = context.config.getStrict<string>('projectName');
  const initialState = context.config.getStrict<IApplicationState>(`${projectName}.serp.initialState`);

  store = createReduxStore(initialState, context);

  return store;
}

export async function createApplicationStateLazy(context: IApplicationContext) {
  if (store) {
    return store;
  }

  const path = location.pathname + location.search;

  try {
    const initialState = await fetchSearchInitialState(context, { path });
    store = createReduxStore(initialState, context);

    return store;
  } catch (error) {
    context.logger.error(error);
    window.location.reload();

    // throw необходим для TS, иначе он считает, потому что он не понимает, что страница
    // будет перезагружена
    throw error;
  }
}

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../../../shared/reducers', () => {
    const reducers = require('../../../shared/reducers');

    if (store) {
      store.replaceReducer(reducers);
    }
  });
}
