import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

import {
  TGetSaveSearchMicrofrontendModel,
  IGetSaveSearchMicrofrontendRequest,
  TGetSaveSearchMicrofrontendResponse,
  IMappers,
} from './types';

const defaultConfig: TGetSaveSearchMicrofrontendModel = {
  apiType: 'public',
  assertStatusCodes: [200],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'save-search-frontend',
  pathApi: '/v1/get-save-search-microfrontend/',
} as TGetSaveSearchMicrofrontendModel;

function createGetSaveSearchMicrofrontendModel(
  parameters: IGetSaveSearchMicrofrontendRequest,
): TGetSaveSearchMicrofrontendModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetHeaderMicrofrontendOptions<TResponse200> {
  httpApi: IHttpApi;
  parameters: IGetSaveSearchMicrofrontendRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
}

async function fetchGetSaveSearchMicrofrontend<TResponse200>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetHeaderMicrofrontendOptions<TResponse200>): Promise<TResponse200 | TGetSaveSearchMicrofrontendResponse> {
  const { response, statusCode, headers } = await httpApi.fetch(
    createGetSaveSearchMicrofrontendModel(parameters),
    config,
  );

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response);
    }
  }

  return { response, statusCode, headers } as TGetSaveSearchMicrofrontendResponse;
}

export { defaultConfig, createGetSaveSearchMicrofrontendModel, fetchGetSaveSearchMicrofrontend };
