import * as React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../selectors/user';
import { BuilderChatButton } from '../../components/BuilderChatButton';
import { getAuthor, getGaLabel } from '../../selectors/rcs';
import { trackSendMessageClick } from './tracking';
import { useBuilderChat } from '../../hooks';
import { yieldToMainThread } from '../../utils/yieldToMainThread';

interface IBuilderChatButtonContainerProps {
  id: number;
  defaultMessage?: string;
}

export const BuilderChatButtonContainer: React.FC<IBuilderChatButtonContainerProps> = ({ id, defaultMessage }) => {
  const user = useSelector(userSelector);
  const gaLabel = useSelector(getGaLabel(id));
  const realtyUserId = useSelector(getAuthor(id)).builderRealtyUserId;
  const isOwnNewbuilding = realtyUserId === (user.isAuthenticated && user.userId);
  const { openChat } = useBuilderChat();

  const handleClick = React.useCallback(async () => {
    openChat({ realtyUserId, gaLabel, defaultMessage });
    await yieldToMainThread();
    trackSendMessageClick({ label: gaLabel });
  }, [defaultMessage, gaLabel, openChat, realtyUserId]);

  if (isOwnNewbuilding) {
    return null;
  }

  return <BuilderChatButton onClick={handleClick} />;
};
