import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import { IMortgageSerpBannerResponse, TMortgageSerpBannerModel } from './types';
import { jsonQueryHelper } from '../../../../shared/utils/jsonQuery/createJsonQuery';

const defaultConfig: IHttpApiFetchConfig = {
  requestConfig: {
    withCredentials: true,
    headers: [['content-type', 'application/json']],
  },
  ecnhancersConfig: {
    timeout: 2000,
  },
};

const defaultModel: TMortgageSerpBannerModel = {
  apiType: 'public',
  assertStatusCodes: [200],
  method: 'POST',
  microserviceName: 'mortgageSerpBanner',
  pathApi: '/stub',
};

export async function fetchMortgageSerpBannerContent(
  httpApi: IHttpApi,
  position: number,
): Promise<IMortgageSerpBannerResponse> {
  const model: TMortgageSerpBannerModel = {
    ...defaultModel,
    pathApi: '/stub/?place=' + position,
    parameters: jsonQueryHelper.dump(),
  };

  return httpApi.fetch(model, defaultConfig);
}
