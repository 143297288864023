export enum ESortValue {
  Default = 'default',
  Price = 'price_object_order',
  SquarePrice = 'price_square_order',
  Area = 'area_order',
  LandArea = 'site_order',
  WalkingTime = 'walking_time',
  MKAD = 'mcad',
  StreetName = 'street_name',
  UserId = 'id_user',
}
