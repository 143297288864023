import * as React from 'react';

import * as style from './style.css';

interface INewbuildingPromoCarouselLayoutProps {
  children?: React.ReactNode;
}

export function NewbuildingPromoCarouselLayout({ children }: INewbuildingPromoCarouselLayoutProps) {
  return <div className={style['container']}>{children}</div>;
}
