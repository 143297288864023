import * as React from 'react';
import * as style from './style.css';
import { Loader } from '../Loader';

/**
 * Количество карточек с лоадером
 * Для оптимизации CLS, достаточно чтобы пользователь не прокрутил страницу ниже них до окончания загрузки новой порции данных
 **/
const LOADER_CARDS_COUNT = 5;

export const PaginationLoader = () => {
  const cards = React.useMemo(() => {
    return new Array(LOADER_CARDS_COUNT).fill(null);
  }, []);

  return (
    <>
      {cards.map((_, index) => (
        <div className={style['card']} key={`loader_${index}`}>
          <Loader size={16} />
        </div>
      ))}
    </>
  );
};
