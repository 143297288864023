import * as React from 'react';
import { Author } from '../../components/Author/Author';
import { useSelector } from 'react-redux';
import { getAuthor } from '../../selectors/rcs';
import { IAuthor } from '../../types/author';
import { UIText3 } from '@cian/ui-kit';
import { useApplicationContext } from '../../utils/applicationContext';
import { trackTransferToDeveloperCard } from './tracking';
export interface IAuthorContainerProps {
  id: number;
}

export const AuthorContainer = ({ id }: IAuthorContainerProps) => {
  const author: IAuthor = useSelector(getAuthor(id));

  const { config } = useApplicationContext();
  const isDevCardEnabled = config.get<boolean>('builders.DeveloperCardRedesign.Enabled');
  const devCardUrl = React.useMemo(() => (isDevCardEnabled ? author.builderPageUrl : undefined), [isDevCardEnabled]);

  if (!author || !author.name) {
    return null;
  }

  return (
    <Author
      img={author.avatar}
      name={author.name}
      type={author.fromDeveloper && <UIText3 color="gray60_100">{author.fromDeveloper}</UIText3>}
      url={devCardUrl}
      onClick={trackTransferToDeveloperCard}
    />
  );
};
