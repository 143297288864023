import { getTitle } from '../../selectors/seoData';
import { TThunkAction } from '../../types/redux';

export function replaceBrowserLocation(): TThunkAction {
  return (dispatch, getState) => {
    if (typeof window === 'undefined') {
      return;
    }

    const state = getState();
    const title = getTitle(state);

    window.history.replaceState({}, title);
  };
}
