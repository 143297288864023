/* eslint-disable react/no-danger */
import * as React from 'react';
import { IconActionChevronUpSmall16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'clsx';
import * as DOMPurify from 'isomorphic-dompurify';

import * as styles from './SeoMarkedListItem.css';

export interface ISeoMarkedListItemProps {
  id: string;
  title: string;
  content: string;
  expandedByDefault: boolean;
}

export const SeoMarkedListItem: React.VFC<ISeoMarkedListItemProps> = ({ id, title, content, expandedByDefault }) => {
  const [isExpanded, setExpanded] = React.useState<boolean>(expandedByDefault);
  const headingId = `${id}_heading`;
  const bodyId = `${id}_body`;

  const toggleExpanded = React.useCallback(() => setExpanded(prev => !prev), []);

  return (
    <div className={classNames(styles['container'], !isExpanded && styles['closed'])} data-testid="SeoMarkedListItem">
      <div
        id={headingId}
        role="button"
        aria-expanded={isExpanded}
        aria-controls={bodyId}
        tabIndex={0}
        className={styles['heading']}
        onClick={toggleExpanded}
      >
        <div className={styles['title']} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
        <div className={styles['arrow-wrapper']}>
          <IconActionChevronUpSmall16 />
        </div>
      </div>
      <div
        id={bodyId}
        aria-describedby={headingId}
        className={styles['content']}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      />
    </div>
  );
};
