import { IApplicationState } from '../../types/redux';

const URL = '/cat.php/?deal_type=sale&engine_version=2&from_developer=1&offer_type=flat&is_discount_mortgage=1';

export const getMortgageBannerUrl = (state: IApplicationState): string => {
  const region = state.location.jsonQuery.region;
  const regionId = region?.value ? region.value[0] : undefined;

  return regionId ? `${URL}&region=${regionId}` : URL;
};
