import * as React from 'react';
import { Button } from '@cian/ui-kit';

interface IBuilderChatButtonProps {
  onClick: () => void;
}

export const BuilderChatButton: React.FC<IBuilderChatButtonProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick} size="M" theme="fill_secondary" type="button" fullWidth>
      Написать
    </Button>
  );
};
