import * as React from 'react';
import * as styles from './author.css';
import { Avatar, UIHeading5, UIText3 } from '@cian/ui-kit';

interface IAuthorProps {
  img?: string | null;
  name: string;
  type: React.ReactNode;
  url?: string;
  onClick(): void;
}
export const Author = ({ img, name, type, url, onClick }: IAuthorProps) => {
  return (
    <a className={styles['container']} href={url} target="_blank" rel="noreferrer" onClick={onClick}>
      <div className={styles['media']}>
        <Avatar type="builder" size={32} src={img} />
      </div>
      <div className={styles['description']}>
        <UIHeading5>{name}</UIHeading5>
        <UIText3 color="gray60_100">{type}</UIText3>
      </div>
    </a>
  );
};
