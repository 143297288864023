import * as React from 'react';
import { IconProductSaveSearch16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from '../styles.css';

export interface ISavedSearchButtonProps {
  onClick(visible: boolean): void;
}

export function SavedSearchButton({ onClick }: ISavedSearchButtonProps) {
  return (
    <button type="button" className={styles['button']} onClick={() => onClick(true)}>
      <IconProductSaveSearch16 color="icon-main-default" />
      <span>Сохранить</span>
    </button>
  );
}
