import * as React from 'react';

import { ISpecialPromoBanner } from '../../types/specialPromo';
import { PromoBannerSlide } from './components/PromoBannerSlide';
import { PromoSliderLoadable } from '../PromoSlider';

import * as styles from './PromoBannerSlider.css';

interface IPromoBannerSliderProps {
  bannersList: ISpecialPromoBanner[];
  logoUrl?: string;
}

export function PromoBannerSlider({ bannersList, logoUrl }: IPromoBannerSliderProps) {
  return (
    <PromoSliderLoadable autoplay dots>
      {bannersList.map(banner => (
        <div className={styles['container']} key={`banner_${banner.id}`}>
          <PromoBannerSlide banner={banner} logoUrl={logoUrl} />
        </div>
      ))}
    </PromoSliderLoadable>
  );
}
