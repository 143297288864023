import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';

export const getIsFavorite =
  (id: number) =>
  (state: IApplicationState): boolean => {
    const rc = selectRCById(state, id);

    return rc.isFavorite;
  };
