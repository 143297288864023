import { getConfig } from '@cian/config/browser';

import { IJsonQuery } from '../../../shared/types/jsonQuery';
import { jsonQueryHelper } from '../../../shared/utils/jsonQuery/createJsonQuery';

export function initJsonQuery() {
  const config = getConfig();
  const projectName = config.getStrict<string>('projectName');
  const jsonQuery = config.getStrict<IJsonQuery>(`${projectName}.serp.jsonQuery`);

  jsonQueryHelper.set(jsonQuery);
}
