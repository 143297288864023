import { ILocationState } from '../types/location';
import { IJsonQuery } from '../types/jsonQuery';
import { IResponseDataSchema } from '../repositories/search-engine/entities/response/ResponseDataSchema';

export const prepareLocation = (data: IResponseDataSchema, subdomain?: string): ILocationState => {
  return {
    jsonQuery: data.jsonQuery as IJsonQuery,
    path: data.path,
    subdomain: subdomain || 'www',
  };
};
