import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';

export const getGaLabel =
  (id: number) =>
  (state: IApplicationState): string => {
    const rc = selectRCById(state, id);

    return rc.gaLabel;
  };
