import { ca } from '@cian/analytics';

export function trackDeleteFavorite(gaLabel: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'remove_from_listing_JK',
    label: gaLabel,
  });
}
