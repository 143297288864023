/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useSelector } from 'react-redux';

import { RCOffers } from '../../components/RCOffers';
import { getPromoColor } from '../../selectors/specialPromo';
import { rcOffersSelector } from '../../selectors/rcs';
import { trackRCPreviewClick } from './analytics';
import { IApplicationState } from '../../types/redux';
import { yieldToMainThread } from '../../utils/yieldToMainThread';

interface IRCOffersContainerProps {
  id: number;
}

export function RCOffersContainer({ id }: IRCOffersContainerProps) {
  const rcOffers = useSelector((state: IApplicationState) => rcOffersSelector(state, id));
  const promoColor = useSelector(getPromoColor);

  const onBlockClick = React.useCallback(async (eventLabel: string) => {
    await yieldToMainThread();
    trackRCPreviewClick(eventLabel);
  }, []);

  if (!rcOffers) {
    return null;
  }

  return <RCOffers {...rcOffers} onBlockClick={onBlockClick} promoColor={promoColor} />;
}
