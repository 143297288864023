import { IconActionSort16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

interface ISortingButton {
  onClick: () => void;
  disabled: boolean;
  text?: string;
}

export function SortingButton({ onClick, disabled, text }: ISortingButton) {
  return (
    <button className={styles['button']} disabled={disabled} onClick={onClick}>
      <span className={styles['text']}>{text}</span>
      <IconActionSort16 color="current_color" />
    </button>
  );
}
