import * as React from 'react';
import { useSelector } from 'react-redux';
import { getMortgageBannerUrl } from '../../selectors/discountMortgage';
import { DiscountMortgageBanner } from '../../components/DiscountMortgageBanner';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { trackBannerClick, trackBannerShow } from './tracking';

export const DiscountMortgageBannerContainer = () => {
  const url = useSelector(getMortgageBannerUrl);

  return (
    <ActionAfterViewObserver callback={trackBannerShow} triggerOnce>
      <DiscountMortgageBanner url={url} onClick={trackBannerClick} />
    </ActionAfterViewObserver>
  );
};
