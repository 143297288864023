import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeBuilderChat } from '../../actions/builderChat';
import { getBuilderChatOpened } from '../../selectors/builderChat';
import { useBuilderChatUrl } from '../../hooks';
import { BuilderChatModal } from '../../components/BuilderChatModal';

export const BuilderChatModalContainer = () => {
  const dispatch = useDispatch();

  const isChatOpened = useSelector(getBuilderChatOpened);
  const chatUrl = useBuilderChatUrl({ isChatOpened });

  const handleClose = React.useCallback(() => dispatch(closeBuilderChat()), [dispatch]);

  if (!isChatOpened) {
    return null;
  }

  return <BuilderChatModal isOpen={isChatOpened} url={chatUrl} onClose={handleClose} />;
};
