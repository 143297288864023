import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { ISeoData } from '../../types/seoData';
import { prepareSeoData } from '../../mappers/prepareSeoData';
import { TReduxActions } from '../../types/redux';

const defaultState: ISeoData = {
  title: '',
  metaTags: {
    noindex: false,
  },
  links: {},
  scripts: [],
  seoSchemas: {
    mortgageDiscount: '',
    onlineShow: '',
    organizationInfo: '',
    product: '',
    wideSelection: '',
  },
};

export function seoDataReducer(state: ISeoData = defaultState, action: TReduxActions): ISeoData {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
    case EPaginationActionTypes.Success:
      return {
        ...prepareSeoData(action.payload),
        scripts: state.scripts,
      };
    default:
      return state;
  }
}
