import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openUpcomingSaleModal } from '../../actions/upcomingSaleRequest';
import { getProduct } from '../../selectors/analytics';
import { getGaLabel, selectRCById } from '../../selectors/rcs';
import { trackUpcomingSaleButtonClick } from './tracking';
import { IApplicationState } from '../../types/redux';
import { Button } from '@cian/ui-kit';

interface IUpcomingSaleRequestButtonProps {
  id: number;
}

export function UpcomingSaleRequestButton({ id }: IUpcomingSaleRequestButtonProps) {
  const newbuilding = useSelector((state: IApplicationState) => selectRCById(state, id));
  const product = useSelector(getProduct(id));
  const gaLabel = useSelector(getGaLabel(id));
  const dispatch = useDispatch();

  const handleClick = React.useCallback(() => {
    trackUpcomingSaleButtonClick({ gaLabel, product });
    dispatch(openUpcomingSaleModal(newbuilding));
  }, [dispatch, newbuilding, gaLabel, product]);

  return (
    <Button size="M" theme="fill_primary" fullWidth onClick={handleClick}>
      Оставить заявку
    </Button>
  );
}
