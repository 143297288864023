import * as React from 'react';
import { ArticleParagraph2 } from '@cian/ui-kit';

import { IconProductHighDemand16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from './DiscountMortgage.css';

export interface IDiscountMortgageProps {
  text: string;
  onClick(): void;
}

export function DiscountMortgage({ text, onClick }: IDiscountMortgageProps) {
  return (
    <div data-testid="DiscountMortgage" className={styles['container']}>
      <div className={styles['container-content']}>
        <div className={styles.icon} data-testid="DiscountMortgage-Icon">
          <IconProductHighDemand16 color="current_color" />
        </div>
        <div className={styles['content']}>
          <div className={styles['text']}>
            <ArticleParagraph2 color="decorative-theme-black">{text}</ArticleParagraph2>
          </div>
          <button data-testid="DiscountMortgageButton" className={styles['button']} onClick={onClick}>
            Подробнее
          </button>
        </div>
      </div>
    </div>
  );
}
