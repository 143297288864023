import * as React from 'react';

import * as styles from './CarouselPhoto.css';

interface ICarouselPhotoProps {
  src: string;
}

const CarouselPhotoComponent: React.FC<ICarouselPhotoProps> = ({ src }) => (
  <div className={styles['container']} style={{ backgroundImage: `url(${src})` }} />
);

export const CarouselPhoto = React.memo(CarouselPhotoComponent);
