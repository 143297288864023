import { UIText3 } from '@cian/ui-kit';
import { IconFunctionalBullet16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as style from './OnlineStatus.css';

interface IOnlineStatusProps {
  isOnline: boolean;
  text: string;
}

export const OnlineStatus: React.FC<IOnlineStatusProps> = ({ isOnline, text }) => {
  return (
    <div className={style['container']} data-testid="OnlineStatus">
      {isOnline && <IconFunctionalBullet16 display="inline-flex" color="icon-positive-default" />}
      <UIText3 display="inline">{text}</UIText3>
    </div>
  );
};
