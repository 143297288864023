import * as React from 'react';

import { AboutBuilderContainer } from '../AboutBuilderContainer';
import { BottomActionsContainer } from '../BottomActionsContainer';
import { BreadcrumbsContainer } from '../BreadcrumbsContainer';
import { ListingContainer } from '../ListingContainer';
import { LazyNewbuildingPromoCarouselContainer } from '../NewbuildingPromoCarousel';
import { OfficesMapContainerLoadable } from '../OfficesMapContainer';
import { PromoActionsContainer } from '../PromoActionsContainer';
import { PromoCallButtonContainerLoadable } from '../PromoCallButtonContainer';
import { QuickLinksContainer } from '../QuickLinksContainer';
import { SalesDescriptionContainer } from '../SalesDescriptionContainer';
import { SeoBlockContainer } from '../SeoBlockContainer';
import { SeoMarkedListContainer } from '../SeoMarkedList';
import { SeoCrossLinksContainer } from '../SeoCrossLinksContainer';
import { DiscountMortgageContainer } from '../DiscountMortgageContainer';

export const MainSection = () => (
  <>
    <QuickLinksContainer />
    <SalesDescriptionContainer />
    <DiscountMortgageContainer />
    <ListingContainer />
    <BottomActionsContainer />
    <LazyNewbuildingPromoCarouselContainer />
    <AboutBuilderContainer />
    <OfficesMapContainerLoadable />
    <PromoCallButtonContainerLoadable />
    <PromoActionsContainer />
    <SeoCrossLinksContainer />
    <SeoMarkedListContainer />
    <BreadcrumbsContainer />
    <SeoBlockContainer />
  </>
);
