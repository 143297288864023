import * as React from 'react';

import * as styles from './PromoTitle.css';

interface IPromoTitleProps {
  isPromo?: boolean;
  title: string;
}

export const PromoTitle: React.FunctionComponent<IPromoTitleProps> = props => {
  const { title } = props;

  return <h1 className={styles['title']}>{title}</h1>;
};
