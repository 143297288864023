import { IAdFoxBannerOnErrorError, IAdFoxBannerOnLoadData } from '@cian/adfox-component';
import { useBooleanState } from '@cian/react-utils';

import * as React from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';

type AdfoxBannerContainerProps = {
  render?: ({ children }: { children: JSX.Element }) => JSX.Element;
  galleryPosition?: number;
  position?: number;
  onError?(error: IAdFoxBannerOnErrorError): void;
  onLoad?(data: IAdFoxBannerOnLoadData): void;
  onStub?(): void;
  onRender?(): void;
  onView?(): void;
};

export const AdfoxBannerContainer = React.memo<AdfoxBannerContainerProps>(function AdfoxBannerContainer(props) {
  const { position, galleryPosition, onStub, onError, onLoad, onRender, onView, render } = props;

  const { state: isBannerHidden, setTrue: setBannerHidden } = useBooleanState();
  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  return React.useMemo<JSX.Element | null>(() => {
    if (isBannerHidden) {
      return null;
    }

    const Banner = withAdfoxPostViewAnalytics(AdfoxBanner, {
      ...postViewAnalyticsData,
      galleryPosition,
      position,
      onView,
    });

    const bannerElement = (
      <Banner
        {...size}
        params={params}
        onRender={onRender}
        onStub={() => {
          onStub?.();

          setBannerHidden();
        }}
        onError={error => {
          onError?.(error);

          setBannerHidden();
        }}
        onLoad={onLoad}
      />
    );

    return render ? render({ children: bannerElement }) : bannerElement;
  }, [isBannerHidden]);
});
