import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';
import { IconToggleStarOn16 } from '@cian/ui-kit-design-tokens/icons';

import * as style from './ReviewEmpty.css';

export interface IReviewEmptyProps {
  reviewCountText: string;
}

export const ReviewEmpty: React.FC<IReviewEmptyProps> = ({ reviewCountText }) => {
  return (
    <div className={style['container']}>
      <div className={style['link']}>
        <div className={style['link-star']}>
          <IconToggleStarOn16 />
        </div>
        <UIText2 color="overlay-default">{reviewCountText}</UIText2>
      </div>
    </div>
  );
};
