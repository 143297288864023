import * as React from 'react';
import { ArticleHeading4 } from '@cian/ui-kit';

import { SalesLeader } from '../SalesLeader';

interface IRCTitle {
  name: string;
  isSalesLeader: boolean;
}

export function RCTitle({ name, isSalesLeader }: IRCTitle) {
  return (
    <ArticleHeading4 fontWeight="600" as="h2">
      {name} {isSalesLeader && <SalesLeader />}
    </ArticleHeading4>
  );
}
