import { IFastFiltersUserSchema } from '../repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { IUserPhoneSchema } from '../repositories/monolith-python/entities/user/UserPhoneSchema';
import { TUser } from '../types/user';

interface IPrepareUserParams {
  user?: IFastFiltersUserSchema;
}

export const prepareUser = ({ user }: IPrepareUserParams, abGroup: number): TUser => {
  return {
    abGroup,
    cianId: user?.id || null,
    email: user?.email || null,
    displayName: user?.displayName || null,
    isAuthenticated: user?.isAuthenticated || false,
    kpnWebimChecksum: user?.kpnWebimChecksum || null,
    phones: preparePhones(user?.phones) || null,
    userId: user?.userId || null,
    isAgent: user?.isAgent,
    isBuilder: user?.isBuilder,
  };
};

function preparePhones(phones?: IUserPhoneSchema[] | null): string[] | null {
  if (phones && phones.length > 0 && phones.some(phone => !!phone.phone)) {
    return phones
      .map(phone => (phone.phone && `+${phone.phone.replace(/\D/g, '')}`) || null)
      .filter(Boolean) as string[];
  }

  return null;
}
