import * as React from 'react';
import { useSelector } from 'react-redux';
import { PromoBannerSlider } from '../../components/PromoBannerSlider';
import { getPromoInfo } from '../../selectors/specialPromo';

export function PromoBannerSliderContainer() {
  const promoInfo = useSelector(getPromoInfo);

  if (!promoInfo || !promoInfo.bannerList.length) {
    return null;
  }

  return <PromoBannerSlider bannersList={promoInfo.bannerList} logoUrl={promoInfo.logoBig} />;
}
