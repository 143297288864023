import React, { FC } from 'react';

import { PikSlideWrapper } from '../../PikSlideWrapper';

import styles from './Slide3.css';

export const Slide3: FC = () => (
  <PikSlideWrapper
    backgroundImageUrl="https://static.cdn-cian.ru/frontend/newbuilding-b2b-assets/images/pik/promo_slider_mobile/slide-3.png"
    key="slide-3"
  >
    <ul className={styles['list']}>
      <li>35 млн м² жилья введено в эксплуатацию</li>
      <li>3.7 млн м² жилья введено в 2023 году</li>
      <li>15 регионов присутствия</li>
      <li>Более 2 млн человек проживают в квартирах ПИК</li>
      <li>Более 55 тысяч детей обучаются в детских садах и школах ПИК</li>
    </ul>
  </PikSlideWrapper>
);
