import * as React from 'react';
import { formatPhone } from '../../utils/formatters/phone';
import * as styles from './CroppedPhone.css';

export interface ICroppedPhoneProps {
  phone: string;
  onClick?(e: React.MouseEvent): void;
  cutPhone?: boolean;
}

export function CroppedPhone({ cutPhone, onClick, phone }: ICroppedPhoneProps) {
  const formattedPhone = React.useMemo(() => formatPhone(phone), [phone]);

  const displayPhone = React.useMemo(
    () => (cutPhone ? `${formattedPhone.slice(0, -3)}...` : formattedPhone),
    [formattedPhone, cutPhone],
  );

  return (
    <button className={styles['button']} onClick={onClick} type="button">
      {displayPhone}
    </button>
  );
}
