import * as React from 'react';
import { useSelector } from 'react-redux';
import { BottomActionsLayout } from '../../components/BottomActionsLayout';
import { getIsLastPage } from '../../selectors/pagination';
import { getIsPromoPage } from '../../selectors/specialPromo';
import { ScrollTop } from '../ScrollTop';
import { ShowMoreContainer } from '../ShowMoreContainer';

export const BottomActionsContainer = () => {
  const isPromoPage = useSelector(getIsPromoPage);
  const isLastPage = useSelector(getIsLastPage);

  if (isLastPage && isPromoPage) {
    return null;
  }

  return (
    <BottomActionsLayout>
      {!isLastPage && <ShowMoreContainer />}
      {!isPromoPage && <ScrollTop />}
    </BottomActionsLayout>
  );
};
