import { INewbuildingPromoCarouselState } from '../../types/newbuildingPromoCarousel';

export const initialState: INewbuildingPromoCarouselState = {
  isEnabled: false,
  newbuildingIds: [],
};

export function newbuildingPromoCarouselReducer(
  state: INewbuildingPromoCarouselState = initialState,
): INewbuildingPromoCarouselState {
  return state;
}
