/**
 * @property Default - по умолчанию
 * @property Area - по общей площади
 * @property StreetName - по улице
 * @property WalkingTime - по времени до метро
 * @property Price - по цене
 * @property SquarePrice - по цене за квадратный метр
 * @property LandArea - по площади участка
 * @property Mkad - по расстоянию до МКАД
 */
export enum ESortingType {
  Default = 'default',
  Area = 'area',
  StreetName = 'streetName',
  WalkingTime = 'walkingTime',
  Price = 'price',
  SquarePrice = 'squarePrice',
  LandArea = 'landArea',
  Mkad = 'mkad',
}

export interface ISortingItem {
  type: ESortingType;
}

export interface ISortingOption {
  key: ESortingType;
  value: string;
}

export interface ISorting {
  current: ISortingItem;
  options: ISortingOption[];
}
