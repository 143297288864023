/* eslint-disable @typescript-eslint/no-explicit-any */

type TExcludeNull<O> = { [P in keyof O]: Exclude<O[P], null> };

export function mapNullToVoid<O extends { [key: string]: any }>(object: O): TExcludeNull<O> {
  const mappedObject: { [key: string]: any } = {};

  for (const key of Object.keys(object)) {
    const value = object[key];

    if (value === null) {
      continue;
    }

    mappedObject[key] = value;
  }

  return mappedObject as TExcludeNull<O>;
}
