import * as React from 'react';
import { UIHeading5, UIText2 } from '@cian/ui-kit';
import { IconToggleStarOn16 } from '@cian/ui-kit-design-tokens/icons';

import * as style from './ReviewLink.css';

export interface IReviewLinkProps {
  url: string;
  reviewCountText: string;
  totalRate: string;
}

export const ReviewLink: React.FC<IReviewLinkProps> = ({ url, reviewCountText, totalRate }) => {
  return (
    <div className={style['container']}>
      <a href={`${url}otzyvy/`} target="_blank" className={style['link']} rel="noreferrer">
        <div className={style['link-star']}>
          <IconToggleStarOn16 color="icon-warning-default" />
        </div>
        <UIHeading5 color="text-warning-default" as="p">
          {totalRate}
        </UIHeading5>
        <UIText2 color="overlay-default" as="span">
          {reviewCountText}
        </UIText2>
      </a>
    </div>
  );
};
