import * as React from 'react';
import { useSelector } from 'react-redux';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';

import {
  getNewbuildingPromoCarouselIds,
  getIsNewbuildingPromoCarouselEnabled,
} from '../../selectors/newbuildingPromoCarousel';
import { NewbuildingPromoCarouselLoadableContainer } from './NewbuildingPromoCarouselLoadable';
import { NewbuildingPromoCarouselLayout } from '../../components/NewbuildingPromoCarouselLayout';
import { getIsPromoOrPikPage } from '../../selectors/specialPromo';
import { trackNewbuildingPromoCarouselShow } from './tracking';
import { NewbuildingPromoCarouselLoader } from '../../components/NewbuildingPromoCarouselLoader';

export function LazyNewbuildingPromoCarouselContainer() {
  const [isVisible, setIsVisible] = React.useState(false);
  const newbuildingIds = useSelector(getNewbuildingPromoCarouselIds);
  const isPromoOrPikPage = useSelector(getIsPromoOrPikPage);
  const isNewbuildingPromoCarouselEnabled = useSelector(getIsNewbuildingPromoCarouselEnabled);

  const handleActionAfterViewd = React.useCallback(() => {
    setIsVisible(true);
    trackNewbuildingPromoCarouselShow(newbuildingIds);
  }, [newbuildingIds]);

  if (!isNewbuildingPromoCarouselEnabled || !newbuildingIds.length || isPromoOrPikPage) {
    return null;
  }

  return (
    <ActionAfterViewed callback={handleActionAfterViewd} percentVisible={0.2}>
      <div>
        {isVisible && (
          <NewbuildingPromoCarouselLayout>
            <NewbuildingPromoCarouselLoadableContainer
              newbuildingIds={newbuildingIds}
              fallback={<NewbuildingPromoCarouselLoader />}
            />
          </NewbuildingPromoCarouselLayout>
        )}
        {!isVisible && (
          <NewbuildingPromoCarouselLayout>
            <NewbuildingPromoCarouselLoader />
          </NewbuildingPromoCarouselLayout>
        )}
      </div>
    </ActionAfterViewed>
  );
}
