import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';

export const addressSelector =
  (id: number) =>
  (state: IApplicationState): string => {
    const {
      geo: { address },
    } = selectRCById(state, id);

    return address;
  };
