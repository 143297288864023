import React, { FC } from 'react';

import { PikSlideWrapper } from '../../PikSlideWrapper';

export const Slide2: FC = () => (
  <PikSlideWrapper
    backgroundImageUrl="https://static.cdn-cian.ru/frontend/newbuilding-b2b-assets/images/pik/promo_slider_mobile/slide-2.png"
    key="slide-2"
  >
    <span>
      С момента основания в 1997 году компания построила более 35 млн м², обеспечив жильем более 2 млн человек. Сегодня
      ПИК работает в пятнадцати регионах России: от Санкт-Петербурга и&nbsp;Москвы до Владивостока и Хабаровска
    </span>
  </PikSlideWrapper>
);
