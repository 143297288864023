import * as React from 'react';
import { useSelector } from 'react-redux';

import { addressSelector, undergroundsSelector } from '../../selectors/rcs';
import { Address } from '../../components/Address';
import { Undergrounds } from '../../components/Undergrounds';
import { isEmpty } from '../../utils/isEmpty';

interface ILocationContainerProps {
  id: number;
}

export function LocationContainer({ id }: ILocationContainerProps) {
  const undergrounds = useSelector(undergroundsSelector(id));
  const address = useSelector(addressSelector(id));

  if (isEmpty(undergrounds)) {
    return <Address address={address} />;
  }

  return <Undergrounds undergrounds={undergrounds} />;
}
