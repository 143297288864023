import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export const trackReadAllSeo = () => {
  ca('eventSite', {
    action: 'readAll',
    category: 'Listing',
    name: 'oldevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
};
