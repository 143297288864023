import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { TReduxActions } from '../../types/redux';
import { IPromoPopup } from '../../types/promoPopup/promoPopup';
import { prepareDiscountMortgagePopup } from '../../mappers';

export function discountMortgagePopupReducer(
  state: IPromoPopup | null = null,
  action: TReduxActions,
): IPromoPopup | null {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
    case EPaginationActionTypes.Success:
      return prepareDiscountMortgagePopup(action.payload);
    default:
      return state;
  }
}
