// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface IPromoLabelSchema {
  /** Цвет лейбла **/
  color: EColor;
  /** Текст лейбла **/
  name: string;
}

export enum EColor {
  /** Зеленый **/
  'Green' = 'green',
  /** Светло-зеленый **/
  'LightGreen' = 'lightGreen',
}
