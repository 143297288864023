import * as React from 'react';

import { IMarkedListItem } from '../../types/seoData';
import { SeoMarkedListItem } from './components/SeoMarkedListItem';
import * as styles from './SeoMarkedList.css';

interface ISeoMarkedListProps {
  items: IMarkedListItem[];
}

export const SeoMarkedList: React.VFC<ISeoMarkedListProps> = ({ items }) => (
  <div className={styles['container']}>
    {items.map((item, index) => {
      const id = `SeoMarkedListItem_${index}`;

      return (
        <SeoMarkedListItem
          key={id}
          id={id}
          title={item.title}
          content={item.content}
          expandedByDefault={item.expanded}
        />
      );
    })}
  </div>
);
