import * as React from 'react';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as styles from './PreInfiniteBanner.css';

export interface IPreInfiniteBannerProps {
  onClick?(): void;
  onShow?(): void;
}

export function PreInfiniteBanner({ onClick, onShow }: IPreInfiniteBannerProps) {
  const handleShow = React.useCallback(() => {
    if (onShow) {
      onShow();
    }
  }, [onShow]);

  return (
    <ActionAfterViewed percentVisible={1.0} callback={handleShow}>
      <section className={styles['container']}>
        <div className={styles['banner']}>
          <div className={styles['banner-image']}></div>
          <div className={styles['content']}>
            <h3 className={styles['text-bold']}>Сейчас это все объявления по вашему поиску</h3>
            <p className={styles['text']}>Подпишитесь на обновления, и мы сообщим вам, если что-то появится</p>
            <button className={styles['tablet-button']} type="button" onClick={onClick}>
              Подписаться
            </button>
          </div>
        </div>
        <button className={styles['mobile-button']} type="button" onClick={onClick}>
          Подписаться
        </button>
      </section>
    </ActionAfterViewed>
  );
}
