import { ca } from '@cian/analytics';

import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export const trackClickBreadcrumbs = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_BreadCrumbs',
    action: 'click',
    sc: jsonQueryHelper.dump(),
  });
};
