import { Ticker } from '@cian/adfox-templates';
import * as React from 'react';
import { ISpecialPromoBanner } from '../../../../types/specialPromo';

import * as styles from './PromoBannerSlide.css';

interface IPromoBannerSlideProps {
  banner: ISpecialPromoBanner;
  logoUrl?: string;
}

export function PromoBannerSlideComponent({ banner, logoUrl }: IPromoBannerSlideProps) {
  return (
    <div className={styles['slide']} style={{ backgroundImage: `url(${banner.imageUrl})` }}>
      <div className={styles['content']}>
        {logoUrl && (
          <div className={styles['logo-container']}>
            <img src={logoUrl} className={styles['logo']} />
          </div>
        )}
        <a className={styles['link-text']} href={banner.urlLink} target="_blank" rel="noopener noreferrer">
          {banner.text}
        </a>
      </div>

      {banner.disclaimer && (
        <div data-testid="disclaimer" className={styles['disclaimer']}>
          <Ticker text={banner.disclaimer} />
        </div>
      )}
    </div>
  );
}

export const PromoBannerSlide = React.memo(PromoBannerSlideComponent);
