import { TThunkAction } from '../../types/redux';
import { ESortingType } from '../../types/sorting';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';
import { trackChangeSorting } from './analytics';
import { pushErrorNotification } from '../notifications';
import { EErrorNotificationType } from '../../types/notifications';
import { mapSortingToJsonQuery } from '../../utils/jsonQuery/sortingMappers';
import { updateBrowserLocation } from '../location';
import { requestNewbuildings } from '../newbuildings';
import { getCurrentSorting } from '../../selectors/sorting';
import { setCurrentSorting } from './sorting';

interface IOptions {
  sortingType: ESortingType;
}

export const sortNewbuildings =
  ({ sortingType }: IOptions): TThunkAction<Promise<void>> =>
  async (dispatch, getState, { logger }) => {
    const prevJsonQuery = jsonQueryHelper.dump();
    const jsonQuery = jsonQueryHelper.mergeSorting(mapSortingToJsonQuery(sortingType));

    const prevSorting = getCurrentSorting(getState());

    dispatch(setCurrentSorting(sortingType));
    trackChangeSorting({ sortingType, jsonQuery });

    try {
      await dispatch(requestNewbuildings({ jsonQuery }));
      dispatch(updateBrowserLocation());
    } catch (error) {
      logger.error(error, {
        domain: 'actions/sorting/sortNewbuildings',
        prevJsonQuery: JSON.stringify(prevJsonQuery),
        jsonQuery: JSON.stringify(jsonQuery),
      });

      dispatch(pushErrorNotification(EErrorNotificationType.Sorting));
      dispatch(setCurrentSorting(prevSorting));
    }
  };
