import * as React from 'react';
import * as styles from './styles.css';

import { IconCatOnBox } from './icons';

export function EmptySearchScreen() {
  return (
    <div className={styles['container']}>
      <IconCatOnBox />
      <div className={styles['title']}>Упс... По такому запросу объявления еще не разместили</div>
      <div className={styles['text']}>Попробуйте изменить условия поиска или посмотрите похожие предложения ниже</div>
    </div>
  );
}
