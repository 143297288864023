import { INewbuildingForMobileSiteSchema } from '../repositories/search-engine/entities/newbuilding/NewbuildingForMobileSiteSchema';
import { IRCOffers } from '../types/rc/rcOffers';

// TO-DO  Поле просто пропало из апи, требуется разобюраться при возобновлении разработки
export const prepareRCOffers = (rc: INewbuildingForMobileSiteSchema): IRCOffers | null => {
  const { flatStats } = rc;

  if (!flatStats) {
    return null;
  }

  return flatStats;
};
