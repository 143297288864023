import { TTrackingUser } from '../../types/cianAnalyticsPageViewObject';
import { IApplicationState } from '../../types/redux';

export function getTrackingUser(state: IApplicationState): TTrackingUser {
  const { user } = state;
  const trackingUser = { abGroup: user.abGroup };

  if (user.isAuthenticated) {
    return {
      ...trackingUser,
      userId: user.userId,
    };
  }

  return trackingUser;
}
