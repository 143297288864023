import {
  EType,
  INewbuildingFeatureSchema,
} from '../repositories/search-engine/entities/newbuilding_feature/NewbuildingFeatureSchema';
import { EFeatureType, IFeature } from '../types/rc/feature';

const FEATURE_MAP = new Map([
  [EType.Has3dTour, EFeatureType.Has3dTour],
  [EType.HasFlatTourBooking, EFeatureType.HasFlatTourBooking],
]);

export function prepareFeatures(features?: INewbuildingFeatureSchema[]): IFeature[] {
  if (!features) {
    return [];
  }

  return features.reduce<IFeature[]>((acc, feature) => {
    const type = FEATURE_MAP.get(feature.type);

    if (type) {
      acc.push({ type });
    }

    return acc;
  }, []);
}
