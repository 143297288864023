import { TThunkAction } from '../../types/redux';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';
import { pushErrorNotification } from '../notifications';
import { EErrorNotificationType } from '../../types/notifications';
import { requestNewbuildings } from '../newbuildings';
import { replaceBrowserLocation } from './replaceBrowserLocation';

export const fetchNewbuildingsByLocation = (path: string): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, { logger }) => {
    const prevJsonQuery = jsonQueryHelper.dump();

    try {
      await dispatch(requestNewbuildings({ path }));
      dispatch(replaceBrowserLocation());
    } catch (error) {
      logger.error(error, {
        domain: 'actions/location/fetchNewbuildingsByLocation',
        prevJsonQuery: JSON.stringify(prevJsonQuery),
      });

      dispatch(pushErrorNotification(EErrorNotificationType.SearchApi));
    }
  };
};
