export const clearPhone = (phoneNumber: string) => phoneNumber.replace(/\D/g, '');

export const formatPhone = (phoneNumber: string) => {
  const cleanPhone = clearPhone(phoneNumber);

  return cleanPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
};

export const makePhoneLink = (phoneNumber: string) => {
  const cleanPhone = clearPhone(phoneNumber);

  return `tel:${cleanPhone}`;
};
