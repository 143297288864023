import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export const trackBannerShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BlackFriday', // Переиспользуем категорию ЧП.
    action: 'show_sopr',
    label: 'listing_banner',
    sc: jsonQueryHelper.dump(),
  });
};
