import * as React from 'react';

import { NewbuildingPromosBanner } from '../../components/NewbuildingPromosBanner';
import { useApplicationContext } from '../../utils/applicationContext';
import { getNewbuildingPromosBannerData } from '../../utils/config';
import { trackFlatTourPromoClick, trackFlatTourPromoShow } from './tracking';

export function NewbuildingPromosBannerContainer() {
  const { config } = useApplicationContext();
  const bannerData = getNewbuildingPromosBannerData(config);

  return (
    <NewbuildingPromosBanner
      title={bannerData.title}
      description={bannerData.description}
      url={bannerData.link}
      onClick={trackFlatTourPromoClick}
      onShow={trackFlatTourPromoShow}
    />
  );
}
