import * as React from 'react';

export function IconCatOnBox() {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 93">
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#13344B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".5"
          d="M8.473 7.491l-3.219-2.67"
        />
        <g fill="#F9B067">
          <path d="M75.967 29.725c-2.273-6.254.985-14.416-12.726-18.115-12.916-3.47-24.657 5.759-25.945 6.827h-.113c.113-3.356-1.667-6.331-1.667-6.331s1.705-2.288.076-5.377c0 0-2.803-1.488-5.643 1.792-1.137 1.297-8.825-2.212-8.825-2.212l.681-3.013c-2.234-.724-6.514 2.823-6.514 2.823-11.741 8.16-3.598 18.724-3.598 18.724S4.875 39.717 8.928 43.111c4.052 3.432 7.007 2.402 7.007 2.402l.492-.572h1.364c-2.121 1.373-4.356 2.594-6.44 3.28-3.597 1.144-1.666 2.937-.226 2.784-.91.267-2.765.992-2.197 1.83.72 1.107 3.787-.61 3.787-.61s-2.196 1.488-1.212 2.327c.985.877 7.727-6.026 12.348-7.513.984-.305 3.257-1.106 5.416-2.021l14.847.228c5.113 3.585 14.96 3.242 22.081 3.242 9.772 0 12.044-12.47 9.772-18.763z" />
          <path d="M40.932 42.348l-13.483.61c.038 0 11.287 0 13.483-.61z" />
        </g>
        <path
          stroke="#13344B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".572"
          d="M31.577 22.86c6.856-4.614 1.97-12.737 1.97-12.737s1.704-2.288.075-5.377c0 0-2.802-1.488-5.643 1.792-1.136 1.297-8.825-2.212-8.825-2.212l.682-3.013C17.6.59 13.32 4.135 13.32 4.135 1.581 12.297 9.723 22.86 9.723 22.86S2.906 37.734 6.958 41.128c4.053 3.394 7.007 2.402 7.007 2.402"
        />
        <path
          stroke="#13344B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".572"
          d="M35.213 16.568S47.56 5.966 61.271 9.665c13.711 3.7 10.454 11.86 12.726 18.115 2.273 6.254 0 18.763-9.772 18.763-9.771 0-24.808.687-25.452-9.19-.643-9.878 5.265-13.997 7.84-13.082 2.576.916-3.56 11.746 0 14.378 3.56 2.631 15.643 2.326 17.612 0M25.366 35.217s-8.787 8.695-15.984 11.021c-4.014 1.297-1.098 3.356.228 2.631 0 0-3.41.878-2.69 1.983.72 1.106 3.788-.61 3.788-.61S8.511 51.73 9.496 52.57c.985.839 7.726-6.026 12.347-7.513 1.894-.61 8.181-2.822 9.924-4.424"
        />
        <path
          stroke="#13344B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".572"
          d="M12.677 35.217s-.72 1.83.985 2.135c1.705.305 7.802.382 8.863.458M27.487 42.958s11.286 0 13.483-.61M26.767 13.746s-.417 1.487.72 2.479c1.136.991 2.462-1.22 2.462-1.22M10.746 9.665s-.606 1.907.871 2.632c1.477.724 2.803-1.335 2.803-1.335M17.525 13.25s.53 1.602 2.387.496M17.525 18.665s-.113-1.678 1.175-4.538M11.465 15.119H4.231M9.61 17.56l-3.94 1.105M24.305 18.665l5.644 1.83M22.525 20.496l3.144 2.25M40.516 53.598s.833 21.128 0 38.48L.709 68.28V37.467l4.09 2.135M55.022 46.429c-3.333.915-10.946 5.453-14.506 6.407M40.516 92.078l32.951-17.581s.114-18.954 0-30.395M38.281 53.446l-15.34-6.14M6.958 33.882L.71 37.428"
        />
        <g stroke="#13344B" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".572">
          <path d="M21.578 64.734v6.407s.758.229 1.099.267c.34 0 .416-6.102 0-6.407-.417-.305-1.099-.267-1.099-.267zM21.881 61.264c-.417-.038-.644.762-.303 1.373.341.61.985.915 1.402.305.416-.61.113-1.602-1.099-1.678z" />
          <path d="M30.82 71.332c3.446-7.437-2.992-13.691-9.242-14.683-2.727-.42-8.332.496-8.257 7.017.038 9.306 13.18 16.895 17.499 7.666" />
        </g>
      </g>
    </svg>
  );
}
