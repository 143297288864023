import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { prepareLocation } from '../../mappers';
import { ILocationState } from '../../types/location';
import { TReduxActions } from '../../types/redux';

const initialState: ILocationState = {
  jsonQuery: {
    _type: 'flatsale',
    engine_version: {
      type: 'term',
      value: 2,
    },
  },
  path: '',
  subdomain: 'www',
};

export function locationReducer(state: ILocationState = initialState, action: TReduxActions): ILocationState {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
    case EPaginationActionTypes.Success: {
      return prepareLocation(action.payload, state.subdomain);
    }

    default:
      return state;
  }
}
