import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';
import { IUnderground } from '../../types/rc/geo';

export const undergroundsSelector =
  (id: number) =>
  (state: IApplicationState): IUnderground[] => {
    const {
      geo: { undergrounds },
    } = selectRCById(state, id);

    return undergrounds;
  };
