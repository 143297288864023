import * as React from 'react';

import { CollapsedButton } from './CollapsedButton';

interface ICollapsedBlockButtonProps
  extends JSX.LibraryManagedAttributes<typeof CollapsedButton, React.ComponentPropsWithoutRef<typeof CollapsedButton>> {
  toggle(): void;
}

export function CollapsedBlockButton({ toggle, ...buttonProps }: ICollapsedBlockButtonProps) {
  const onClick = React.useCallback(() => {
    toggle();
  }, [toggle]);

  return <CollapsedButton {...buttonProps} onClick={onClick} closedContent="Читать полностью" />;
}
