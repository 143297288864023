import { IApplicationState } from '../../types/redux';
import { selectRCById } from './rcs';

export const getSalesStartLabel =
  (id: number) =>
  (state: IApplicationState): string => {
    const { salesStartLabel } = selectRCById(state, id);

    return salesStartLabel;
  };
