import { incrementHeaderFavorites, decrementHeaderFavorites } from './utils/updateHeaderFavorites';
import { TThunkAction } from '../../types/redux';
import { deleteFavoriteRequest, deleteFavoriteSuccess, deleteFavoriteFailure } from './deleteFavoriteActions';
import { trackDeleteFavorite } from './analytics';
import { pushErrorNotification } from '../notifications';
import { EErrorNotificationType } from '../../types/notifications';
import {
  fetchDeleteOfferFromFavorites,
  TDeleteOfferFromFavoritesResponse,
} from '../../repositories/favorites/v1/delete-offer-from-favorites';
import {
  EDealType,
  EEntityType,
} from '../../repositories/favorites/entities/delete_user_favorites/DeleteOfferFromFavoritesRequestSchema';

interface IParams {
  rcId: number;
}

export const deleteFavorite =
  ({ rcId }: IParams): TThunkAction<Promise<void>> =>
  async (dispatch, getState, { logger, httpApi }) => {
    const { rcs } = getState();
    const rc = rcs.byId[rcId];

    dispatch(deleteFavoriteRequest({ rcId }));
    decrementHeaderFavorites();

    trackDeleteFavorite(rc.gaLabel);

    try {
      const response: TDeleteOfferFromFavoritesResponse = await fetchDeleteOfferFromFavorites({
        httpApi,
        parameters: {
          entityId: rc.id,
          dealType: EDealType.Sale,
          entityType: EEntityType.Newbuilding,
        },
      });

      if (response.statusCode === 200) {
        dispatch(deleteFavoriteSuccess({ rcId }));

        return;
      }
    } catch (err) {
      logger.error(err, {
        rcId: rc.id,
        domain: 'Serp/shared/actions/favorites#deleteFavorite()',
      });
    }

    dispatch(pushErrorNotification(EErrorNotificationType.Favorites));
    dispatch(deleteFavoriteFailure({ rcId }));
    incrementHeaderFavorites();
  };
