import * as React from 'react';
import classNames from 'clsx';

import * as styles from './styles.css';

interface ILinkAreaProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  href: string;
  title: string;
  preventDefault?: boolean;
}

export function LinkArea({ href, children, preventDefault, title, ...divProps }: ILinkAreaProps) {
  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (preventDefault) {
        e.preventDefault();
      }
    },
    [preventDefault],
  );

  return (
    <div {...divProps} className={classNames(styles['container'], divProps.className)}>
      <a
        href={href}
        title={title}
        className={styles['link']}
        onClick={onLinkClick}
        data-testid="LinkArea-Link"
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles['link-area']} />
      </a>
      {children}
    </div>
  );
}
