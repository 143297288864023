import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { IRCs, IRCsById } from '../../types/rc/rcs';
import { prepareRCs } from '../../mappers';
import { TReduxActions } from '../../types/redux';
import { EAddFavoritesActionTypes, EDeleteFavoritesActionTypes } from '../../actions/favorites';
import { updateRC } from './utils/updateRC';

const defaultState: IRCs = {
  byId: {} as IRCsById,
  allIds: [],
};

export function rcsReducer(state: IRCs = defaultState, action: TReduxActions): IRCs {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success: {
      const { byId, allIds } = prepareRCs(action.payload);

      return {
        byId,
        allIds,
      };
    }

    case EPaginationActionTypes.Success: {
      const { byId, allIds } = prepareRCs(action.payload);

      return {
        ...state,
        byId: Object.assign({}, state.byId, byId),
        allIds: [...state.allIds, ...allIds],
      };
    }

    case EAddFavoritesActionTypes.Request:
    case EDeleteFavoritesActionTypes.Failure: {
      return updateRC(state, action.payload.rcId, { isFavorite: true });
    }

    case EDeleteFavoritesActionTypes.Request:
    case EAddFavoritesActionTypes.Failure: {
      return updateRC(state, action.payload.rcId, { isFavorite: false });
    }

    default:
      return state;
  }
}
