import * as React from 'react';

import { PageWrapper } from '../../components/PageWrapper';
import { Loader } from '../../components/Loader';
import { PageLoaderLayout } from '../../components/PageLoaderLayout';

import { SeoTitleContainer } from '../SeoTitleContainer';
import { MainSection } from './MainSection';
import { TopBarContainer } from '../TopBarContainer';
import { HelmetContainerLoadable } from '../HelmetContainer';
import { ConsultantChatContainerLoadable } from '../ConsultantChatContainer';
import { Notifications } from '../Notifications';
import { PromoBannerSliderContainer } from '../PromoBannerSliderContainer';
import { SavedSearchContainerLoadable } from '../SavedSearchContainer';
import { SpecialPromoSliderContainer } from '../SpecialPromoSliderContainer';
import { SpecialPromoFooterContainer } from '../SpecialPromoFooterContainer';
import { UpcomingSaleRequestContainerLoadable } from '../UpcomingSaleRequestContainer';
import { BuilderChatModalContainer } from '../BuilderChatModalContainer';

interface IPageProps {
  isLoading: boolean;
}

export function Page({ isLoading }: IPageProps) {
  return (
    <PageWrapper>
      <HelmetContainerLoadable />
      <Notifications />
      <TopBarContainer />
      <SpecialPromoSliderContainer />
      <PromoBannerSliderContainer />
      <SeoTitleContainer />
      {isLoading && (
        <PageLoaderLayout>
          <Loader />
        </PageLoaderLayout>
      )}

      {!isLoading && <MainSection />}

      <SpecialPromoFooterContainer />
      <SavedSearchContainerLoadable />
      <ConsultantChatContainerLoadable />
      <UpcomingSaleRequestContainerLoadable />
      <BuilderChatModalContainer />
    </PageWrapper>
  );
}
