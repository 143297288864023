import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { TReduxActions } from '../../types/redux';
import { prepareIsDiscountMortgage } from '../../mappers';

export function isDiscountMortgageReducer(state: boolean = false, action: TReduxActions): boolean {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
    case EPaginationActionTypes.Success:
      return prepareIsDiscountMortgage(action.payload);
    default:
      return state;
  }
}
