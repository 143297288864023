import * as React from 'react';
import { useSelector } from 'react-redux';
import { Carousel as CarouselView } from '../../components/Carousel';
import { CarouselStub } from '../../components/CarouselStub';
import { getMedia } from '../../selectors/rcs';

interface ICarouselProps {
  id: number;
}

export function Carousel(props: ICarouselProps) {
  const { id } = props;

  const media = useSelector(getMedia(id));

  if (!media.length) {
    return <CarouselStub />;
  }

  return <CarouselView media={media} />;
}
