import * as React from 'react';
import { UIText1, UIText3 } from '@cian/ui-kit';
import { IPromoProp } from '../../types/promoPopup/promoPopup';

import * as styles from './PromoFeatures.css';

interface IPromoFeaturesProps {
  features: IPromoProp[];
}

export const PromoFeatures: React.FC<IPromoFeaturesProps> = ({ features }) => {
  return (
    <ul className={styles['features-list']}>
      {features.map((feature, idx) => (
        <li key={idx} className={styles['feature-item']}>
          <div className={styles['feature-title']}>
            <UIText3 color="black_60">{feature.title}</UIText3>
          </div>
          <UIText1 display="block">{feature.value}</UIText1>
        </li>
      ))}
    </ul>
  );
};
