import { ca } from '@cian/analytics';
import { EModalOpener } from '../../types/savedSearch';

export function trackSavedSearchPopupOpen(modalOpener: EModalOpener) {
  const modalOpenerToPage = modalOpener === EModalOpener.PreInfiniteBanner;

  ca('eventSite', {
    name: 'oldevent',
    action: 'open',
    category: 'subscription',
    label: 'saved_search',
    page: {
      extra: {
        banner: modalOpenerToPage,
      },
    },
  });
}
