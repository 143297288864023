import { connect } from 'react-redux';

import { Breadcrumbs } from '../../components/Breadcrumbs';
import { IApplicationState } from '../../types/redux';
import { trackClickBreadcrumbs } from './analytics/trackClickBreadcrumbs';

export function mapStateToProps(store: IApplicationState) {
  const { breadcrumbs } = store;

  return {
    breadcrumbs,
    handleClickBreadcrumbs: trackClickBreadcrumbs,
  };
}

export const BreadcrumbsContainer = connect(mapStateToProps)(Breadcrumbs);
