import * as React from 'react';
import { ArticleHeading2, ModalWindow } from '@cian/ui-kit';
import { PromoFeatures } from '../PromoFeatures';
import { IPromoProp } from '../../types/promoPopup/promoPopup';

import * as styles from './DiscountMortgagePopup.css';

interface IBannerPopupProps {
  description: string;
  onClose(): void;
  open: boolean;
  props: IPromoProp[];
  title: string;
}

export const DiscountMortgagePopup: React.FC<IBannerPopupProps> = props => {
  const { description, onClose, open, props: propsList, title } = props;

  return (
    <ModalWindow open={open} onClose={onClose} width="100%" maxWidth={648}>
      <div className={styles['container']} data-testid="DiscountMortgagePopup">
        <div className={styles['title']}>
          <ArticleHeading2 as="h2">{title}</ArticleHeading2>
        </div>
        {propsList.length > 0 && (
          <div className={styles['features-list']}>
            <PromoFeatures features={propsList} />
          </div>
        )}
        {description && <p className={styles['description']}>{description}</p>}
      </div>
    </ModalWindow>
  );
};
