import * as React from 'react';
import { useSelector } from 'react-redux';

import { ArrowUpRoundButton } from '../../components/ArrowUpRoundButton';
import { selectRCsIds } from '../../selectors/rcs';

export function ScrollTop() {
  const rcsIds = useSelector(selectRCsIds);
  const onClick = React.useCallback(() => window.scrollTo(0, 0), []);

  if (rcsIds.length < 3) {
    return null;
  }

  return <ArrowUpRoundButton onClick={onClick} />;
}
