import { IResponseDataSchema } from '../../../repositories/search-engine/entities/response/ResponseDataSchema';
import { ResponseError } from '../../../errors';

export function assertResponseDataError(
  response: IResponseDataSchema | null | undefined,
): asserts response is IResponseDataSchema {
  if (response) {
    return;
  }

  throw new ResponseError({
    domain: 'services.newbuildings.fetchSearchNewbuildings',
    message: 'Response body is empty',
  });
}
