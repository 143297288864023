import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { OnMapButton } from '../../components/TopBar/OnMapButton';
import { trackClickMapButton } from './analytics';

export function mapStateToProps(state: IApplicationState) {
  return {
    mapUrl: state.mapUrl,
    handleClickMapButton: trackClickMapButton,
  };
}

export const OnMapButtonContainer = connect(mapStateToProps)(OnMapButton);
