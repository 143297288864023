import * as React from 'react';

import * as styles from './styles.css';

export interface IAddresProps {
  address: string;
}

export interface IAddressDetailSchema {
  key: string;
  value: string;
}

export function Address({ address }: IAddresProps) {
  return <div className={styles['container']}>{address}</div>;
}
