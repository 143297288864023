import { IAuthor } from '../types/author';
import type { INewbuildingBuilderForMobileSiteSchema } from '../repositories/search-engine/entities/newbuilding/NewbuildingBuilderForMobileSiteSchema';

const getName = (author: INewbuildingBuilderForMobileSiteSchema) => {
  return (author.name || author.id) as string;
};

export const prepareAuthor = (author: INewbuildingBuilderForMobileSiteSchema | null | undefined): IAuthor => {
  if (!author) {
    return {
      name: null,
      avatar: null,
      fromDeveloper: null,
      cianId: null,
    };
  }

  return {
    name: getName(author),
    avatar: author.logoUrl,
    fromDeveloper: author.fromDeveloper,
    cianId: author.id,
    builderPageUrl: author.builderPageUrl,
    builderRealtyUserId: author.realtyUserId,
    chat: {
      isEnabled: author.isChatsEnabled,
      defaultMessage: author.chatDefaultMessage,
      isOnline: author.isOnline,
    },
  };
};
