import * as React from 'react';
import classNames from 'clsx';

import * as styles from './Section.css';

interface ISectionProps<T extends keyof JSX.IntrinsicElements> {
  tag?: T;
}

type TSectionProps<T extends keyof JSX.IntrinsicElements> = ISectionProps<T> & JSX.IntrinsicElements[T];
type TSectionComponent = React.FC<TSectionProps<keyof JSX.IntrinsicElements>>;

export const Section: TSectionComponent = React.memo(props => {
  const { tag: Tag = 'section', ...sharedProps } = props;

  return (
    <Tag
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(sharedProps as any)}
      className={classNames(styles['container'], sharedProps.className)}
    />
  );
});

Section.displayName = 'Section';
