import { IApplicationState } from '../../types/redux';
import { getPromoInfo } from './getPromoInfo';

export const getPromoPageTitle = (state: IApplicationState) => {
  const promoInfo = getPromoInfo(state);

  if (promoInfo) {
    return `Квартиры в\xA0новостройке от\xA0${promoInfo.nameGenitive}`;
  }

  return '';
};
