import * as React from 'react';
import { IconActionFilters16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from '../styles.css';

export interface IFiltersButtonProps {
  filtersUrl: string;
}

export function FiltersButton({ filtersUrl }: IFiltersButtonProps) {
  return (
    <a href={filtersUrl} className={styles['button']}>
      <IconActionFilters16 color="icon-main-default" />
      <span>Фильтры</span>
    </a>
  );
}
