import { ModalFullscreen, Spinner } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';

export interface IBuilderChatProps {
  url?: string;
  isOpen: boolean;
  onClose(): void;
}

export function BuilderChatModal({ isOpen, onClose, url }: IBuilderChatProps) {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  return (
    <ModalFullscreen onClose={onClose} open={isOpen} theme="white">
      <div className={styles['iframe-container']} data-testid="BuilderChatModalContainer">
        <span className={styles['close']} data-testid="BuilderChatModalCloseButton">
          <IconActionClose16 color="icon-primary-default" onClick={onClose} />
        </span>
        {isLoading && (
          <span className={styles['spinner']} data-testid="spinner">
            <Spinner size={16} />
          </span>
        )}
        <iframe
          data-testid="BuilderChatModal"
          className={styles['iframe']}
          src={url}
          onLoad={onLoad}
          onError={onLoad}
        />
      </div>
    </ModalFullscreen>
  );
}
