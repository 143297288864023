import { ca } from '@cian/analytics';

export function trackTransferToDeveloperCard() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'click_sopr',
    label: 'to_card_builder',
    useLastProducts: true,
  });
}
