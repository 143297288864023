import { IResponseDataSchema } from '../../repositories/search-engine/entities/response/ResponseDataSchema';
import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/actionGenerator';

export enum ENewbuildingsActionTypes {
  Request = 'NEWBUILDINGS/REQUEST',
  Success = 'NEWBUILDINGS/SUCCESS',
  Failure = 'NEWBUILDINGS/FAILURE',
}

export type TGetNewbuildingsRequest = ITypedReduxAction<ENewbuildingsActionTypes.Request, void>;
export type TGetNewbuildingsSuccess = ITypedReduxAction<ENewbuildingsActionTypes.Success, IResponseDataSchema>;
export type TGetNewbuildingsFailure = ITypedReduxAction<ENewbuildingsActionTypes.Failure, void>;

export const getNewbuildingsRequest = actionGenerator<ENewbuildingsActionTypes.Request>(
  ENewbuildingsActionTypes.Request,
);

export const getNewbuildingsSuccess = actionGenerator<ENewbuildingsActionTypes.Success, IResponseDataSchema>(
  ENewbuildingsActionTypes.Success,
);

export const getNewbuildingsFailure = actionGenerator<ENewbuildingsActionTypes.Failure>(
  ENewbuildingsActionTypes.Failure,
);

export type TNewbuildingsActionsTypes = TGetNewbuildingsRequest | TGetNewbuildingsSuccess | TGetNewbuildingsFailure;
