import * as React from 'react';
import { Provider } from 'react-redux';
import { ErrorLogComponent } from '@cian/error-log-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { Context as ErrogLogContext } from '../../utils/withErrorHandler';
import { IReduxStore } from '../../types/redux';
import { IApplicationContext } from '../../types/application';
import { PageContainer } from '../PageContainer';
import { ApplicationContext } from '../../utils/applicationContext';
import { SaveSearchModalProvider } from '../SaveSearchModalProvider';
import { SubscriptionsContainer } from '../Subscriptions';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export class Application extends React.Component<IApplicationProps> {
  public render() {
    const { reduxStore, context } = this.props;
    const { logger, microfrontendsRegistry } = context;

    return (
      <ErrorLogComponent logger={logger}>
        <ErrogLogContext.Provider value={logger}>
          <ApplicationContext.Provider value={context}>
            <MicrofrontendProvider registry={microfrontendsRegistry}>
              <Provider store={reduxStore}>
                <SaveSearchModalProvider>
                  <PageContainer />
                  <SubscriptionsContainer reduxStore={reduxStore} />
                </SaveSearchModalProvider>
              </Provider>
            </MicrofrontendProvider>
          </ApplicationContext.Provider>
        </ErrogLogContext.Provider>
      </ErrorLogComponent>
    );
  }
}
