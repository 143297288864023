import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export function trackMobFooterClick(label?: string) {
  ca('eventSite', {
    action: 'click',
    name: 'oldevent',
    category: 'Mob_footer',
    label,
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}
