import { ca } from '@cian/analytics';
import { yieldToMainThread } from '../../../utils/yieldToMainThread';

export async function trackRCCardClick() {
  await yieldToMainThread();
  ca('eventSite', {
    name: 'oldevent',
    category: 'jk-listing',
    action: 'click',
    label: 'jk-card',
  });
}
