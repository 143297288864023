import { connect } from 'react-redux';

import { ChangeConditionsSearch } from '../../components/ChangeConditionsSearch';
import { IApplicationState } from '../../types/redux';

export function mapStateToProps(state: IApplicationState) {
  return {
    filtersUrl: state.filtersUrl,
  };
}

export const ChangeConditionsSearchContainer = connect(mapStateToProps)(ChangeConditionsSearch);
