export interface ISpecialPromoState {
  isSpecialPromo: boolean;
  promoInfo: ISpecialPromo | null;
  specialPromoType: ESpecialPromoType | null;
}

/** Кастомные спецпроекты */
export enum ESpecialPromoType {
  Pik = 'pik',
}

export interface ISpecialPromo {
  /** Список баннеров **/
  bannerList: ISpecialPromoBanner[];
  /** Акцентный цвет спецпроекта в формате #XXXXXX **/
  colorHex: string;
  /** Блок с настраиваемой информацией о спецпроекте **/
  info: ISpecialPromoInfo | null;
  /** Большой логотип **/
  logoBig?: string;
  /** Логотип для моб. сайта **/
  logoMobile?: string;
  /** Маленький логотип **/
  logoSmall?: string;
  /** Настройки карты офисов **/
  mapSettingsOffice: IMapSettings;
  /** Название спецпроекта **/
  name: string;
  /** Название спецпроекта в родительном падеже **/
  nameGenitive: string;
  /** Список офисов **/
  officeList: IMapOffice[];
  /** Основной контактный телефон **/
  phone: string;
  /** Изображение для пина на карте **/
  pinImage?: string;
}

export interface ISpecialPromoBanner {
  id: number;
  imageUrl: string;
  text: string;
  urlLink: string;
  disclaimer?: string;
}

export interface ISpecialPromoInfo {
  html: string;
  text?: string;
  title?: string;
}

export interface IMapSettings {
  lat?: number;
  lng?: number;
  zoom?: number;
}

export interface IPinImage {
  url?: string | null;
}

export interface IMapOffice {
  address: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  id: number;
  isMain: boolean;
  name: string;
  phone: string;
}

export interface ISpecialPromoData {
  analyticsKey: string;
  color?: string;
  linkText?: string;
  linkUrl?: string;
  phone: string;
  workTime?: string;
}

export type ISpecialPromoConfig = {
  [key in ESpecialPromoType]: ISpecialPromoData;
};
