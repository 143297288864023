import { EUpcomingSaleModalStatuses } from '../../types/upcomingSaleRequest';
import { IRC } from '../../types/rc/rcs';
import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/actionGenerator';

export enum EUpcomingSaleActionTypes {
  OpenModal = 'upcomingSale/openModal',
  CloseModal = 'upcomingSale/closeModal',
  SetStatus = 'upcomingSale/setStatus',
}

export type TOpenUpcomingSaleModal = ITypedReduxAction<EUpcomingSaleActionTypes.OpenModal, IRC>;
export type TCloseUpcomingSaleModal = ITypedReduxAction<EUpcomingSaleActionTypes.CloseModal, void>;
export type TSetStatusUpcomingSaleModal = ITypedReduxAction<
  EUpcomingSaleActionTypes.SetStatus,
  EUpcomingSaleModalStatuses
>;

export const openUpcomingSaleModal = actionGenerator<EUpcomingSaleActionTypes.OpenModal, IRC>(
  EUpcomingSaleActionTypes.OpenModal,
);

export const closeUpcomingSaleModal = actionGenerator<EUpcomingSaleActionTypes.CloseModal>(
  EUpcomingSaleActionTypes.CloseModal,
);

export const setStatusUpcomingSaleModal = actionGenerator<
  EUpcomingSaleActionTypes.SetStatus,
  EUpcomingSaleModalStatuses
>(EUpcomingSaleActionTypes.SetStatus);

export type TUpcomingSaleActions = TOpenUpcomingSaleModal | TCloseUpcomingSaleModal | TSetStatusUpcomingSaleModal;
