import * as React from 'react';
import { useSaveSearchContext } from '../../../packages/SaveSearchModal';

import { SavedSearchButton } from '../../components/TopBar/SavedSearchButton';
import { EModalOpener } from '../../types/savedSearch';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';
import { trackSavedSearchPopupOpen } from './tracking';

export function SavedSearchButtonContainer() {
  const { onOpen, setParams } = useSaveSearchContext();

  const handleSaveSearchOpen = React.useCallback(() => {
    const saveSearchParams = { jsonQuery: jsonQueryHelper.dump() };

    trackSavedSearchPopupOpen(EModalOpener.SavedSearchButton);
    setParams(saveSearchParams);
    onOpen();
  }, [onOpen, setParams]);

  return <SavedSearchButton onClick={handleSaveSearchOpen} />;
}
