import { ca } from '@cian/analytics';

import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';

export function trackBannerShow(): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'newbuilding_banner',
    label: 'KPN',
    event: 'oldevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}

export function trackBannerClick(): void {
  ca('eventSite', {
    action: 'click',
    category: 'newbuilding_banner',
    label: 'KPN',
    name: 'oldevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}
