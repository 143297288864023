import * as React from 'react';
import { useSelector } from 'react-redux';

import { SellerBlock, ShowPhoneTooltip } from '../../components/ShowPhoneTooltip';
import { getGaLabel, getPhones } from '../../selectors/rcs';
import { CroppedPhoneContainer } from '../CroppedPhoneContainer';
import { trackShowPhone } from './tracking';

interface IOwnProps {
  id: number;
}

export function ShowPhoneButtonContainer({ id }: IOwnProps) {
  const phones = useSelector(getPhones(id));
  const gaLabel = useSelector(getGaLabel(id));

  const onClick = React.useCallback(() => trackShowPhone(gaLabel), [gaLabel]);

  if (!phones.length) {
    return null;
  }

  return (
    <ShowPhoneTooltip onOpen={onClick}>
      {phones.length === 1 ? (
        <SellerBlock
          name={phones[0].name}
          contacts={
            <CroppedPhoneContainer
              phone={phones[0].phone}
              siteBlockId={phones[0].siteBlockId}
              newbuildingId={id}
              open
            />
          }
        />
      ) : (
        phones.map(phone => (
          <SellerBlock
            key={phone.realtyUserId}
            name={phone.name}
            contacts={<CroppedPhoneContainer phone={phone.phone} siteBlockId={phone.siteBlockId} newbuildingId={id} />}
          />
        ))
      )}
    </ShowPhoneTooltip>
  );
}
