import { IJsonQuery } from '../../types/jsonQuery';
import { ResponseError } from '../../errors';
import { getBuilderIdFromJsonQuery, getIsSpecialPromoFromJsonQuery } from '../../utils/jsonQuery';
import { IApplicationContext } from '../../types/application';
import {
  fetchGetSpecialPromo,
  TGetSpecialPromoResponse_1,
  IGetSpecialPromoResponse200,
  IGetSpecialPromoResponse400,
} from '../../repositories/special-promos/v1/get-special-promo';

export const resolveSpecialPromo = async (
  context: IApplicationContext,
  jsonQuery: IJsonQuery,
): Promise<TGetSpecialPromoResponse_1 | null> => {
  const { httpApi, logger } = context;

  const isSpecialPromo = getIsSpecialPromoFromJsonQuery(jsonQuery);
  const builderId = getBuilderIdFromJsonQuery(jsonQuery);

  if (!isSpecialPromo || !builderId) {
    return null;
  }

  try {
    const response = await fetchGetSpecialPromo<IGetSpecialPromoResponse200, IGetSpecialPromoResponse400>({
      httpApi,
      parameters: { builderId },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'services#resolveSpecialPromo',
        message: response.response.message as string,
      });
    }

    return response.response as TGetSpecialPromoResponse_1;
  } catch (error) {
    logger.warning('[Degradation] Нет информации о спецпроекте, отображается обычная выдача ЖК', error);

    return null;
  }
};
