/**
 * @todo
 * Покрыть тестами в задаче https://jira.cian.tech/browse/CD-217891
 */

import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { useEffect, useState } from 'react';

import { fetchGetNewbuildingsInfoForTgbService } from '../services/newbuildingSearch';

import type { INewbuildingInfoForTgbSchema } from '../repositories/newbuilding-search/entities/get_newbuildings_info_for_tgb/NewbuildingInfoForTgbSchema';
import type { IHttpApi } from '@cian/http-api/shared';
import type { ILogger } from '@cian/logger/shared';

const infoForTgbMap = new Map<number, INewbuildingInfoForTgbSchema>();

export function useNewbuidingInfoForTgb(
  { logger, httpApi }: { logger: ILogger; httpApi: IHttpApi },
  { newBuildingId, newBuildingIdIsUsed }: TTgbNativedescGeneralContext,
): null | INewbuildingInfoForTgbSchema {
  /**
   * @description Только для значения newBuildingIdIsUsed === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1';

  const newBuildingIdInt = newBuildingId ? parseInt(newBuildingId, 10) : null;

  const [newbuidingInfoForTgb, setNewbuidingInfoForTgb] = useState<null | INewbuildingInfoForTgbSchema>(() => {
    /**
     * @description если уже много данных в кеше, то чистим его, по времени нет особого смыла как то кешировать,
     * так же как и какие данные оставлять в кеше
     */
    if (infoForTgbMap.size > 100) {
      infoForTgbMap.clear();

      return null;
    }

    let newbuidingInfoForTgb: null | INewbuildingInfoForTgbSchema = null;

    if (newBuildingIdInt && isEnrichmentFromBackendEnabled) {
      newbuidingInfoForTgb = infoForTgbMap.get(newBuildingIdInt) ?? null;
    }

    return newbuidingInfoForTgb;
  });

  useEffect(() => {
    if (isEnrichmentFromBackendEnabled && newBuildingIdInt && !newbuidingInfoForTgb) {
      fetchGetNewbuildingsInfoForTgbService({ logger, httpApi }, { newbuildingIds: [newBuildingIdInt] }).then(data => {
        infoForTgbMap.set(newBuildingIdInt, data.newbuildings[0]);

        setNewbuidingInfoForTgb(data.newbuildings[0]);
      });
    }
    /**
     * @description изменение данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return newbuidingInfoForTgb;
}
