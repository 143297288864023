import * as React from 'react';
import { useSelector } from 'react-redux';

import { Section } from '../../components/Section';
import { EmptySearchScreen } from '../../components/EmptySearchScreen';
import { AdfoxBannerParallaxBetweenOffers, AdfoxListingCarouselBanners } from '../../components/AdfoxBanner';
import { ChangeConditionsSearchContainer } from '../ChangeConditionsSearchContainer';
import { ListingBannerContainer } from '../ListingBannerContainer';
import { RCCardContainer } from '../RCCardContainer';
import { getIsLastPage, selectIsLoading } from '../../selectors/pagination';
import { getIsPromoPage, getSpecialPromoType } from '../../selectors/specialPromo';
import { selectRCsIds } from '../../selectors/rcs';

import { SERP_BANNER_POSITIONS } from './constants';
import { PreInfiniteBannerContainer } from '../PreInfiniteBannerContainer';
import { PaginationLoader } from '../../components/PaginationLoader';

export function ListingContainer() {
  const rcsIds = useSelector(selectRCsIds);
  const isLastPage = useSelector(getIsLastPage);
  const isPromoPage = useSelector(getIsPromoPage);
  const specialPromo = useSelector(getSpecialPromoType);
  const isLoading = useSelector(selectIsLoading);

  if (!rcsIds.length) {
    return (
      <>
        <Section>
          <EmptySearchScreen />
        </Section>
        <ChangeConditionsSearchContainer />
      </>
    );
  }

  return (
    <>
      {rcsIds.map((rcId, index) => {
        const position = index + 1;

        const rc = <RCCardContainer key={rcId} id={rcId} />;

        switch (true) {
          case isLastPage && position === rcsIds.length:
            return [rc, <PreInfiniteBannerContainer key={`pre-infinite-${rcId}`} />];

          case isPromoPage:
          case Boolean(specialPromo):
            return rc;

          case SERP_BANNER_POSITIONS.includes(position):
            return [rc, <ListingBannerContainer key={`banner-${rcId}`} position={position} />];

          case position % 10 === 0:
            return [rc, <AdfoxBannerParallaxBetweenOffers key={`adfox-${rcId}`} position={position} />];

          case position % 5 === 0:
            return [rc, <AdfoxListingCarouselBanners key={`adfox-${rcId}`} position={position} />];

          default:
            return rc;
        }
      })}
      {isLoading && <PaginationLoader />}
    </>
  );
}
