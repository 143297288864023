import { ErrorLogComponent } from '@cian/error-log-component';
import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

import { wrapDisplayName } from '../displayName';
import { Context } from './context';

export interface IWithErrorHandlerParameters {
  onError?(error: Error, errorInfo: React.ErrorInfo): void;
  errorStub?: JSX.Element;
}

function withErrorHandlerHoc<P>(
  Component: React.ComponentType<P>,
  parameters?: IWithErrorHandlerParameters,
): React.ComponentType<P> {
  return class WithErrorHandler extends React.Component<P> {
    public static displayName = wrapDisplayName(Component, 'withErrorHandler');

    public render() {
      return (
        <Context.Consumer>
          {logger => {
            if (!logger) {
              throw new ConfigurationError({
                message: "withErrorHandler used outside of it's context",
                domain: WithErrorHandler.displayName,
              });
            }

            return (
              <ErrorLogComponent
                logger={logger}
                onError={parameters && parameters.onError}
                errorStub={parameters && parameters.errorStub}
              >
                <Component {...this.props} />
              </ErrorLogComponent>
            );
          }}
        </Context.Consumer>
      );
    }
  };
}

export const withErrorHandler = (parameters?: IWithErrorHandlerParameters) => {
  return function <P>(Component: React.ComponentType<P>) {
    return withErrorHandlerHoc<P>(Component, parameters);
  };
};
