import { compose } from 'redux';
import { connect } from 'react-redux';
import { IApplicationState } from '../../types/redux';
import { SeoBlock } from '../../components/SeoBlock';
import { withEmptyRenderingStrict } from '../../utils/withEmptyRendering';
import { trackReadAllSeo } from './analytics/trackReadAllSeo';

interface IStateProps {
  text?: string;
  handleReadAllSeo(): void;
}

export function mapStateToProps({ seoData }: IApplicationState): IStateProps {
  return {
    text: seoData.bottomText,
    handleReadAllSeo: trackReadAllSeo,
  };
}

export const SeoBlockContainer: React.ComponentType<{}> = compose(
  connect(mapStateToProps),
  withEmptyRenderingStrict<IStateProps>(['text']),
)(SeoBlock);
