import * as React from 'react';
import { useSelector } from 'react-redux';

import { QuickLinks } from '../../components/QuickLinks';
import { trackClickQuickLink, trackShowQuickLinks } from './analytics';
import { getQuickLinks } from '../../selectors/quickLinks';
import { getIsPromoPage } from '../../selectors/specialPromo';
import { selectRCsIds } from '../../selectors/rcs';
import { SortingSelectContainer } from '../SortingSelectContainer';
import { yieldToMainThread } from '../../utils/yieldToMainThread';
import { getMarkedListItems, getSubtitle } from '../../selectors/seoData';

export const QuickLinksContainer = () => {
  const quickLinks = useSelector(getQuickLinks);
  const isPromoPage = useSelector(getIsPromoPage);
  const newbuildingIds = useSelector(selectRCsIds);
  const markedList = useSelector(getMarkedListItems);
  const offersCountTitle = useSelector(getSubtitle);

  const subtitle = React.useMemo(() => {
    const seoSubtitle = markedList.find(item => item.underH1)?.content;

    if (seoSubtitle || quickLinks.length > 0) {
      return;
    }

    return offersCountTitle;
  }, [markedList, quickLinks, offersCountTitle]);

  const showSortingButton = React.useMemo(() => {
    return newbuildingIds.length > 0 && !isPromoPage;
  }, [newbuildingIds, isPromoPage]);

  const handleClickQuickLink = React.useCallback(async (url: string) => {
    await yieldToMainThread();
    trackClickQuickLink({ url });
  }, []);

  React.useEffect(() => {
    yieldToMainThread().then(() => {
      trackShowQuickLinks();
    });
  }, []);

  return (
    <QuickLinks
      subtitle={subtitle}
      quickLinks={quickLinks}
      handleClickQuickLink={handleClickQuickLink}
      sortingButton={showSortingButton && <SortingSelectContainer />}
    />
  );
};
