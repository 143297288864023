import { trackPageView } from '../../analytics';
import { getSubdomain } from '../../selectors/location';
import { selectPage } from '../../selectors/pagination';
import { fetchSearchNewbuildings } from '../../services/newbuildings';
import { EErrorNotificationType } from '../../types/notifications';
import { TThunkAction } from '../../types/redux';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';
import { pushErrorNotification } from '../notifications';
import { failurePagination, requestPagination, successPagination } from './pagination';
import { IJsonQuery } from '../../types/jsonQuery';

export const paginateOffers = (): TThunkAction<Promise<void>> => async (dispatch, getState, context) => {
  const { httpApi, logger, config } = context;

  const state = getState();
  const page = selectPage(state);
  const subdomain = getSubdomain(state);

  const jsonQuery = jsonQueryHelper.mergePage(page + 1);
  dispatch(requestPagination());

  try {
    const result = await fetchSearchNewbuildings(httpApi, { jsonQuery, subdomain });

    jsonQueryHelper.set(result.jsonQuery as IJsonQuery);
    dispatch(successPagination(result));

    trackPageView(getState(), config);
  } catch (error) {
    logger.error(error);

    dispatch(pushErrorNotification(EErrorNotificationType.Pagination));
    dispatch(failurePagination());
  }
};
