import * as React from 'react';

import { IMedia } from './types/media';
import { ClientComponent } from '../ClientComponent';
import { CarouselPhoto } from './components/CarouselPhoto';
import { CarouselSliderLoadable } from './components/CarouselSlider';
import * as styles from './Carousel.css';

interface ICarouselProps {
  media: IMedia[];
}

export const Carousel: React.FC<ICarouselProps> = ({ media }) => {
  return (
    <div className={styles['container']}>
      <ClientComponent replaceNode={<CarouselPhoto key={`slide_${0}`} src={media[0].url} />}>
        <CarouselSliderLoadable media={media} />
      </ClientComponent>
    </div>
  );
};
