// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TCreateUpcomingSaleRequestRequest,
  TCreateUpcomingSaleRequestModel,
  IMappers,
  TCreateUpcomingSaleRequestResponse,
  TCreateUpcomingSaleRequestResponse_1,
  TCreateUpcomingSaleRequestResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TCreateUpcomingSaleRequestModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'newbuilding-flat-view-order',
  pathApi: '/v1/create-upcoming-sale-request/',
  requestType: 'json',
} as TCreateUpcomingSaleRequestModel;

export function createCreateUpcomingSaleRequestModel(
  parameters: TCreateUpcomingSaleRequestRequest,
): TCreateUpcomingSaleRequestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateUpcomingSaleRequestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TCreateUpcomingSaleRequestRequest;
}

export async function fetchCreateUpcomingSaleRequest<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICreateUpcomingSaleRequestOptions<TResponse200, TResponse400>): Promise<
  TCreateUpcomingSaleRequestResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCreateUpcomingSaleRequestModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TCreateUpcomingSaleRequestResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TCreateUpcomingSaleRequestResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCreateUpcomingSaleRequestResponse;
}
