import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import * as React from 'react';

import { TgbNativedescGeneral } from './TgbNativedescGeneral';
import { useApplicationContext } from '../../../../../utils/applicationContext';
import { useNewbuidingInfoForTgb } from '../../../../../hooks/useNewbuidingInfoForTgb';

type TgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: React.FC<TgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const { annotation, image, url, description, title, label, logoImageUrl } = context;

  const { httpApi, logger } = useApplicationContext();

  const newbuidingInfoForTgb = useNewbuidingInfoForTgb({ httpApi, logger }, context);

  return (
    <TgbNativedescGeneral
      logoUrl={newbuidingInfoForTgb?.publisher.logo.url || logoImageUrl}
      url={url}
      label={label}
      image={image}
      annotation={annotation}
      title={title}
      description={description}
    />
  );
};
