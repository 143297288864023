import { IRC } from './rc/rcs';

export enum EUpcomingSaleModalStatuses {
  Inactive = 'inactive',
  Form = 'form',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export interface IUpcomingSaleRequestState {
  status: EUpcomingSaleModalStatuses;
  newbuilding: IRC | null;
}

export interface IUpcomingSaleRequest {
  email?: string;
  phone: string;
  userName: string;
}

export interface IValidationErrors {
  [key: string]: string | undefined;
}
