import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/actionGenerator';

export enum EBuilderChatActionTypes {
  OpenChat = 'builderChat/open',
  CloseChat = 'builderChat/close',
  OpenChatAfterAuth = 'builderChat/openAfterAuth',
}

export type TOpenBuilderChatParameters = {
  defaultMessage?: string;
  gaLabel?: string;
  realtyUserId?: number;
};

export const openBuilderChat = actionGenerator<EBuilderChatActionTypes.OpenChat, TOpenBuilderChatParameters>(
  EBuilderChatActionTypes.OpenChat,
);

export const openBuilderChatAfterAuth = actionGenerator<
  EBuilderChatActionTypes.OpenChatAfterAuth,
  TOpenBuilderChatParameters
>(EBuilderChatActionTypes.OpenChatAfterAuth);

export const closeBuilderChat = actionGenerator<EBuilderChatActionTypes.CloseChat, void>(
  EBuilderChatActionTypes.CloseChat,
);

export type TBuilderChatActions =
  | ITypedReduxAction<EBuilderChatActionTypes.OpenChat, TOpenBuilderChatParameters>
  | ITypedReduxAction<EBuilderChatActionTypes.OpenChatAfterAuth, TOpenBuilderChatParameters>
  | ITypedReduxAction<EBuilderChatActionTypes.CloseChat>;
