import * as React from 'react';
import { useSelector } from 'react-redux';
import { getPromoLabels, isProblemSelector } from '../../selectors/rcs';
import { trackProblematicRCClick } from './analytics';
import { ProblemLabel } from '../../components/Labels';
import { Label } from '@cian/ui-kit';
import { EColor } from '../../repositories/search-engine/entities/newbuilding/PromoLabelSchema';
import { TColors } from '@cian/ui-kit-design-tokens/colors';

interface ILabelsProps {
  id: number;
}

const LABEL_COLOR_MAP: { [key in EColor]: TColors } = {
  [EColor.Green]: 'accent-positive-primary',
  [EColor.LightGreen]: 'surface-positive-default',
};
const LABEL_TEXT_COLOR_MAP: { [key in EColor]: TColors } = {
  [EColor.Green]: 'text-inverted-default',
  [EColor.LightGreen]: 'accent-positive-primary',
};

export const Labels = ({ id }: ILabelsProps) => {
  const isProblem = useSelector(isProblemSelector(id));
  const promoLabels = useSelector(getPromoLabels(id));

  const labels = React.useMemo(() => {
    return promoLabels.map(label => {
      return {
        background: LABEL_COLOR_MAP[label.color],
        color: LABEL_TEXT_COLOR_MAP[label.color],
        text: label.name,
      };
    });
  }, [promoLabels]);

  const onProblematicClick = React.useCallback(() => {
    trackProblematicRCClick('red', id);
  }, [id]);

  if (!isProblem && labels.length === 0) {
    return null;
  }

  return (
    <>
      {isProblem && <ProblemLabel onClick={onProblematicClick} />}
      {labels.map((label, index) => (
        <Label background={label.background} color={label.color} key={index}>
          {label.text}
        </Label>
      ))}
    </>
  );
};
