import * as React from 'react';
import { Label } from '@cian/ui-kit';

interface IProblemBlockProps {
  onClick(): void;
}

export function ProblemLabel({ onClick }: IProblemBlockProps) {
  return (
    <Label background="surface-negative-default" color="accent-negative-primary" onClick={onClick}>
      Будьте внимательны
    </Label>
  );
}
