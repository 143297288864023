import * as React from 'react';

import { Button } from '@cian/ui-kit';

export interface IPhoneButtonProps {
  text?: string;
  onClick(): void;
}

export function PhoneButton({ text, onClick }: IPhoneButtonProps) {
  return (
    <Button size="M" onClick={onClick} theme="fill_primary" fullWidth>
      {text}
    </Button>
  );
}
