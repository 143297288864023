import { useSelector } from 'react-redux';
import { getBuilderChatUrlParams } from '../selectors/builderChat';

interface IParams {
  isChatOpened: boolean;
}

export const useBuilderChatUrl = ({ isChatOpened }: IParams): string | undefined => {
  const { defaultMessage, realtyUserId, gaLabel } = useSelector(getBuilderChatUrlParams);

  if (!isChatOpened) {
    return undefined;
  }

  return (
    `/dialogs/?hostType=frame&agentId=${realtyUserId as number}` +
    `${defaultMessage ? '&defaultMessage=' + encodeURIComponent(defaultMessage) : /* istanbul ignore next */ ''}` +
    `${gaLabel ? '&gaLabel=' + encodeURIComponent(gaLabel) : /* istanbul ignore next */ ''}` +
    '&noDefaultMsgIfChatExists=1' +
    '&pageType=ListingJK'
  );
};
