import * as React from 'react';
import { useSelector } from 'react-redux';

import { SeoCrossLinks } from '../../components/SeoCrossLinks';
import { selectCrossLinks } from '../../selectors/seoCrossLinks';

export const SeoCrossLinksContainer: React.VFC = () => {
  const seoCrossLinks = useSelector(selectCrossLinks);

  if (!seoCrossLinks || seoCrossLinks.length === 0) {
    return null;
  }

  return <SeoCrossLinks items={seoCrossLinks} />;
};
