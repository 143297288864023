import { actionGenerator } from '../../utils/actionGenerator';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EDeleteFavoritesActionTypes {
  Request = 'DELETE_FAVORITE/REQUEST',
  Success = 'DELETE_FAVORITE/SUCCESS',
  Failure = 'DELETE_FAVORITE/FAILURE',
}

interface IPayload {
  rcId: number;
}

type TDeleteFavoriteRequest = ITypedReduxAction<EDeleteFavoritesActionTypes.Request, IPayload>;
type TDeleteFavoriteSuccess = ITypedReduxAction<EDeleteFavoritesActionTypes.Success, IPayload>;
type TDeleteFavoriteFailure = ITypedReduxAction<EDeleteFavoritesActionTypes.Failure, IPayload>;

export type TDeleteFavorite = TDeleteFavoriteRequest | TDeleteFavoriteSuccess | TDeleteFavoriteFailure;

export const deleteFavoriteRequest = actionGenerator<EDeleteFavoritesActionTypes.Request, IPayload>(
  EDeleteFavoritesActionTypes.Request,
);

export const deleteFavoriteSuccess = actionGenerator<EDeleteFavoritesActionTypes.Success, IPayload>(
  EDeleteFavoritesActionTypes.Success,
);

export const deleteFavoriteFailure = actionGenerator<EDeleteFavoritesActionTypes.Failure, IPayload>(
  EDeleteFavoritesActionTypes.Failure,
);
