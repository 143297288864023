export interface IRCPessimization {
  /** Текст на фото **/
  text: string;
  /** Тип пессимизации **/
  type: ERCPessimizationType;
}

export enum ERCPessimizationType {
  /** Легкая пессимизация **/
  Light = 'light',
  /** Сильная пессимизация **/
  Strong = 'strong',
}
