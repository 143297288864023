import cx from 'clsx';
import * as React from 'react';
import { IconActionChevronUpSmall16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from './SeoCrossLinksGroup.css';
import { capitalize } from './utis/capitalize';
import { ILink } from '../../types/crossLinks';

interface ISeoCrossLinksGroupProps {
  title: string;
  links: ILink[];
  id: string;
}

export function SeoCrossLinksGroup({ title, links, id }: ISeoCrossLinksGroupProps) {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const capitalizedTitle = React.useMemo(() => capitalize(title), [title]);
  const headingId = React.useMemo(() => `${id}_heading`, [id]);
  const bodyId = React.useMemo(() => `${id}_body`, [id]);

  const toggleExpandedState = React.useCallback(() => {
    setExpanded(prevState => !prevState);
  }, []);

  return (
    <div className={cx(styles['container'], expanded && styles['container_expanded'])} data-testid="SeoCrossLinksGroup">
      <div
        id={headingId}
        data-testid={headingId}
        role="button"
        aria-expanded={expanded}
        aria-controls={bodyId}
        tabIndex={0}
        className={styles['title']}
        onClick={toggleExpandedState}
      >
        <div className={styles['heading']}>{capitalizedTitle}</div>
        <div className={styles['arrow-wrapper']}>
          <IconActionChevronUpSmall16 />
        </div>
      </div>

      <ul className={styles['list']} id={bodyId} data-testid={bodyId} aria-describedby={headingId}>
        {links.map((link, index) => (
          <li key={`${id}-link-${index}`}>
            <a className={styles['link']} href={link.uri}>
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
