import { EUpcomingSaleActionTypes, TUpcomingSaleActions } from '../../actions/upcomingSaleRequest';
import { EUpcomingSaleModalStatuses, IUpcomingSaleRequestState } from '../../types/upcomingSaleRequest';

export const defaultState: IUpcomingSaleRequestState = {
  newbuilding: null,
  status: EUpcomingSaleModalStatuses.Inactive,
};

export function upcomingSaleRequestReducer(
  state: IUpcomingSaleRequestState = defaultState,
  action: TUpcomingSaleActions,
) {
  switch (action.type) {
    case EUpcomingSaleActionTypes.OpenModal:
      return {
        ...state,
        newbuilding: action.payload,
        status: EUpcomingSaleModalStatuses.Form,
      };
    case EUpcomingSaleActionTypes.CloseModal:
      return {
        ...state,
        newbuilding: null,
        status: EUpcomingSaleModalStatuses.Inactive,
      };
    case EUpcomingSaleActionTypes.SetStatus:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
}
