import * as React from 'react';
import { ArticleHeading4 } from '@cian/ui-kit';

import * as styles from './SellerBlock.css';

interface ISellerBlockProps {
  contacts: React.ReactNode;
  name: string;
}

export function SellerBlock({ name, contacts }: ISellerBlockProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['name']}>
        <ArticleHeading4 as="h4">{name}</ArticleHeading4>
      </div>
      {contacts}
    </div>
  );
}
