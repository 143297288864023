import * as React from 'react';

import { LinkArea } from '../LinkArea';
import { Section } from '../Section';

import * as styles from './Card.css';
import { Responsive, UIHeading5 } from '@cian/ui-kit';

export interface ICardProps {
  buildingStatus: React.ReactNode;
  controlButton: React.ReactNode;
  favorite: React.ReactNode;
  features: React.ReactNode;
  gallery: React.ReactNode;
  href: string;
  location: React.ReactNode;
  onClick(event: React.MouseEvent): void;
  priceLimits: string;
  rcOffers: React.ReactNode;
  showPhone: React.ReactNode;
  title: React.ReactNode;
  name: string;
  reviewLink: React.ReactNode;
  author: React.ReactNode;
  labels: React.ReactNode;
}

const CardComponent: React.FC<ICardProps> = props => {
  const {
    buildingStatus,
    controlButton,
    favorite,
    features,
    gallery,
    href,
    location,
    onClick,
    priceLimits,
    rcOffers,
    showPhone,
    title,
    name,
    reviewLink,
    author,
    labels,
  } = props;

  return (
    <Section className={styles['paper']}>
      <div className={styles['card']} data-testid="card">
        <Responsive phone>
          <LinkArea href={href} title={name} onClick={onClick}>
            <div className={styles['author']}>{author}</div>
          </LinkArea>
        </Responsive>
        <a className={styles['media']} href={href} onClick={onClick} target="_blank" rel="noreferrer">
          <div className={styles['carousel']}>
            <div className={styles['features']}>{features}</div>
            <div className={styles['gallery']}>{gallery}</div>
          </div>
        </a>
        <Responsive phone>
          <div className={styles['labels']}>{labels}</div>
        </Responsive>
        <div className={styles['container']}>
          <div className={styles['review-link']}>{reviewLink}</div>
          <LinkArea href={href} title={name} onClick={onClick}>
            <div className={styles['content']}>
              <div className={styles['rc-title']}>{title}</div>
              <div className={styles['building-status']}>{buildingStatus}</div>
              <div className={styles['price-limits']}>
                <UIHeading5>{priceLimits}</UIHeading5>
              </div>
              <Responsive tablet>
                <div className={styles['labels']}>{labels}</div>
              </Responsive>
              {location}
            </div>
          </LinkArea>
          {rcOffers}

          <Responsive phone>
            <div className={styles['call']}>{controlButton}</div>
          </Responsive>

          <div className={styles['controls']} onClick={onClick}>
            <Responsive tablet>{showPhone}</Responsive>
            <div className={styles['favorite']}>{favorite}</div>
          </div>
        </div>
      </div>
    </Section>
  );
};

CardComponent.displayName = 'CardComponent';

export const Card = React.memo(CardComponent);
