import * as React from 'react';
import * as styles from './SeoSubtitles.css';

interface ISeoSubtitlesProps {
  subtitles: string;
}
export const SeoSubtitles: React.FC<ISeoSubtitlesProps> = ({ subtitles }) => {
  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <div data-testid="SeoSubtitles" className={styles['list']} dangerouslySetInnerHTML={{ __html: subtitles }} />
    </>
  );
};
