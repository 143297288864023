import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import { FlightBanner } from '../../components/FlightBanner';
import { trackBannerClick, trackBannerShow } from './tracking';

interface IFlightBannerContainerProps {
  url: string;
}

export const FlightBannerContainer: React.FC<IFlightBannerContainerProps> = ({ url }) => {
  return (
    <ActionAfterViewObserver callback={trackBannerShow} triggerOnce>
      <FlightBanner url={url} onBannerClick={trackBannerClick} />
    </ActionAfterViewObserver>
  );
};
