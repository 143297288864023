enum Regions {
  Moscow = 1,
  MoscowRegion = 4593,
  SaintPetersburg = 2,
  LeningradRegion = 4588,
}

export const COUNTRYSIDE_REGIONS_MAP: { [key: number]: number } = {
  [Regions.Moscow]: Regions.MoscowRegion,
  [Regions.SaintPetersburg]: Regions.LeningradRegion,
};
