import { ITypedReduxAction, TThunkAction } from '../../types/redux';

export enum EAuthenticationActionType {
  Succeed = 'authentication/succeed',
  Cancelled = 'authentication/cancelled',
}

export type TAuthenticationSucceed = ITypedReduxAction<EAuthenticationActionType.Succeed>;
export type TAuthenticationCancelled = ITypedReduxAction<EAuthenticationActionType.Cancelled>;

export type TAuthenticationActions = TAuthenticationSucceed | TAuthenticationCancelled;

export interface IRequestAuthenticationParameters {
  viewData?: IViewData;
  source?: string;
  alert?: EAlertType;
  onSuccess?(): TThunkAction;
  onCloseNoAuth?(): TThunkAction;
}

export interface IAuthenticateEvent {
  autoRefresh?: boolean;
  viewData?: IViewData | null;
  source?: string | null;
}

interface IViewData {
  headingType: string;
}

export enum EAlertType {
  Favorites = 'favorites',
  Chats = 'chats',
  Hide = 'hide',
}
