import { isNil } from '../../isNil';
import { withEmptyRendering } from './withEmptyRendering';

type OptionalProps<T> = { [P in keyof T]: T[P] | undefined | null };

export function withEmptyRenderingStrict<T, K extends keyof T = keyof T>(keys: K[]) {
  return withEmptyRendering<Omit<T, K> & OptionalProps<Pick<T, K>>>(validator<T, K>(keys));
}

function validator<T, K extends keyof T>(keys: K[]) {
  return (props: T) =>
    keys.some(key => {
      const value = props[key];

      const isNilOrEmpty = isNil(value) || value === '' || (Array.isArray(value) && value.length === 0);

      return isNilOrEmpty;
    });
}
