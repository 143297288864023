import { ca } from '@cian/analytics';

export function trackProblematicRCClick(label: 'red' | 'green', rcId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing_JK',
    action: 'JK_problems_icon_click',
    label: `${label}/${rcId}`,
  });
}
