import { ca } from '@cian/analytics';

export function trackRCPreviewClick(label: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'jk-listing',
    action: 'click',
    label,
  });
}
