import * as React from 'react';

import * as styles from './AdfoxListingBannerWrapper.css';

interface IAdfoxListingBannerWrapperProps {
  onClick?(): void;
  children: React.ReactNode;
}

export const AdfoxListingBannerWrapper: React.FC<IAdfoxListingBannerWrapperProps> = ({ children, onClick }) => {
  return (
    <section data-testid="adfox-listing-banner-wrapper" className={styles['container']} onClick={onClick}>
      {children}
      <div className={styles['spacer']} />
    </section>
  );
};
