import { connect } from 'react-redux';
import { EPageStatus } from '../../types/pageStatus';
import { IApplicationState } from '../../types/redux';

import { Page } from './Page';

interface IStateProps {
  isLoading: boolean;
}

export function mapStateToProps({ pageStatus }: IApplicationState): IStateProps {
  return {
    isLoading: pageStatus === EPageStatus.Loading,
  };
}

export const PageContainer = connect(mapStateToProps)(Page);
