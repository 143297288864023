/**
 * @todo
 * Покрыть тестами в задаче https://jira.cian.tech/browse/CD-217891
 */

import { Ticker } from '@cian/adfox-templates';
import { Label, Image, UIHeading4, UIHeading5 } from '@cian/ui-kit';
import * as React from 'react';

import styles from './TgbNativedescGeneral.css';

export type TgbNativedescGeneralProps = {
  url: string;
  image: string;
  logoUrl: string | undefined;
  title: string;
  annotation: string;
  label: string | undefined;
  description: string | undefined;
};

export const TgbNativedescGeneral: React.FC<TgbNativedescGeneralProps> = props => {
  const { title, annotation, image, url, label, logoUrl, description } = props;

  return (
    <a
      data-testid="TgbNativedescGeneral"
      href={url}
      target="_blank"
      className={styles['container']}
      rel="noopener noreferrer"
    >
      <div className={styles['photo-block']} style={{ backgroundImage: `url(${image})` }}>
        {!!label && (
          <div className={styles['photo-label']}>
            {/* @ts-expect-error Нет замене black_60 в новой палитре */}
            <Label color="decorative-theme-white" background="black_60">
              {label}
            </Label>
          </div>
        )}
        {!!annotation && (
          <div className={styles['annotation']}>
            <Ticker withBackground text={annotation} />
          </div>
        )}
      </div>
      <div className={styles['content']}>
        <div className={styles['title-container']}>
          <UIHeading4>{title}</UIHeading4>
          {!!description && <UIHeading5>{description}</UIHeading5>}
        </div>
        {!!logoUrl && (
          <div className={styles['logo-container']}>
            <Image src={logoUrl} borderRadius="4px" width={40} height={40} objectFit="contain" />
          </div>
        )}
      </div>
    </a>
  );
};
