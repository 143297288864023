import { ITypedReduxAction } from '../../types/redux';

/**
 * Утилита для генерации экшенов. Позволяет создавать экшены с данными и без.
 * На уровне ТС происходит валидация данных.
 *
 * @example
 *
 * // Экшены без данных:
 * const fooActionCreator = actionGenerator<EActionType.Foo>(EActionType.Foo);
 *
 * dispatch(fooActionCreator()) // => { type: EActionType.Foo };
 *
 * // Экшены с данными:
 * const barActionCreator = actionGenerator<EActionType.Bar, string>(EActionType.Bar);
 *
 * dispatch(barActionCreator('Bar'));
 * // => { type: EActionType.Bar, payload: 'Bar' };
 *
 * // Созданный creator будет валидировать данные:
 * interface IFizPayload {
 *   bool: boolean;
 *   str: string;
 * }
 *
 * const fizActionCreator = actionGenerator<EActionType.Fiz, IFizPayload>(EActionType.Fiz);
 *
 * fizActionCreator({ bool: false });
 * // => Error: Property 'str' is missing in type
 *
 * fizActionCreator({ bool: false, str: 1 });
 * // => Error: Type "number" is not assignable to type "string"
 *
 * fizActionCreator({ bool: false, str: 'str' });
 * // => { type: EActionType.Fiz, payload: { bool: false, str: 'str' } };
 *
 * @template Type - generic, соответствует action type
 * @template Payload - generic, соответствует action payload
 * @param {Type} type - action type
 * @returns {(paylaod?: Payload) => ITypedReduxAction<Type, Payload>} - action creator
 */

export function actionGenerator<Type>(type: Type): () => ITypedReduxAction<Type, void>;
export function actionGenerator<Type, Payload>(type: Type): (payload: Payload) => ITypedReduxAction<Type, Payload>;
export function actionGenerator<Type, Payload = void>(type: Type) {
  return (payload?: Payload): ITypedReduxAction<Type, Payload | void> => ({
    type,
    payload,
  });
}
