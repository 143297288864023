import * as React from 'react';
import { useSelector } from 'react-redux';
import { NewbuildingFeatures } from '../../components/NewbuildingFeatures';
import { getFeatures } from '../../selectors/rcs';
import { IApplicationState } from '../../types/redux';

interface INewbuildingFeaturesContainerProps {
  id: number;
}

export function NewbuildingFeaturesContainer({ id }: INewbuildingFeaturesContainerProps) {
  const features = useSelector((state: IApplicationState) => getFeatures(state, id));

  if (!features.length) {
    return null;
  }

  return <NewbuildingFeatures features={features} />;
}
