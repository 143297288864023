import { IResponseDataSchema } from '../../repositories/search-engine/entities/response/ResponseDataSchema';
import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/actionGenerator';

export enum EPaginationActionTypes {
  Request = 'PAGINATION/REQUEST',
  Success = 'PAGINATION/SUCCESS',
  Failure = 'PAGINATION/FAILURE',
}

export type TRequestPagination = ITypedReduxAction<EPaginationActionTypes.Request, void>;
export type TSuccessPagination = ITypedReduxAction<EPaginationActionTypes.Success, IResponseDataSchema>;
export type TFailurePagination = ITypedReduxAction<EPaginationActionTypes.Failure, void>;

export const requestPagination: () => TRequestPagination = actionGenerator<EPaginationActionTypes.Request>(
  EPaginationActionTypes.Request,
);

export const successPagination: (data: IResponseDataSchema) => TSuccessPagination = actionGenerator<
  EPaginationActionTypes.Success,
  IResponseDataSchema
>(EPaginationActionTypes.Success);

export const failurePagination: () => TFailurePagination = actionGenerator<EPaginationActionTypes.Failure>(
  EPaginationActionTypes.Failure,
);
