import * as React from 'react';
import { DiscountMortgage, DiscountMortgagePopup } from '../../components/DiscountMortgage';
import { useSelector } from 'react-redux';
import { discountMortgagePopupSelector, isDiscountMortgageActiveSelector } from '../../selectors/discountMortgage';

export const DiscountMortgageContainer = () => {
  const [isOpen, setOpen] = React.useState(false);
  const isDiscountMortgageActive = useSelector(isDiscountMortgageActiveSelector);
  const popup = useSelector(discountMortgagePopupSelector);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onClick = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  if (!isDiscountMortgageActive || !popup) {
    return null;
  }

  return (
    <>
      <DiscountMortgage
        onClick={onClick}
        text="Льготная ипотека на новостройки действительна до 1 июля 2024 года. Подробности уточняйте у застройщиков"
      />
      <DiscountMortgagePopup
        open={isOpen}
        description={popup.description}
        title={popup.name}
        props={popup.props}
        onClose={onClose}
      />
    </>
  );
};
