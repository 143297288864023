import * as React from 'react';
import * as style from './style.css';
import { UIHeading4, UIText2 } from '@cian/ui-kit';

interface IDiscountMortgageBannerProps {
  url: string;
  onClick: () => void;
}

export const DiscountMortgageBanner = ({ url, onClick }: IDiscountMortgageBannerProps) => {
  return (
    <a
      className={style['banner']}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      data-testid="DiscountMortgageBanner"
    >
      <div className={style['content']}>
        <div className={style['coin']} />
        <div className={style['text']}>
          <UIHeading4>
            Льготная ипотека&nbsp;— <br />
            успейте воспользоваться
          </UIHeading4>
          <UIText2>До 01.07.2024</UIText2>
        </div>
      </div>
    </a>
  );
};
