import * as React from 'react';
import { useSelector } from 'react-redux';

import { SpecialPromoFooter } from '../../components/SpecialPromoFooter';
import { getSpecialPromoType } from '../../selectors/specialPromo';
import { ISpecialPromoData } from '../../types/specialPromo';
import { useApplicationContext } from '../../utils/applicationContext';
import { getSpecialPromoInfo } from '../../utils/config';
import { trackMobFooterClick, trackPhonesCall } from './tracking';

export const SpecialPromoFooterContainer = () => {
  const { config } = useApplicationContext();
  const promoType = useSelector(getSpecialPromoType);

  const data = React.useMemo(() => promoType && getSpecialPromoInfo(config, promoType), [promoType, config]);

  const handleLinkClick = React.useCallback(() => {
    trackMobFooterClick((data as ISpecialPromoData).analyticsKey);
  }, [data]);

  const handlePhoneClick = React.useCallback(() => {
    trackPhonesCall((data as ISpecialPromoData).analyticsKey);
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <SpecialPromoFooter
      color={data.color}
      linkText={data.linkText}
      linkUrl={data.linkUrl}
      phone={data.phone}
      workTime={data.workTime}
      onLinkClick={handleLinkClick}
      onPhoneClick={handlePhoneClick}
    />
  );
};
