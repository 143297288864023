import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';

export function trackFlatTourPromoShow(): void {
  ca('eventSite', {
    action: 'new_building_banner_show_sopr',
    category: 'ListingJK',
    label: 'subsidized_mortgage',
    name: 'soprevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}

export function trackFlatTourPromoClick(): void {
  ca('eventSite', {
    action: 'new_building_banner_click',
    category: 'ListingJK',
    label: 'subsidized_mortgage',
    name: 'oldevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}
