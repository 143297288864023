import * as React from 'react';
import { ArticleParagraph2, ArticleParagraph3 } from '@cian/ui-kit';
import { IconActionClose12 } from '@cian/ui-kit-design-tokens/icons';
import { ESalesType } from '../../types/salesDescription';
import { getIconByType } from './utils';

import * as styles from './SalesDescription.css';

export interface ISalesDescriptionProps {
  label?: string;
  onClose?(): void;
  text: string;
  type: ESalesType;
}

export function SalesDescription({ onClose, text, label, type }: ISalesDescriptionProps) {
  const SalesTypeIcon = getIconByType(type);

  return (
    <div className={styles['container']}>
      <div className={styles['container-content']}>
        {SalesTypeIcon && (
          <div className={styles.icon} data-testid="SalesDescription-Icon">
            <SalesTypeIcon color="decorative-theme-black" />
          </div>
        )}
        <ArticleParagraph2 color="decorative-theme-black">{text}</ArticleParagraph2>
        <button type="button" className={styles.close} onClick={onClose} data-testid="SalesDescription-CloseButton">
          <IconActionClose12 color="icon-secondary-default" />
        </button>
      </div>
      {label && (
        <ArticleParagraph3 color="gray40_100" data-testid="SalesDescription-Label">
          {label}
        </ArticleParagraph3>
      )}
    </div>
  );
}
