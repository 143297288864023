import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { IAnalytics } from '../../types/analytics';
import { prepareProducts } from '../../mappers/prepareProducts';
import { TReduxActions } from '../../types/redux';

const defaultState = {
  products: {
    entities: {},
    pages: [],
  },
  queryString: '',
  offersQty: 0,
};

export function analyticsReducer(state: IAnalytics = defaultState, action: TReduxActions): IAnalytics {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
      return {
        products: prepareProducts(action.payload),
        queryString: action.payload?.queryString || '',
        offersQty: action.payload?.offersCount || 0,
      };

    case EPaginationActionTypes.Success: {
      const products = prepareProducts(action.payload);

      return {
        ...state,
        products: {
          entities: {
            ...state.products.entities,
            ...products.entities,
          },
          pages: [...state.products.pages, ...products.pages],
        },
      };
    }

    default:
      return state;
  }
}
