import * as React from 'react';
import classNames from 'clsx';

import { WithChangeOrientation } from '../WithChangeOrientation';
import { CollapsedBlockButton } from './CollapsedBlockButton';

import * as styles from './CollapsedBlock.css';

const DEFAULT_COLLAPSED_HEIGHT = 96;

interface ICollapsedButtonProps {
  opened: boolean;
  toggle(): void;
}

interface ICollapsedBlockProps {
  opened?: boolean;
  collapsedHeight: number;
  className?: string;
  withShadow: boolean;
  onChangeOpen?(opened: boolean): void;
  Button: React.ComponentType<ICollapsedButtonProps>;
  children: React.ReactNode;
}

interface ICollapsedBlockState {
  opened: boolean;
  contentHeight: number;
  isInited: boolean;
}

const CSS_MARGIN_BEFORE_BUTTON = 8;

export class CollapsedBlock extends React.PureComponent<ICollapsedBlockProps, ICollapsedBlockState> {
  public static defaultProps: Partial<ICollapsedBlockProps> = {
    withShadow: true,
    collapsedHeight: DEFAULT_COLLAPSED_HEIGHT,
    Button: CollapsedBlockButton,
  };

  private contentEl = React.createRef<HTMLDivElement>();

  public constructor(props: ICollapsedBlockProps) {
    super(props);

    this.state = {
      opened: props.opened || false,
      contentHeight: props.collapsedHeight as number,
      isInited: false,
    };
  }

  public componentDidUpdate(prevProps: ICollapsedBlockProps, prevState: ICollapsedBlockState) {
    const { opened } = this.state;

    if (prevState.opened !== opened) {
      const { onChangeOpen } = this.props;

      if (onChangeOpen) {
        onChangeOpen(opened);
      }
    }
  }

  public render() {
    if (!this.props.children) {
      return null;
    }

    const { children, className, collapsedHeight, withShadow, Button } = this.props;
    const { opened, contentHeight } = this.state;
    const withButton = this.isExpanderVisible();
    const collapsed = withButton && !opened;

    return (
      <WithChangeOrientation handler={this.updateContentHeight}>
        <div className={className} data-name="CollapsedBlock">
          <div
            className={classNames(
              styles['content'],
              collapsed && withShadow && styles['with-shadow'],
              withButton && styles['with-button'],
            )}
            style={{
              maxHeight: `${
                collapsed ? collapsedHeight : contentHeight + (this.state.isInited ? CSS_MARGIN_BEFORE_BUTTON : 0)
              }px`,
            }}
          >
            <div ref={this.contentEl} className={classNames(withButton && styles['content-wrapper'])}>
              {children}
            </div>
          </div>

          {withButton && <Button opened={opened} toggle={this.toggle} />}
        </div>
      </WithChangeOrientation>
    );
  }

  public updateContentHeight = () => {
    const contentEl = this.contentEl.current;

    /* istanbul ignore next */
    if (!contentEl) {
      return;
    }

    const { height } = contentEl.getBoundingClientRect();

    this.setState({
      contentHeight: height,
      isInited: true,
    });
  };

  private isExpanderVisible() {
    return this.state.contentHeight > (this.props.collapsedHeight as number);
  }

  private toggle = () => this.setState(state => ({ opened: !state.opened }));
}
