import { getLocationPath } from '../../selectors/location';
import { getTitle } from '../../selectors/seoData';
import { TThunkAction } from '../../types/redux';

export function updateBrowserLocation(): TThunkAction {
  return (dispatch, getState) => {
    if (typeof window === 'undefined') {
      return;
    }

    const { pathname, search } = window.location;
    const currentPath = pathname + search;

    const state = getState();
    const nextPath = getLocationPath(state);
    const title = getTitle(state);

    if (currentPath !== nextPath) {
      window.history.pushState({}, title, nextPath);
    } else {
      window.history.replaceState({}, title);
    }
  };
}
