import { IConfig } from '@cian/config/shared';
import { ca } from '@cian/analytics';

import { createCianAnalyticsPageViewObject } from '../utils/cianAnalyticsPageViewObject';
import { IApplicationState } from '../types/redux';
import { jsonQueryHelper } from '../utils/jsonQuery/createJsonQuery';

// Не стоит сюда добавлять аргументы, лучше обновить и передать актуальное состояние, для консистентности
export function trackPageView(state: IApplicationState, config: IConfig) {
  ca(
    'pageviewSite',
    createCianAnalyticsPageViewObject({
      state,
      jsonQuery: jsonQueryHelper.dump(),
      abUseExperiments: config.get('newbuilding-search-mobile-frontend.abUseExperiments') || '',
    }),
  );
}
