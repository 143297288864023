import * as React from 'react';
import { CollapsedBlock } from '../CollapsedBlock';
import * as styles from './styles.css';
import { Section } from '../Section';

interface ISeoBlockProps {
  text: string;
  handleReadAllSeo(): void;
}

function createMarkup(text: string) {
  return { __html: text };
}

export class SeoBlock extends React.Component<ISeoBlockProps> {
  public onChangeOpen = (isOpen: boolean) => {
    if (isOpen) {
      this.props.handleReadAllSeo();
    }
  };

  public render() {
    return (
      <Section className={styles['seo-block']}>
        <CollapsedBlock withShadow={false} collapsedHeight={70} onChangeOpen={this.onChangeOpen}>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles['content']} dangerouslySetInnerHTML={createMarkup(this.props.text)} />
        </CollapsedBlock>
      </Section>
    );
  }
}
