import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useScript } from '../../utils/useScript';
import { mortgageSerpBannerSetContent } from '../../actions/mortgageSerpBanner';
import { MortgageSerpBannerComponent } from '../../components/MortgageSerpBanner';
import { getMortgageBannerContent, getMortgageScriptUrl } from '../../selectors/mortgageSerpBanner';
import { TThunkDispatch } from '../../types/redux';

interface IMortgageSerpBannerContainerProps {
  position: number;
}

export function MortgageSerpBannerContainer({ position }: IMortgageSerpBannerContainerProps) {
  const scriptUrl = useSelector(getMortgageScriptUrl);
  const content = useSelector(getMortgageBannerContent(position));
  const dispatch: TThunkDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(mortgageSerpBannerSetContent(position));
  }, [dispatch, position]);

  useScript(scriptUrl);

  if (content && scriptUrl) {
    return <MortgageSerpBannerComponent content={content} />;
  } else {
    return null;
  }
}
