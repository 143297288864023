import { preparePagination } from '../../mappers';
import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { EPaginationStatus, IPagination } from '../../types/pagination';
import { TReduxActions } from '../../types/redux';

const defaultState: IPagination = {
  status: EPaginationStatus.Initial,
  itemsPerPage: 28,
  page: 1,
  totalCount: 0,
};

export function paginationReducer(state: IPagination = defaultState, action: TReduxActions): IPagination {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
    case EPaginationActionTypes.Success: {
      const pagination = preparePagination(action.payload);

      return {
        ...pagination,
        status: EPaginationStatus.Success,
      };
    }

    case EPaginationActionTypes.Request:
      return {
        ...state,
        status: EPaginationStatus.Loading,
      };

    case EPaginationActionTypes.Failure:
      return {
        ...state,
        status: EPaginationStatus.Failure,
      };

    default:
      return state;
  }
}
