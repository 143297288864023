import { ca } from '@cian/analytics';

export function trackShowPhone(gaLabel: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing_JK',
    action: 'open_contacts',
    label: gaLabel,
  });
}
