import * as React from 'react';

import * as styles from './PageLoaderLayout.css';

interface IPageLoaderLayoutProps {
  children: React.ReactNode;
}

export const PageLoaderLayout = ({ children }: IPageLoaderLayoutProps) => {
  return <div className={styles['container']}>{children}</div>;
};
