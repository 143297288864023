import { TThunkAction, TThunkDispatch } from '../../types/redux';
import { actionGenerator } from '../../utils/actionGenerator';
import { chatUrlSelector } from '../../selectors/consultantCianChat';
import { getConsultantChat } from '../../services/fetchConsultantChat';
import { getLocationPath } from '../../selectors/location';
import { trackOpenChatPopup } from '../../containers/ConsultantCianChat/tracking';
import { userSelector } from '../../selectors/user';

export enum EConsultantCianChatActionTypes {
  OpenAuthorizationMotivationPopup = 'consultantCianChat/authorizationMotivationPopupOpen',
  OpenChat = 'consultantCianChat/open',
  CloseChat = 'consultantCianChat/close',
  SetChatId = 'consultantCianChat/setChatId',
  SetChatLoading = 'consultantCianChat/setChatLoading',
  OpenErrorMessage = 'consultantCianChat/OpenErrorMessage',
  CloseErrorMessage = 'consultantCianChat/CloseErrorMessage',
}

export const openConsultantCianChat = actionGenerator<EConsultantCianChatActionTypes.OpenChat, void>(
  EConsultantCianChatActionTypes.OpenChat,
);

export const setCianChatId = actionGenerator<EConsultantCianChatActionTypes.SetChatId, string>(
  EConsultantCianChatActionTypes.SetChatId,
);

export const setChatLoading = actionGenerator<EConsultantCianChatActionTypes.SetChatLoading, void>(
  EConsultantCianChatActionTypes.SetChatLoading,
);

export const openErrorMessage = actionGenerator<EConsultantCianChatActionTypes.OpenErrorMessage, void>(
  EConsultantCianChatActionTypes.OpenErrorMessage,
);

export const closeErrorMessage = actionGenerator<EConsultantCianChatActionTypes.CloseErrorMessage, void>(
  EConsultantCianChatActionTypes.CloseErrorMessage,
);

export const onCianChatOpen = (): TThunkAction => async (dispatch: TThunkDispatch, getState, context) => {
  try {
    dispatch(setChatLoading());

    const state = getState();
    const path = getLocationPath(state);
    const chatUrl = chatUrlSelector(state);
    const user = userSelector(state);

    if (chatUrl) {
      return;
    }

    const chat = await getConsultantChat(context, { path });
    /* istanbul ignore else */
    if (chat) {
      dispatch(setCianChatId(chat.chatId));
      dispatch(openConsultantCianChat());
      trackOpenChatPopup({
        chatId: chat.chatId,
        botId: 3,
        userId: user.isAuthenticated ? user.cianId : undefined,
      });
    }
  } catch (error) {
    dispatch(openErrorMessage());
    //hide chat
  }
};

export const closeConsultantCianChat = actionGenerator<EConsultantCianChatActionTypes.CloseChat, void>(
  EConsultantCianChatActionTypes.CloseChat,
);

/* istanbul ignore next */
export const onConsultantCianSuccessAuth = () => (): TThunkAction => (dispatch: TThunkDispatch) => {
  dispatch(onCianChatOpen());
};

/* istanbul ignore next */
export const onConsultantCianCloseNoAuth = () => (): TThunkAction => (dispatch: TThunkDispatch) => {
  dispatch(closeConsultantCianChat());
};

export const openAutorisationMotivationConsultantCianChat = actionGenerator<
  EConsultantCianChatActionTypes.OpenAuthorizationMotivationPopup,
  void
>(EConsultantCianChatActionTypes.OpenAuthorizationMotivationPopup);

export type TConsultantCianChatActions =
  | ReturnType<typeof openConsultantCianChat>
  | ReturnType<typeof closeConsultantCianChat>
  | ReturnType<typeof openAutorisationMotivationConsultantCianChat>
  | ReturnType<typeof setCianChatId>
  | ReturnType<typeof openErrorMessage>
  | ReturnType<typeof setChatLoading>
  | ReturnType<typeof closeErrorMessage>;
