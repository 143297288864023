import * as React from 'react';

import * as styles from './styles.css';

export interface IAdfoxCarouselWrapperProps {
  children: React.ReactNode;
}

export const AdfoxCarouselWrapper: React.FC<IAdfoxCarouselWrapperProps> = props => {
  const { children } = props;

  return (
    <div className={styles['container']} data-testid="adfox_carousel">
      {children}
    </div>
  );
};
