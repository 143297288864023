import { IConsultantChatState } from '../../types/consultantChat';

const initialState: IConsultantChatState = {
  accountName: '',
  enabled: false,
};

export function consultantChatReducer(state: IConsultantChatState = initialState) {
  return state;
}
