interface IMap {
  [key: string]: string | number;
}

export function reverseObject<K extends string | number, V extends string | number>(
  object: { [P in K]: V },
  mapValue?: (key: string) => K,
): { [P in V]: K };

export function reverseObject(object: IMap, mapValue?: (value: string) => string | number): IMap {
  const reversedObject: IMap = {};

  for (const key of Object.keys(object)) {
    const value = object[key];
    reversedObject[value] = mapValue ? mapValue(key) : key;
  }

  return reversedObject;
}
