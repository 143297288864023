import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export function trackShowQuickLinks() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: `view_alsoLooking_0`,
    label: '',
    sc: jsonQueryHelper.dump(),
  });
}
