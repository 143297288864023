import * as React from 'react';
import { Button, UIHeading2 } from '@cian/ui-kit';
import * as styles from './NewbuildingConsultBanner.css';

export interface INewbuildingConsultBannerProps {
  onClick?(): void;
  url: string;
}

export const NewbuildingConsultBanner: React.FC<INewbuildingConsultBannerProps> = ({ onClick, url }) => {
  return (
    <a
      className={styles['container']}
      data-testid="NewbuildingConsultBanner"
      href={url}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className={styles['title']}>
        <UIHeading2 as="h2">Подберём квартиру в&nbsp;новостройке</UIHeading2>
      </div>
      <div className={styles['button']}>
        <Button theme="fill_primary" type="button" fullWidth={true} size="M">
          Подобрать бесплатно
        </Button>
      </div>
    </a>
  );
};
