import {
  ISearchNewbuildingsMobileSiteResponse200,
  TSearchNewbuildingsMobileSiteResponse,
} from '../../../repositories/search-engine/v1/search-newbuildings-mobile-site';
import { ResponseError } from '../../../errors';

export function assertUnknownError(
  response: TSearchNewbuildingsMobileSiteResponse,
): asserts response is ISearchNewbuildingsMobileSiteResponse200 {
  if (response.statusCode === 200) {
    return;
  }

  const { message, errors } = response.response;

  throw new ResponseError({
    domain: 'services.newbuildings.fetchSearchNewbuildings',
    message: message as string,
    details: { errors: JSON.stringify(errors) },
  });
}
