import * as React from 'react';

import * as styles from './styles.css';

export interface IBreadcrumb {
  title: string;
  url: string;
}

export interface IBreadcrumbsProps {
  breadcrumbs: IBreadcrumb[];
  handleClickBreadcrumbs(): void;
}

function getBreadcrumb(title: string, url: string, handleClickBreadcrumbs: () => void) {
  return (
    <li key={url}>
      <a href={url} onClick={handleClickBreadcrumbs}>
        <span>{title}</span>
      </a>
    </li>
  );
}

export function Breadcrumbs({ breadcrumbs, handleClickBreadcrumbs }: IBreadcrumbsProps) {
  return (
    <div className={styles['container']}>
      <ul>
        {breadcrumbs.map(breadcrumb => {
          return getBreadcrumb(breadcrumb.title, breadcrumb.url, handleClickBreadcrumbs);
        })}
      </ul>
    </div>
  );
}
