import * as React from 'react';
import { Button } from '@cian/ui-kit';
import * as styles from './styles.css';

interface IShowMoreProps {
  loading: boolean;

  paginateOffers(): void;
}

export const ShowMore = (props: IShowMoreProps) => {
  const { loading, paginateOffers } = props;

  // Обертка в div сделана вынуждено, т.к. в кнопку нельзя указать параметры для flex
  return (
    <div className={styles['container']}>
      <Button theme="fill_primary" loading={loading} disabled={loading} onClick={paginateOffers} fullWidth>
        Показать ещё
      </Button>
    </div>
  );
};
