import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';

export const buildingStatusSelector =
  (id: number) =>
  (state: IApplicationState): string => {
    const { buildingStatus } = selectRCById(state, id);

    return buildingStatus;
  };

export const offerTypesSelector =
  (id: number) =>
  (state: IApplicationState): string | undefined => {
    const rc = selectRCById(state, id);

    return rc.offerTypes;
  };

export const isProblemSelector =
  (id: number) =>
  (state: IApplicationState): boolean => {
    const { isProblem } = selectRCById(state, id);

    return isProblem;
  };
