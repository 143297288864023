import { applyMiddleware, compose, createStore, CombinedState } from 'redux';

import { IApplicationState, IApplicationStateInitial, TReduxActions, IReduxStore } from '../types/redux';
import { reducers } from '../reducers';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { IApplicationContext } from '../types/application';

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 */

interface IThunkExt {
  dispatch: ThunkDispatch<IApplicationState, IApplicationContext, TReduxActions>;
}

/* istanbul ignore next */
const composeEnhancers = (() => {
  if (process.env.NODE_ENV === 'development' && process.env.IS_BROWSER_BUILD) {
    const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;

    if (__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: `newbuilding-search-mobile-frontend: ${document.title}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
  }

  return compose;
})();

export function createReduxStore(initialState: IApplicationStateInitial, context: IApplicationContext): IReduxStore {
  const storeEnhancers = composeEnhancers(applyMiddleware(thunk.withExtraArgument(context)));

  return createStore<CombinedState<IApplicationState>, TReduxActions, IThunkExt, {}>(
    reducers,
    initialState,
    storeEnhancers,
  );
}
