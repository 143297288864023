import * as React from 'react';
import { Button, InformationTooltip } from '@cian/ui-kit';

import * as styles from './ShowPhoneTooltip.css';

interface IShowPhoneTooltip {
  children?: React.ReactNode;
  onOpen?(): void;
}

export function ShowPhoneTooltip({ children, onOpen }: IShowPhoneTooltip) {
  return (
    <InformationTooltip
      onOpen={onOpen}
      content={<div className={styles['container']}>{children}</div>}
      placement="left-end"
      arrow
    >
      <Button size="M" theme="fill_primary" fullWidth data-testid="CallButton">
        Контакты застройщика
      </Button>
    </InformationTooltip>
  );
}
