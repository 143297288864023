import * as React from 'react';
import { PhoneContainer } from '../PhoneContainer';
import { ActionsLayout } from '../../components/ActionsLayout';
import { BuilderChatButtonContainer } from '../BuilderChatButtonContainer';
import { getBuilderChat } from '../../selectors/rcs';
import { OnlineStatus } from '../../components/OnlineStatus';
import { useSelector } from 'react-redux';

interface IActionsContainerProps {
  id: number;
}

export const ActionsContainer: React.FC<IActionsContainerProps> = ({ id }) => {
  const builderChat = useSelector(getBuilderChat(id));
  const onlineStatusText = builderChat?.isOnline
    ? 'Свяжитесь с застройщиком, пока он в сети'
    : 'Свяжитесь с застройщиком';

  if (builderChat?.isEnabled) {
    return (
      <ActionsLayout
        phoneButton={<PhoneContainer id={id} buttonText="Позвонить" />}
        messageButton={<BuilderChatButtonContainer id={id} defaultMessage={builderChat.defaultMessage} />}
        onlineStatus={<OnlineStatus isOnline={builderChat.isOnline} text={onlineStatusText} />}
      />
    );
  }

  return <PhoneContainer id={id} />;
};
