export interface ISalesDescription {
  salesType: ESalesType;
  text: string;
}

export enum ESalesType {
  SalesLeader = 'salesLeader',
  SalesStart = 'salesStart',
  UpcomingSale = 'upcomingSale',
}

export type TSalesDescriptionState = ISalesDescription | null;
