import React, { FC } from 'react';

import styles from './PikSlideWrapper.css';

type PikSlideWrapperProps = {
  backgroundImageUrl: string;
  children: JSX.Element;
};

export const PikSlideWrapper: FC<PikSlideWrapperProps> = ({ children, backgroundImageUrl }) => {
  return (
    <div style={{ backgroundImage: `url(${backgroundImageUrl})` }} className={styles['container']}>
      <div className={styles['content']}>{children}</div>
    </div>
  );
};
