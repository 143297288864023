import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/actionGenerator';
import { EErrorNotificationType } from '../../types/notifications';

export enum EErrorNotificationActionTypes {
  Push = 'ERROR_NOTIFICATIONS/PUSH',
  Remove = 'ERROR_NOTIFICATIONS/REMOVE',
  Clear = 'ERROR_NOTIFICATIONS/CLEAR',
}

export type TPushErrorNotification = ITypedReduxAction<EErrorNotificationActionTypes.Push, EErrorNotificationType>;

export const pushErrorNotification: (type: EErrorNotificationType) => TPushErrorNotification = actionGenerator<
  EErrorNotificationActionTypes.Push,
  EErrorNotificationType
>(EErrorNotificationActionTypes.Push);

export type TRemoveErrorNotification = ITypedReduxAction<EErrorNotificationActionTypes.Remove, EErrorNotificationType>;

export const removeErrorNotification: (type: EErrorNotificationType) => TRemoveErrorNotification = actionGenerator<
  EErrorNotificationActionTypes.Remove,
  EErrorNotificationType
>(EErrorNotificationActionTypes.Remove);

export type TClearErrorNotifications = ITypedReduxAction<EErrorNotificationActionTypes.Clear, void>;

export const clearErrorNotification: () => TClearErrorNotifications =
  actionGenerator<EErrorNotificationActionTypes.Clear>(EErrorNotificationActionTypes.Clear);

export type TErrorNotificationActions = TPushErrorNotification | TRemoveErrorNotification | TClearErrorNotifications;
