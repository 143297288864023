import * as React from 'react';
import { useSaveSearchContext } from '../../../packages/SaveSearchModal';
import { PreInfiniteBanner } from '../../components/PreInfiniteBanner';
import { EModalOpener } from '../../types/savedSearch';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';
import { trackSavedSearchPopupOpen } from '../SavedSearchButtonContainer/tracking';
import { trackPreInfiniteSaveSearchShow } from './tracking';

export function PreInfiniteBannerContainer() {
  const { onOpen, setParams } = useSaveSearchContext();

  const handleSaveSearchOpen = React.useCallback(() => {
    const saveSearchParams = { jsonQuery: jsonQueryHelper.dump() };

    trackSavedSearchPopupOpen(EModalOpener.PreInfiniteBanner);
    setParams(saveSearchParams);
    onOpen();
  }, [onOpen, setParams]);

  return <PreInfiniteBanner onClick={handleSaveSearchOpen} onShow={trackPreInfiniteSaveSearchShow} />;
}
