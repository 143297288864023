import { IConfig } from '@cian/config/shared';
import { ESpecialPromoType, ISpecialPromoConfig } from '../../types/specialPromo';

const SPECIAL_PROMO_CONFIG_KEY = 'builders.specialPromo';

export const getSpecialPromoInfo = (config: IConfig, type: ESpecialPromoType) => {
  const data = config.get<ISpecialPromoConfig>(SPECIAL_PROMO_CONFIG_KEY);

  return (data && data[type]) || null;
};
