export function defferedTrack(cb: () => void, defferedTime: number = 10000) {
  let timerId: number;

  const listener = () => {
    clearTimeout(timerId);
    window.removeEventListener('focus', listener);
  };

  window.addEventListener('focus', listener);

  timerId = window.setTimeout(() => {
    window.removeEventListener('focus', listener);

    cb();
  }, defferedTime);
}
