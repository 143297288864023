import * as React from 'react';
import {
  IconToggleHeartOn16,
  IconToggleHeartOff16,
  IconToggleHeartOn24,
  IconToggleHeartOff24,
} from '@cian/ui-kit-design-tokens/icons';
import * as style from './style.css';

import { Button, IconButton, Responsive } from '@cian/ui-kit';

export interface IFavoritesProps {
  isActive: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const Favorites: React.FunctionComponent<IFavoritesProps> = ({ isActive, onClick }) => {
  const Icon = isActive ? IconToggleHeartOn16 : IconToggleHeartOff16;
  const IconPhone = isActive ? IconToggleHeartOn24 : IconToggleHeartOff24;
  const text = isActive ? 'В избранном' : 'В избранное';
  const dataTestId = isActive ? 'RemoveFavoriteButton' : 'AddFavoriteButton';

  return (
    <>
      <Responsive tablet>
        <Button
          theme="fill_secondary"
          data-testid={dataTestId}
          onClick={onClick}
          beforeIcon={<Icon color="error_100" />}
          size="M"
        >
          {text}
        </Button>
      </Responsive>
      <Responsive phone>
        <div className={style['icon-button--phone']}>
          <IconButton onClick={onClick} icon={<IconPhone />} data-testid={dataTestId} />
        </div>
      </Responsive>
    </>
  );
};
