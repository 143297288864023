import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Favorites } from '../../components/Favorites';
import { addFavorite, deleteFavorite } from '../../actions/favorites';
import { getIsFavorite } from '../../selectors/rcs';
import { TThunkDispatch } from '../../types/redux';

interface IFavoritesContainerProps {
  id: number;
}

export const FavoritesContainer = ({ id }: IFavoritesContainerProps) => {
  const isFavorite = useSelector(getIsFavorite(id));
  const dispatch: TThunkDispatch = useDispatch();

  const handleButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const favoriteAction = isFavorite ? deleteFavorite : addFavorite;
      dispatch(favoriteAction({ rcId: id }));
    },
    [dispatch, id, isFavorite],
  );

  return <Favorites onClick={handleButtonClick} isActive={isFavorite} />;
};
