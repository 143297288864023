export interface IGeo {
  regionId: number;
  address: string;
  undergrounds: IUnderground[];
}

export interface IUnderground {
  id: number;
  name: string;
  underConstruction: boolean;
  lineColor?: string | null;
  time?: string | null;
  transportType?: ETransportType | null;
}

// TODO удалить все вхождения highways или проверить их актуальность
export interface IHighways {
  id: number;
  name: string;
  distance?: string | null;
}

export enum ETransportType {
  Transport = 'transport',
  Walk = 'walk',
}
