import * as React from 'react';

import { Section } from '../Section';

import * as styles from './styles.css';

export interface IChangeConditionsSearchProps {
  filtersUrl: string;
}

export function ChangeConditionsSearch({ filtersUrl }: IChangeConditionsSearchProps) {
  return (
    <Section>
      <a href={filtersUrl} className={styles['container']}>
        Изменить условия поиска
      </a>
    </Section>
  );
}
