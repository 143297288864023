import { combineReducers } from 'redux';

import { IApplicationState, TReduxActions } from '../types/redux';
import { experimentsReducer } from './experiments';
import { newbuildingPromoCarouselReducer } from './newbuildingPromoCarousel';
import { rcsReducer } from './rcs';
import { seoDataReducer } from './seoData';
import { breadcrumbsReducer } from './breadcrumbs';
import { fitersUrlReducer } from './filtersUrl';
import { locationReducer } from './location';
import { mapUrlReducer } from './mapUrl';
import { consultantChatReducer } from './consultantChat';
import { paginationReducer } from './pagination';
import { notificationsReducer } from './notifications';
import { sortingReducer } from './sorting';
import { analyticsReducer } from './analytics';
import { userReducer } from './user';
import { deviceTypeReducer } from './deviceType';
import { salesDescriptionReducer } from './salesDescription';
import { specialPromoReducer } from './specialPromo';
import { mortgageSerpBannerReducer } from './mortgageSerpBanner';
import { upcomingSaleRequestReducer } from './upcomingSaleRequest';
import { pageStatusReducer } from './pageStatus';
import { puidsByFilterReducer } from './puidsByFilter';
import { platformReducer } from './platform';
import { pikDisclaimerReducer } from './pikDisclaimer';
import { noopReducer } from './noopReducer';
import { discountMortgagePopupReducer } from './discountMortgagePopup';
import { isDiscountMortgageReducer } from './isDiscountMortgage';
import { builderChatReducer } from './builderChat';
import { consultantCianChatReducer } from './consultantCianChat';

export const reducers = combineReducers<IApplicationState, TReduxActions>({
  analytics: analyticsReducer,
  breadcrumbs: breadcrumbsReducer,
  deviceType: deviceTypeReducer,
  filtersUrl: fitersUrlReducer,
  newbuildingPromoCarousel: newbuildingPromoCarouselReducer,
  location: locationReducer,
  mapUrl: mapUrlReducer,
  mortgageSerpBanner: mortgageSerpBannerReducer,
  consultantChat: consultantChatReducer,
  builderChat: builderChatReducer,
  notifications: notificationsReducer,
  pageStatus: pageStatusReducer,
  pagination: paginationReducer,
  quickLinks: noopReducer(null),
  rcs: rcsReducer,
  salesDescription: salesDescriptionReducer,
  seoData: seoDataReducer,
  sorting: sortingReducer,
  specialPromo: specialPromoReducer,
  pikDisclaimer: pikDisclaimerReducer,
  upcomingSaleRequest: upcomingSaleRequestReducer,
  user: userReducer,
  puidsByFilter: puidsByFilterReducer,
  experiments: experimentsReducer,
  platform: platformReducer,
  seoCrossLinks: noopReducer(null),
  discountMortgagePopup: discountMortgagePopupReducer,
  isDiscountMortgage: isDiscountMortgageReducer,
  consultantCianChat: consultantCianChatReducer,
});
