import * as React from 'react';

import { QuickLink } from './QuickLink';
import { IQuickLink } from '../../types/quickLinks';

import * as styles from './QuickLinks.css';
import { UIText2 } from '@cian/ui-kit';
import { clsx } from 'clsx';

export interface IQuickLinksProps {
  quickLinks: IQuickLink[];
  handleClickQuickLink(url: string): void;
  sortingButton?: React.ReactNode;
  subtitle?: string;
}

export const QuickLinks: React.FC<IQuickLinksProps> = React.memo(
  ({ quickLinks, handleClickQuickLink, sortingButton, subtitle }) => {
    return (
      <section className={clsx(styles['container'], subtitle && styles['container--subtitle'])}>
        {subtitle && <UIText2>{subtitle}</UIText2>}
        {sortingButton}
        {quickLinks.map(quickLink => (
          <QuickLink
            {...quickLink}
            key={quickLink.title + quickLink.counter}
            onClick={() => handleClickQuickLink(quickLink.url)}
          />
        ))}
      </section>
    );
  },
);

QuickLinks.displayName = 'QuickLinks';
