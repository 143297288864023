import { IResponseDataSchema } from '../repositories/search-engine/entities/response/ResponseDataSchema';
import { ISorting, ISortingOption } from '../types/sorting';
import { mapJsonQueryToSorting } from '../utils/jsonQuery/sortingMappers';

export const prepareSorting = (data: IResponseDataSchema): ISorting => {
  // @ts-expect-error jsonQuery - нетипизированный объект
  const jsonQuerySortingType = data?.jsonQuery?.sort?.value || null;
  const sortingType = mapJsonQueryToSorting(jsonQuerySortingType);

  return {
    current: { type: sortingType },
    options: data.sortVariants as { key: string; value: string }[] as ISortingOption[],
  };
};
