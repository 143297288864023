import React, { FC } from 'react';

import { PikSlideWrapper } from '../../PikSlideWrapper';

import styles from './Slide1.css';

export const Slide1: FC = () => (
  <PikSlideWrapper
    backgroundImageUrl="https://static.cdn-cian.ru/frontend/newbuilding-b2b-assets/images/pik/promo_slider_mobile/slide-1.png"
    key="slide-1"
  >
    <div className={styles['container']}>
      <img
        alt="ПИК"
        className={styles['logo']}
        src="https://static.cdn-cian.ru/frontend/newbuilding-b2b-assets/icons/pik/wide/logo-white-80x24.svg"
        width={80}
        height={24}
      />
      <span>ПИК &mdash; крупнейшая в России технологическая компания в&nbsp;области жилой недвижимости</span>
    </div>
  </PikSlideWrapper>
);
