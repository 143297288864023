import { TReduxActions } from '../../types/redux';
import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPageStatus, TPageStatusState } from '../../types/pageStatus';

const defaultState: TPageStatusState = EPageStatus.Initial;

export function pageStatusReducer(state: TPageStatusState = defaultState, action: TReduxActions): TPageStatusState {
  switch (action.type) {
    case ENewbuildingsActionTypes.Failure:
      return EPageStatus.Failure;

    case ENewbuildingsActionTypes.Request:
      return EPageStatus.Loading;

    case ENewbuildingsActionTypes.Success:
      return EPageStatus.Success;

    default:
      return state;
  }
}
