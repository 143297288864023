import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

interface IOptions {
  url: string;
}

export function trackClickQuickLink({ url }: IOptions) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: `view_alsoLooking_0`,
    label: url,
    sc: jsonQueryHelper.dump(),
  });
}
