import * as React from 'react';
import { IconProduct3DTour16, IconProductDateTime16 } from '@cian/ui-kit-design-tokens/icons';
import { EFeatureType } from '../../types/rc/feature';
import * as styles from './NewbuildingFeatures.css';

interface INewbuildingFeatureProps {
  features: EFeatureType[];
}

const ICONS_MAP = {
  [EFeatureType.Has3dTour]: IconProduct3DTour16,
  [EFeatureType.HasFlatTourBooking]: IconProductDateTime16,
};

export function NewbuildingFeatures({ features }: INewbuildingFeatureProps) {
  return (
    <div className={styles['features']}>
      {features.map((type, idx) => {
        const Icon = ICONS_MAP[type];

        return (
          <div key={idx} className={styles['feature']}>
            <div className={styles['feature-icon-wrapper']}>
              <Icon color="icon-inverted-default" />
            </div>
          </div>
        );
      })}
    </div>
  );
}
