import { ca } from '@cian/analytics';

export const trackNewbuildingPromoCarouselShow = (newbuildingIds: number[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'new_building_popular_JK_show_sopr',
    label: 'querystring',
    products: newbuildingIds.map(newbuildingId => ({ id: newbuildingId })),
  });
};
