import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export function trackPhonesCall(label?: string) {
  ca('eventSite', {
    action: 'Call',
    name: 'oldevent',
    category: 'Phones',
    label,
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}
