import { ca } from '@cian/analytics';

import { getNewbuildingsClickInfo } from '../../../utils/newbuildingPromoCarousel';
import type { INewbuildingPromo } from '@cian/frontend-newbuilding-promo-carousel-component';

export interface ITrackNewbuildingPromoCarouselCardClickParams {
  position: number;
  newbuilding: INewbuildingPromo;
}

export const trackNewbuildingPromoCarouselCardClick = ({
  newbuilding,
  position,
}: ITrackNewbuildingPromoCarouselCardClickParams) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'new_building_popular_JK_click',
    label: 'querystring',
    products: getNewbuildingsClickInfo({ position, newbuilding }),
  });
};
