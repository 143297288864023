import { IPagination } from '../types/pagination';
import { IResponseDataSchema } from '../repositories/search-engine/entities/response/ResponseDataSchema';

export const preparePagination = (data: IResponseDataSchema): IPagination => {
  // @ts-expect-error jsonQuery - нетипизированный объект
  const page = data?.jsonQuery?.page?.value || 1;
  const totalCount = data?.aggregatedOffersCount || 0;
  const itemsPerPage = 28;

  return { page, totalCount, itemsPerPage } as IPagination;
};
