import { IBuilderChat } from '../../types/builderChat';
import { IApplicationState } from '../../types/redux';
import { selectRCById } from './rcs';

export const getBuilderChat =
  (id: number) =>
  (state: IApplicationState): IBuilderChat | undefined => {
    const rc = selectRCById(state, id);

    if (rc.author.chat) {
      return rc.author.chat;
    }

    return;
  };
