import * as React from 'react';

import * as styles from './QuickLink.css';
import { UIText2 } from '@cian/ui-kit';

export interface IQuickLinkProps {
  url: string;
  title: string;
  counter: number;
  onClick(): void;
}

export const QuickLink: React.FC<IQuickLinkProps> = ({ url, title, counter, onClick }) => {
  return (
    <a href={url} className={styles['link']} onClick={onClick} target="_blank" rel="noreferrer">
      <UIText2 color="text-main-default" display="inline" whiteSpace="nowrap">
        {title}
      </UIText2>
      <UIText2 color="current_color" display="inline">
        {counter}
      </UIText2>
    </a>
  );
};
