import { ca } from '@cian/analytics';

import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';

export const trackChatEntryClick = () =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry',
    action: 'Click_sopr',
    label: 'Click',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });

export const trackOpenChatPopup = ({
  chatId,
  botId,
  userId,
}: {
  chatId: string;
  botId: number;
  userId?: number | null;
}) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Message_popup',
    action: 'open',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
    page: {
      pageType: 'ListingJK',
      siteType: 'mobile',
      extra: {
        chat_id: chatId,
        bot_id: botId,
        owner_id: userId,
      },
    },
  });

export const trackChatPopupClose = () =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry',
    action: 'Click_sopr',
    label: 'EndChat',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });

export const trackNotAuthorizedUserPopupShow = (label: string) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry_popup',
    action: 'Show_sopr',
    label,
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });

export const trackAuthorizeClick = (label: string) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry_popup',
    action: 'Click_sopr',
    label,
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
