import {
  IconProductHotLeads16,
  IconProductSalesStart16,
  IconFinanceSale16,
  ISVGIconProps,
} from '@cian/ui-kit-design-tokens/icons';

import { ESalesType } from '../../types/salesDescription';

export const SALES_TYPE_ICONS = new Map<ESalesType, React.ComponentType<ISVGIconProps>>([
  [ESalesType.SalesLeader, IconProductHotLeads16],
  [ESalesType.SalesStart, IconProductSalesStart16],
  [ESalesType.UpcomingSale, IconFinanceSale16],
]);

export function getIconByType(type: ESalesType | null | undefined) {
  return (type && SALES_TYPE_ICONS.get(type)) || null;
}
