import { loadableReady } from '@loadable/component';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { subscribeBrowserHistory } from '../browserHistory';
import { initJsonQuery } from '../initJsonQuery';
import { createContext } from './createContext';
import { createApplicationState } from '../applicationState';
import { Application } from '../../../shared/containers/Application';

import './criticalResources';

export async function runApp() {
  const context = createContext();
  const { config } = context;

  const projectName = config.getStrict<string>('projectName');

  initJsonQuery();

  const store = createApplicationState(context);
  subscribeBrowserHistory(store);

  return loadableReady(() => {
    const rootElement = document.getElementById(projectName);

    if (!rootElement) {
      throw new Error(`Unable to find element #${projectName}`);
    }

    ReactDOM.hydrateRoot(rootElement, <Application context={context} reduxStore={store} />);
  });
}
