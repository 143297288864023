import { ISorting, ESortingType } from '../../types/sorting';
import { TReduxActions } from '../../types/redux';
import { ESortingActionTypes } from '../../actions/sorting';
import { prepareSorting } from '../../mappers';
import { ENewbuildingsActionTypes } from '../../actions/newbuildings';

const defaultState: ISorting = {
  current: { type: ESortingType.Default },
  options: [],
};

export function sortingReducer(state: ISorting = defaultState, action: TReduxActions): ISorting {
  switch (action.type) {
    case ENewbuildingsActionTypes.Success:
      return prepareSorting(action.payload);

    case ESortingActionTypes.SetCurrent:
      return {
        ...state,
        current: { type: action.payload },
      };

    default:
      return state;
  }
}
