export enum EModalOpener {
  SavedSearchButton = 'savedSearchButton',
  PreInfiniteBanner = 'preInfiniteBanner',
}

export enum EFrequency {
  /** Получать мнгновенно **/
  Asap = 'asap',
  /** Получать каждый день **/
  Daily = 'daily',
  /** Получать каждый час **/
  Hourly = 'hourly',
  /** Получать еженедельно **/
  Weekly = 'weekly',
}
