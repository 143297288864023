import * as React from 'react';
import { Spinner } from '@cian/ui-kit';

interface ILoaderProps {
  size?: number;
}

export const Loader = ({ size = 32 }: ILoaderProps) => {
  return <Spinner size={size} color="icon-main-default" />;
};
