import * as React from 'react';

import * as styles from './styles.css';

interface IActionsLayoutProps {
  phoneButton: React.ReactNode;
  messageButton: React.ReactNode;
  onlineStatus: React.ReactNode;
}

export const ActionsLayout: React.FC<IActionsLayoutProps> = ({ phoneButton, messageButton, onlineStatus }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['buttons']}>
        {phoneButton}
        {messageButton}
      </div>
      {onlineStatus}
    </div>
  );
};
