import * as React from 'react';
import { InlineText2 } from '@cian/ui-kit';

import { IconProductSalesStart12 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from './SalesStartLabel.css';

interface ISalesStartLabelProps {
  text: string;
}

export function SalesStartLabel({ text }: ISalesStartLabelProps) {
  return (
    <InlineText2 color="overlay-default" whiteSpace="normal">
      {text}
      <span className={styles['icon']}>
        &nbsp;
        <IconProductSalesStart12 color="icon-negative-default" />
      </span>
    </InlineText2>
  );
}
