import { EGetUserStatus } from './types';

import { prepareUser } from '../../mappers/prepareUser';
import { TThunkAction } from '../../types/redux';
import { TUser } from '../../types/user';
import { actionGenerator } from '../../utils/actionGenerator';
import {
  fetchCianApiSiteV1GetUserDataByRequest,
  TCianApiSiteV1GetUserDataByRequestResponse,
} from '../../repositories/monolith-python/cian-api/site/v1/get-user-data-by-request';
import { ResponseError } from '../../errors';

const setStatusLoading = actionGenerator<EGetUserStatus.Loading>(EGetUserStatus.Loading);
const setStatusSucceed = actionGenerator<EGetUserStatus.Succeed, TUser>(EGetUserStatus.Succeed);
const setStatusFailed = actionGenerator<EGetUserStatus.Failed>(EGetUserStatus.Failed);

export function getUser(): TThunkAction<Promise<TUser | undefined>> {
  return async (dispatch, getState, context) => {
    dispatch(setStatusLoading());

    const {
      httpApi,
      logger,
      custom: { subdomain },
    } = context;

    try {
      const getUserDataByRequestPromise: Promise<TCianApiSiteV1GetUserDataByRequestResponse> =
        fetchCianApiSiteV1GetUserDataByRequest({
          httpApi,
          config: {
            subdomain,
          },
        });

      const { response, statusCode } = await getUserDataByRequestPromise;

      if (statusCode !== 200) {
        throw new ResponseError({
          domain: 'user#getUser',
          message: response.message as string,
          details: {
            error: JSON.stringify(response.errors),
          },
        });
      }

      const {
        data: { user: userRaw },
      } = response;

      const user: TUser = prepareUser({ user: userRaw }, 100);

      dispatch(setStatusSucceed(user));

      return user;
    } catch (error) {
      logger.error(error);

      dispatch(setStatusFailed());

      return undefined;
    }
  };
}
