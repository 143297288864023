import { EAuthenticationActionType, TAuthenticationActions } from '../../actions/authentication';
import { EBuilderChatActionTypes, TBuilderChatActions } from '../../actions/builderChat';
import { IBuilderChatState } from '../../types/builderChat';

const initialState: IBuilderChatState = {
  isOpened: false,
  openAfterAuth: false,
};

export function builderChatReducer(
  state: IBuilderChatState = initialState,
  action: TBuilderChatActions | TAuthenticationActions,
): IBuilderChatState {
  switch (action.type) {
    case EBuilderChatActionTypes.OpenChat:
      return {
        ...state,
        isOpened: true,
        defaultMessage: action.payload.defaultMessage,
        gaLabel: action.payload.gaLabel,
        realtyUserId: action.payload.realtyUserId,
      };
    case EBuilderChatActionTypes.OpenChatAfterAuth:
      return {
        ...state,
        openAfterAuth: true,
        defaultMessage: action.payload.defaultMessage,
        gaLabel: action.payload.gaLabel,
        realtyUserId: action.payload.realtyUserId,
      };

    case EBuilderChatActionTypes.CloseChat:
      return {
        ...state,
        isOpened: false,
      };

    case EAuthenticationActionType.Succeed: {
      if (!state.openAfterAuth) {
        return state;
      }

      return {
        ...state,
        isOpened: true,
        openAfterAuth: false,
      };
    }

    case EAuthenticationActionType.Cancelled:
      return {
        ...state,
        openAfterAuth: false,
      };

    default:
      return state;
  }
}
