import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TThunkDispatch } from '../types/redux';
import { userSelector } from '../selectors/user';
import { TOpenBuilderChatParameters, openBuilderChat, openBuilderChatAfterAuth } from '../actions/builderChat';
import { callAuthenticationPopup } from '../utils/authentication';

export function useBuilderChat() {
  const dispatch: TThunkDispatch = useDispatch();
  const user = useSelector(userSelector);

  const openChat = React.useCallback(
    (params: TOpenBuilderChatParameters) => {
      if (!user || !user.isAuthenticated) {
        dispatch(openBuilderChatAfterAuth(params));
        callAuthenticationPopup();

        return;
      }

      dispatch(openBuilderChat(params));
    },
    [dispatch, user],
  );

  return React.useMemo(
    () => ({
      openChat,
    }),
    [openChat],
  );
}
