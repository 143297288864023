/**
 * Аналог isEmpty из ramda: возвращает, имеет ли список 0 элементов.
 */
export const isEmpty = <T>(value: T): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'string') {
    return value === '';
  }

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  }

  return false;
};
