import { ca } from '@cian/analytics';

import { ESortingType } from '../../../types/sorting';
import { IJsonQuery } from '../../../types/jsonQuery';

interface IOptions {
  sortingType: ESortingType;
  jsonQuery: IJsonQuery;
}

const SORTING_MAP: { [key in ESortingType]: string } = {
  [ESortingType.Default]: 'default',
  [ESortingType.Area]: 'area_order',
  [ESortingType.Price]: 'price',
  [ESortingType.SquarePrice]: 'price_square_order',
  [ESortingType.StreetName]: 'street_name',
  [ESortingType.Mkad]: 'mcad',
  [ESortingType.LandArea]: 'site_order',
  [ESortingType.WalkingTime]: 'time_to_metro_area',
};

/**
 * Ссылка на события для сортировки:
 * https://conf.cian.tech/pages/viewpage.action?pageId=1159234191
 */
export function trackChangeSorting({ sortingType, jsonQuery }: IOptions) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingJK',
    action: 'sorting',
    label: SORTING_MAP[sortingType],
    sc: jsonQuery,
  });
}
