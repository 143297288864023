import { INotifications } from '../../types/notifications';
import { TReduxActions } from '../../types/redux';
import { EErrorNotificationActionTypes } from '../../actions/notifications';

const defaultState: INotifications = {
  errors: [],
};

export function notificationsReducer(state: INotifications = defaultState, action: TReduxActions): INotifications {
  switch (action.type) {
    case EErrorNotificationActionTypes.Clear:
      return defaultState;
    case EErrorNotificationActionTypes.Push:
      return {
        errors: state.errors.concat(action.payload),
      };
    case EErrorNotificationActionTypes.Remove:
      return {
        errors: state.errors.filter(type => type !== action.payload),
      };
  }

  return state;
}
