import { connect } from 'react-redux';

import { ShowMore } from '../../components/ShowMore';
import { IApplicationState } from '../../types/redux';
import { paginateOffers } from '../../actions/pagination';
import { EPaginationStatus } from '../../types/pagination';

interface IDispatchProps {
  paginateOffers(): void;
}

interface IStateProps {
  loading: boolean;
}

export function mapStateToProps(state: IApplicationState): IStateProps {
  const { status } = state.pagination;

  return {
    loading: status === EPaginationStatus.Loading,
  };
}

export const mapDispatchToProps = {
  paginateOffers,
};

export const ShowMoreContainer = connect<IStateProps, IDispatchProps, {}, IApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(ShowMore);
