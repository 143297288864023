import * as React from 'react';

import * as styles from './styles.css';

export interface ITopBarProps {
  filtersButton: React.ReactNode;
  savedSearchButton: React.ReactNode;
  onMapButton: React.ReactNode;
}

export function TopBar({ filtersButton, savedSearchButton, onMapButton }: ITopBarProps) {
  return (
    <div className={styles['container']}>
      {filtersButton}
      {savedSearchButton}
      {onMapButton}
    </div>
  );
}
