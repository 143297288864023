import * as React from 'react';

import { Underground } from './Underground';
import { IUnderground } from '../../types/rc/geo';

export interface IUndergroundsProps {
  undergrounds: IUnderground[];
}

export function Undergrounds({ undergrounds }: IUndergroundsProps) {
  return (
    <>
      {undergrounds.map(underground => {
        const { id, ...rest } = underground;

        return <Underground key={id} {...rest} />;
      })}
    </>
  );
}
