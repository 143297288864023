import { IMortgageSerpBanner } from '../../types/mortgageSerpBanner';
import { EMortgageSerpBannerActionTypes, TMortgageSerpBannerSetContent } from '../../actions/mortgageSerpBanner';

export function mortgageSerpBannerReducer(
  state: IMortgageSerpBanner = { content: {}, mortgageSerpBannerJSUrl: '' },
  action: TMortgageSerpBannerSetContent,
): IMortgageSerpBanner {
  switch (action.type) {
    case EMortgageSerpBannerActionTypes.SetContent: {
      const {
        payload: { content, mortgageSerpBannerJSUrl, position },
      } = action;
      const nextContent = {
        ...state.content,
        [position]: content,
      };

      return {
        content: nextContent,
        mortgageSerpBannerJSUrl,
      };
    }
  }

  return state;
}
