import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackPhoneButtonClick, trackCall } from './tracking';
import { withPlus } from './utils';
import { PhoneButton } from '../../components/PhoneButton';
import { getProduct, getQueryString } from '../../selectors/analytics';
import { getFirstPhone, getGaLabel } from '../../selectors/rcs';
import { defferedTrack } from '../../utils/analytics/defferedTrack';
import { getDynamicCalltrackingPhone } from '../../utils/dynamicCalltracking';
import { yieldToMainThread } from '../../utils/yieldToMainThread';

interface IPhoneContainerProps {
  id: number;
  buttonText?: string;
}

export function PhoneContainer({ id, buttonText = 'Позвонить застройщику' }: IPhoneContainerProps) {
  const firstPhone = useSelector(getFirstPhone(id));
  const gaLabel = useSelector(getGaLabel(id));
  const product = useSelector(getProduct(id));
  const queryString = useSelector(getQueryString);

  const phone = withPlus(firstPhone && firstPhone.phone);
  const siteBlockId = firstPhone && firstPhone.siteBlockId;

  const handleClick = React.useCallback(async () => {
    const calltrackingPhone = await getDynamicCalltrackingPhone({
      newbuildingId: id,
      queryString,
      siteBlockId,
    });

    window.open(`tel:${calltrackingPhone || phone}`, '_self');

    await yieldToMainThread();
    trackPhoneButtonClick(gaLabel, product);
    defferedTrack(() => trackCall(gaLabel, product));
  }, [id, gaLabel, siteBlockId, product, queryString, phone]);

  if (!phone) {
    return null;
  }

  return <PhoneButton text={buttonText} onClick={handleClick} />;
}
