import * as React from 'react';
import { useSelector } from 'react-redux';
import { CroppedPhone } from '../../components/CroppedPhone';
import { getProduct, getQueryString } from '../../selectors/analytics';
import { getGaLabel } from '../../selectors/rcs';
import { defferedTrack } from '../../utils/analytics';
import { getDynamicCalltrackingPhone } from '../../utils/dynamicCalltracking';
import { trackCall, trackPhoneButtonClick } from '../PhoneContainer/tracking';

interface ICroppedPhoneContainerProps {
  phone: string;
  siteBlockId: number | null;
  newbuildingId: number;
  open?: boolean;
}

export function CroppedPhoneContainer({ newbuildingId, phone, open, siteBlockId }: ICroppedPhoneContainerProps) {
  const [isOpenPhone, setIsOpenPhone] = React.useState(Boolean(open));
  const [dynamicPhone, setDynamicPhone] = React.useState<string>(phone);

  const gaLabel = useSelector(getGaLabel(newbuildingId));
  const queryString = useSelector(getQueryString);
  const product = useSelector(getProduct(newbuildingId));

  const handlePhoneOpen = React.useCallback(async () => {
    // Обернуто в setTimeout для улучшения INP
    setTimeout(() => trackPhoneButtonClick(gaLabel, product, true), 0);

    const calltrackingPhone = await getDynamicCalltrackingPhone({
      newbuildingId,
      queryString,
      siteBlockId,
    });

    setDynamicPhone(calltrackingPhone || phone);
    setIsOpenPhone(true);
  }, [siteBlockId, phone, newbuildingId, queryString, gaLabel, product]);

  const handlePhoneCall = React.useCallback(async () => {
    window.open(`tel:${dynamicPhone}`, '_self');
    defferedTrack(() => trackCall(gaLabel, product));
  }, [dynamicPhone, gaLabel, product]);

  React.useEffect(() => {
    if (open) {
      handlePhoneOpen();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CroppedPhone
      cutPhone={!isOpenPhone}
      onClick={isOpenPhone ? handlePhoneCall : handlePhoneOpen}
      phone={dynamicPhone}
    />
  );
}
