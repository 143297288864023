import * as React from 'react';
import { useSelector } from 'react-redux';

import { buildingStatusSelector, getSalesStartLabel, offerTypesSelector } from '../../selectors/rcs';
import { BuildingStatus } from '../../components/BuildingStatus';
import { SalesStartLabel } from '../../components/Labels';

interface IBuildingStatusContainerProps {
  id: number;
}

export function BuildingStatusContainer({ id }: IBuildingStatusContainerProps) {
  const buildingStatus = useSelector(buildingStatusSelector(id));
  const offerTypes = useSelector(offerTypesSelector(id));
  const salesLabel = useSelector(getSalesStartLabel(id));

  /* istanbul ignore next */
  return (
    <BuildingStatus
      status={buildingStatus}
      offerTypes={offerTypes}
      salesStart={salesLabel ? <SalesStartLabel text={salesLabel} /> : null}
    />
  );
}
