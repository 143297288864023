import { IGeo } from '../types/rc/geo';
import { IGeoSchema } from '../repositories/search-engine/entities/geo/GeoSchema';
import { IAddressItemSchema } from '../repositories/search-engine/entities/geo/AddressItemSchema';

const addressToString = (data: IAddressItemSchema[]): string => {
  return data.map(item => item.value).join(', ');
};

export const prepareGeo = (geo: IGeoSchema): IGeo => {
  return {
    regionId: geo.regionId,
    undergrounds: geo.undergrounds,
    address: addressToString(geo.address),
  };
};
