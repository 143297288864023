import { IConfig } from '@cian/config/shared';

interface INewbuildingPromosBannerData {
  title: string;
  description: string;
  link: string;
}

const NEWBUILDING_PROMOS_BANNER_CONFIG_KEY = 'newbuilding-search-mobile-frontend.newbuildingPromosBanner.data';

export const getNewbuildingPromosBannerData = (config: IConfig): INewbuildingPromosBannerData => {
  const data = config.get<INewbuildingPromosBannerData>(NEWBUILDING_PROMOS_BANNER_CONFIG_KEY);

  if (data) {
    return data;
  }

  return {
    title: 'Возьмите выгодную ипотеку',
    description: 'Льготная ипотека и специальные \nпредложения',
    link: '/skidki-i-akcii/',
  };
};
