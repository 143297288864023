import * as React from 'react';
import classNames from 'clsx';

import { IRCPessimization, ERCPessimizationType } from '../../types/rc/pessimization';

import * as styles from './styles.css';

interface IRCPessimizationProps {
  pessimization: IRCPessimization;
}

export function RCPessimization({ pessimization }: IRCPessimizationProps) {
  return (
    <div
      className={classNames(
        styles['pessimization'],
        pessimization.type === ERCPessimizationType.Strong && styles['strong'],
      )}
    >
      {pessimization.text}
    </div>
  );
}
