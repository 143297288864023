import * as React from 'react';
import { useSelector } from 'react-redux';
import { ReviewEmpty, ReviewLink } from '../../components/ReviewLink';
import { getNewbuildingReviewStat, selectRCById } from '../../selectors/rcs';
import { getPrettyFloat } from '../../utils/getPrettyFloat';
import { IApplicationState } from '../../types/redux';

export interface IReviewLinkContainerProps {
  id: number;
}

export const ReviewLinkContainer: React.FC<IReviewLinkContainerProps> = ({ id }) => {
  const { fullUrl } = useSelector((state: IApplicationState) => selectRCById(state, id));
  const { reviewCountText, totalRate } = useSelector(getNewbuildingReviewStat(id));

  if (totalRate === 0) {
    return <ReviewEmpty reviewCountText={reviewCountText} />;
  }

  return <ReviewLink url={fullUrl} reviewCountText={reviewCountText} totalRate={getPrettyFloat(totalRate)} />;
};
