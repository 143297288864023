import { INewbuildingForMobileSiteSchema } from '../repositories/search-engine/entities/newbuilding/NewbuildingForMobileSiteSchema';
import { IRC } from '../types/rc/rcs';
import { prepareGeo } from './prepareGeo';
import { prepareAuthor } from './prepareAuthor';
import { prepareFeatures } from './prepareFeatures';
import { prepareMedia } from './prepareMedia';
import { prepareRCOffers } from './prepareRCOffers';
import { preparePhones } from './preparePhones';

export const prepareRC = (rc: INewbuildingForMobileSiteSchema): IRC => {
  return {
    buildingStatus: rc.buildingStatus,
    gaLabel: rc.gaLabel as string,
    id: rc.id,
    isFavorite: rc.isFavorite,
    name: rc.name,
    priceLimits: rc.priceLimits as string,
    puids: rc.puids,
    fullUrl: rc.fullUrl,
    reviewStat: rc.reviewStat,
    author: prepareAuthor(rc.author),
    features: prepareFeatures(rc.newbuildingFeatures),
    geo: prepareGeo(rc.geo),
    isProblem: rc.isProblem || false,
    isSalesLeader: rc.isSalesLeader || false,
    isUpcomingSale: rc.isUpcomingSale || false,
    media: prepareMedia(rc.media),
    pessimization: null,
    rcOffers: prepareRCOffers(rc),
    phones: preparePhones(rc),
    salesStartLabel: rc.salesStartLabel || '',
    verifiedByCian: rc.verifiedByCian || false,
    offerTypes: rc.offerTypes,
    promoLabels: rc.promoLabels,
  };
};
