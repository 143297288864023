import * as React from 'react';
import { useSelector } from 'react-redux';

import { PromoTitle } from '../../components/PromoTitle';
import { Section } from '../../components/Section';
import { SeoTitle } from '../../components/SeoTitle';
import { getMarkedListItems, getPageTitle } from '../../selectors/seoData';
import { getIsPromoPage, getPromoPageTitle } from '../../selectors/specialPromo';
import { SeoSubtitles } from '../../components/SeoSubtitles';

export function SeoTitleContainer() {
  const title = useSelector(getPageTitle);
  const promoTitle = useSelector(getPromoPageTitle);
  const isPromo = useSelector(getIsPromoPage);
  const markedList = useSelector(getMarkedListItems);

  const subtitles = React.useMemo(() => {
    return markedList.find(item => item.underH1)?.content;
  }, [markedList]);

  if (isPromo) {
    return <PromoTitle title={promoTitle} />;
  }

  return (
    <Section>
      <SeoTitle title={title} />
      {subtitles && <SeoSubtitles subtitles={subtitles} />}
    </Section>
  );
}
