import { IApplicationState } from '../../types/redux';
import { ISerpCianAnalyticsPageViewObject } from '../../types/cianAnalyticsPageViewObject';
import { IJsonQuery } from '../../types/jsonQuery';
import { getSiteType } from './getSiteType';
import { getTrackingUser } from '../analytics';

interface IPageViewParams {
  state: IApplicationState;
  jsonQuery: IJsonQuery;
  abUseExperiments: string;
}

export function createCianAnalyticsPageViewObject({
  state,
  jsonQuery,
  abUseExperiments,
}: IPageViewParams): ISerpCianAnalyticsPageViewObject {
  const pageNumber = state?.pagination?.page || 1;
  const productsPage = state.analytics.products.pages[pageNumber - 1] || [];
  const products = productsPage.map(id => state.analytics.products.entities[id]);

  return {
    modelVersion: '1',
    page: {
      listingType: 'list',
      pageType: 'ListingJK',
      siteType: getSiteType(state.deviceType),
      pageNumber,
      queryString: state?.analytics?.queryString || '',
      offersQty: state?.analytics.offersQty || 0,
      extra: {
        mobileWebsiteType: 'new',
        abUseExperiments,
      },
    },
    user: getTrackingUser(state),
    products,
    sc: jsonQuery,
  };
}
