import { IApplicationState } from '../../types/redux';
import { selectRCById } from './rcs';
import { IReviewStat } from '../../types/reviewStat';

const DEFAULT_REVIEW_STAT: IReviewStat = {
  reviewCountText: 'Нет отзывов',
  totalRate: 0,
};

export const getNewbuildingReviewStat =
  (id: number) =>
  (state: IApplicationState): IReviewStat => {
    const { reviewStat } = selectRCById(state, id);

    return reviewStat || DEFAULT_REVIEW_STAT;
  };
