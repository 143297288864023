import * as React from 'react';
import * as style from './style.css';
import { Loader } from '../Loader';

export const NewbuildingPromoCarouselLoader = () => {
  return (
    <div className={style['loader']}>
      <Loader size={16} />
    </div>
  );
};
