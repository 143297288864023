import { IProducts } from '../types/analytics';
import { IResponseDataSchema } from '../repositories/search-engine/entities/response/ResponseDataSchema';

export const prepareProducts = (response: IResponseDataSchema): IProducts => {
  const { newbuildings, jsonQuery } = response;
  // @ts-expect-error jsonQuery - нетипизированный объект
  const page: number = jsonQuery?.page?.value || 1;

  return newbuildings.reduce(
    (result, item, index) => {
      const { id } = item;

      result.pages[0].push(id);
      result.entities[id] = {
        id,
        offerType: 'JK',
        position: (Number(page) - 1) * 28 + (index + 1),
      };

      return result;
    },
    {
      entities: {},
      pages: [[]],
    } as IProducts,
  );
};
