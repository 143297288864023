import { IApplicationState } from '../../types/redux';
import { IRC } from '../../types/rc/rcs';
import { createSelector } from '@reduxjs/toolkit';

export const selectRCsIds = (state: IApplicationState): number[] => state.rcs.allIds;
export const selectRCsById = (state: IApplicationState) => state.rcs.byId;

export const selectRCId = (state: IApplicationState, id: number): number => id;

export const selectRCById = createSelector([selectRCsById, selectRCId], (byId, rcId): IRC => byId[rcId]);

export const rcOffersSelector = createSelector([selectRCById], (rc: IRC) => {
  return rc.rcOffers;
});
