import { useMemo } from 'react';

import type { AdfoxContextData } from '../../types';
import { PParams } from '../../../../../types';

import { useSelector } from 'react-redux';
import { IApplicationState } from '../../../../../../../types/redux';

type Params = AdfoxContextData['params'];

export const PR_RANDOM = getRandomId();

export function getRandomId() {
  return String(Math.floor(Math.random() * 4294967295) + 1);
}

const selectAdfoxPuids = (state: IApplicationState) => state.puidsByFilter;

export function useAdfoxParams(pParams: PParams): Params {
  const puids = useSelector(selectAdfoxPuids);

  return useMemo((): Params => {
    return {
      pr: PR_RANDOM,
      ...puids,
      ...pParams,
    } as Params;
  }, [pParams, puids]);
}
