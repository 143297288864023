import * as React from 'react';

import { ArticleHeading2, ArticleParagraph2, Button, Theme } from '@cian/ui-kit';

import * as styles from './FlightBanner.css';
import { NBSP } from '../../constants';

interface IFlightBannerProps {
  url: string;
  onBannerClick: () => void;
}

export const FlightBanner: React.FC<IFlightBannerProps> = ({ url, onBannerClick }) => {
  return (
    <a href={url} className={styles['container']} data-testid="FlightBanner" onClick={onBannerClick}>
      <div className={styles['heading-wrapper']}>
        <ArticleHeading2 color="text-inverted-default" whiteSpace="pre-wrap">
          Скидки на{NBSP}новостройки
        </ArticleHeading2>
      </div>
      <div className={styles['description-wrapper']}>
        <ArticleParagraph2 color="text-inverted-default" whiteSpace="pre-wrap" wordWrap="break-word">
          12 способов сэкономить
        </ArticleParagraph2>
      </div>
      <div>
        <Theme theme="colored">
          <Button>Выбрать</Button>
        </Theme>
      </div>
    </a>
  );
};
