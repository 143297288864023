import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';
import { IPhone } from '../../types/rc/phone';

export const getFirstPhone =
  (id: number) =>
  (state: IApplicationState): IPhone | null => {
    const { phones } = selectRCById(state, id);

    return phones && phones.length ? phones[0] : null;
  };
