import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { FiltersButton } from '../../components/TopBar/FiltersButton';

export function mapStateToProps(state: IApplicationState) {
  const { filtersUrl } = state;

  return {
    filtersUrl,
  };
}

export const FiltersButtonContainer = connect(mapStateToProps)(FiltersButton);
