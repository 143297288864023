import { connect } from 'react-redux';

import { sortNewbuildings } from '../../actions/sorting';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { SortingSelect } from '../../components/SortingSelect';
import { ESortingType, ISortingOption } from '../../types/sorting';
import { EPageStatus } from '../../types/pageStatus';

interface IStateProps {
  sortingType: ESortingType;
  options: ISortingOption[];
  areOffersLoading: boolean;
}

interface IDispatchProps {
  sortNewbuildings(sortingType: ESortingType): void;
}

export function mapStateToProps({ pageStatus, sorting }: IApplicationState): IStateProps {
  return {
    areOffersLoading: pageStatus === EPageStatus.Loading,
    sortingType: sorting.current.type,
    options: sorting.options,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): IDispatchProps {
  return {
    sortNewbuildings: (sortingType: ESortingType) => {
      dispatch(sortNewbuildings({ sortingType }));
    },
  };
}

export const SortingSelectContainer: React.ComponentType<{}> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortingSelect);
