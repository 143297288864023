import * as React from 'react';
import { useAuthenticationSubscription } from '../../hooks/useAuthenticationSubscription';

import { IReduxStore } from '../../types/redux';

interface ISubscriptionsContainerProps {
  reduxStore: IReduxStore;
}

export const SubscriptionsContainer: React.FC<ISubscriptionsContainerProps> = ({ reduxStore }) => {
  useAuthenticationSubscription({ reduxStore });

  return null;
};
