import * as React from 'react';

import { TopBar } from '../../components/TopBar';

import { SavedSearchButtonContainer } from '../SavedSearchButtonContainer';
import { OnMapButtonContainer } from '../OnMapButtonContainer';
import { FiltersButtonContainer } from '../FiltersButtonContainer';

// eslint-disable-next-line react/display-name
export const TopBarContainer = React.memo(() => {
  return (
    <TopBar
      filtersButton={<FiltersButtonContainer />}
      savedSearchButton={<SavedSearchButtonContainer />}
      onMapButton={<OnMapButtonContainer />}
    />
  );
});
