import * as React from 'react';
import { IconActionArrowUp16 } from '@cian/ui-kit-design-tokens/icons';
import * as styles from './ArrowUpRoundButton.css';

interface IProps {
  onClick(): void;
}

export function ArrowUpRoundButton({ onClick }: IProps) {
  return (
    <button onClick={onClick} aria-label={'Наверх'} className={styles['button']}>
      <IconActionArrowUp16 color="icon-inverted-default" />
    </button>
  );
}
