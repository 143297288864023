import { NotFoundError } from '@cian/peperrors/shared';

import { IJsonQuery, ESortValue } from '../../types/jsonQuery';

export interface IJsonQueryHelper {
  dump(): IJsonQuery;
  set(newJsonQuery: IJsonQuery): void;
  mergePage(page: number): IJsonQuery;
  mergeSorting(sortValue: ESortValue): IJsonQuery;
}

export function createJsonQueryHelper(): IJsonQueryHelper {
  let jsonQuery: IJsonQuery | null = null;

  return {
    dump: () => {
      if (jsonQuery === null) {
        throw new NotFoundError({
          message:
            'jsonQueryHelper must be initialized before accessing it. Forgot to call set() before accessing jsonQueryHelper?',
          domain: 'Serp/shared/utils/jsonQuery#createJsonQueryHelper()',
        });
      }

      return jsonQuery;
    },

    set: newJsonQuery => {
      jsonQuery = newJsonQuery;
    },

    mergePage: (page: number) => {
      return Object.assign({}, jsonQuery, {
        page: {
          type: 'term',
          value: page,
        },
      });
    },

    mergeSorting: (sortValue: ESortValue) => {
      return Object.assign({}, jsonQuery, {
        sort: {
          type: 'term',
          value: sortValue,
        },
        page: undefined,
      });
    },
  };
}

export const jsonQueryHelper = createJsonQueryHelper();
