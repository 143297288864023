import { fetchNewbuildingsByLocation } from '../../../shared/actions/location';
import { IReduxStore } from '../../../shared/types/redux';

export const subscribeBrowserHistory = (store: IReduxStore) => {
  window.addEventListener('popstate', () => {
    const { pathname, search } = window.location;
    const path = pathname + search;

    store.dispatch(fetchNewbuildingsByLocation(path));
  });
};
