import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';

export function trackBannerClick(): void {
  ca('eventSite', {
    action: 'click_sopr',
    category: 'ListingJK',
    label: 'nb_mortgage_banner',
    name: 'oldevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}

export function trackBannerShow(): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'ListingJK',
    label: 'nb_mortgage_banner',
    name: 'oldevent',
    sc: jsonQueryHelper.dump(),
    useLastProducts: true,
  });
}
