import * as React from 'react';
import { IconActionPinLine16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from '../styles.css';

export interface IOnMapButtonProps {
  mapUrl: string;
  handleClickMapButton(): void;
}

export function OnMapButton({ mapUrl, handleClickMapButton }: IOnMapButtonProps) {
  const onClick = () => {
    handleClickMapButton();
  };

  return (
    <a href={mapUrl} className={styles['button']} onClick={onClick}>
      <IconActionPinLine16 color="current_color" />
      <span>На карте</span>
    </a>
  );
}
