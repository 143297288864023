import * as React from 'react';
import { useSelector } from 'react-redux';

import { Carousel } from '../Carousel';

import { RCPessimization } from '../../components/RCPessimization';
import { IApplicationState } from '../../types/redux';
import { selectRCById } from '../../selectors/rcs';

interface IPessimizableCarouselContainerProps {
  id: number;
}

export function PessimizableCarouselContainer({ id }: IPessimizableCarouselContainerProps) {
  const pessimizationSelector = React.useCallback(
    (state: IApplicationState) => {
      const rc = selectRCById(state, id);

      return rc.pessimization;
    },
    [id],
  );
  const pessimization = useSelector(pessimizationSelector);

  if (pessimization) {
    return <RCPessimization pessimization={pessimization} />;
  }

  return <Carousel id={id} />;
}
