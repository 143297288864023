import { IMedia } from '../types/rc/media';
import { IMediaSchema } from '../repositories/search-engine/entities/media/MediaSchema';

export const prepareMedia = (media?: IMediaSchema[]): IMedia[] => {
  if (!media) {
    return [];
  }

  return media.map<IMedia>(({ previewUrl }) => ({ url: previewUrl }));
};
