import * as React from 'react';

import * as styles from './SeoTitle.css';
import { UIHeading3 } from '@cian/ui-kit';

interface ISeoTitleProps {
  title: string;
}

export const SeoTitle: React.FunctionComponent<ISeoTitleProps> = props => {
  const { title } = props;

  return (
    <div className={styles['title']}>
      <UIHeading3 as="h1">{title}</UIHeading3>
    </div>
  );
};
