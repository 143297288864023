import { IAdFoxBannerOnLoadData } from '@cian/adfox-component';

import * as React from 'react';

import { PParams } from '../../../types';
import { AdfoxContextData, AdfoxContextProvider } from '../../context/AdfoxContext';
import { AdfoxCarouselBannerWrapper, AdfoxCarouselWrapper, Scroller } from '../../../../AdfoxCarousel';
import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';

const P_PARAMS: PParams = { p1: 'dciqj', p2: 'fkhj' };
const ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'between_offers',
  place__DEPRECATED: 'newbuildingsSearch',
  name__DEPRECATED: 'Listing_slider_TGB',
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '100%' };

type Banner = {
  type: string;
  position: number;
};

type Slide = {
  position: number;
  visible?: boolean;
};

const BANNER_DIRECT_NAME = 'banner.direct';

type AdfoxListingCarouselBannersProps = {
  position: number;
};

export const AdfoxListingCarouselBanners = React.memo<AdfoxListingCarouselBannersProps>(
  function AdfoxListingCarouselBanners({ position }) {
    const [slides, setSlides] = React.useState<Slide[]>(() => {
      const initialSlides: Slide[] = [];

      for (let i = 1; i <= 5; i++) {
        initialSlides.push({ position: i, visible: i === 1 });
      }

      return initialSlides;
    });
    const [loadedBanners, setLoadedBanners] = React.useState<Banner[]>([]);
    const [hasStubbedBanners, setHasStubbedBanners] = React.useState<boolean>(false);

    const handleLoad = (position: number) => (data: IAdFoxBannerOnLoadData) => {
      if (!loadedBanners.length) {
        if (data.bundleName === BANNER_DIRECT_NAME) {
          //Если первый видимый баннер - РСЯ, то отображаем только его
          setSlides(state => state.slice(0, 1));
        } else {
          //Если первый видимый баннер - не РСЯ, то позволяем загрузить второй
          setSlides(state => state.map(el => (el.position === position + 1 ? { ...el, visible: true } : el)));
        }
      }

      setLoadedBanners(state => [...state, { type: data.bundleName, position }]);
    };

    const handleView = (position: number) => () => {
      if (loadedBanners.length) {
        setSlides(state => state.map(slide => (slide.position === position + 1 ? { ...slide, visible: true } : slide)));
      }
    };

    const handleStub = (position: number) => () => {
      setHasStubbedBanners(true);

      const filteredSlides = slides.filter(el => el.position < position);

      setSlides(filteredSlides);
    };

    const handleRender = (position: number) => () => {
      if (loadedBanners.length < 2) {
        return;
      }

      const renderedSlide = loadedBanners.find(el => el.position === position);
      const isDirect = renderedSlide?.type === BANNER_DIRECT_NAME;

      //Перестаем грузить рекламу если попались на рся после 2 баннеров
      if (isDirect) {
        setSlides(slides.filter(el => el.position < position));
      }
    };

    if (hasStubbedBanners && loadedBanners.length === 0) {
      return null;
    }

    return (
      <AdfoxContextProvider postViewAnalyticsData={ANALYTICS_DATA} pParams={P_PARAMS} size={SIZE}>
        <AdfoxCarouselWrapper>
          <Scroller>
            {slides.map(({ position: galleryPosition, visible }) => (
              <AdfoxCarouselBannerWrapper
                fullWidth={slides.length === 1 || (!loadedBanners.length && galleryPosition === 1)}
                key={galleryPosition}
              >
                {visible ? (
                  <AdfoxBannerContainer
                    galleryPosition={galleryPosition}
                    position={position}
                    onLoad={handleLoad(galleryPosition)}
                    onRender={handleRender(galleryPosition)}
                    onStub={handleStub(galleryPosition)}
                    onView={handleView(galleryPosition)}
                  />
                ) : (
                  <div />
                )}
              </AdfoxCarouselBannerWrapper>
            ))}
          </Scroller>
        </AdfoxCarouselWrapper>
      </AdfoxContextProvider>
    );
  },
);
