import { getSubdomain } from '../../selectors/location';
import { TThunkAction } from '../../types/redux';
import { getNewbuildingsFailure, getNewbuildingsRequest, getNewbuildingsSuccess } from './actions';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';
import { trackPageView } from '../../analytics';
import { IJsonQuery } from '../../types/jsonQuery';
import { fetchSearchNewbuildings } from '../../services/newbuildings';

interface IRequestNewbuildingsOptions {
  path?: string;
  jsonQuery?: IJsonQuery;
}

export const requestNewbuildings = ({ path, jsonQuery }: IRequestNewbuildingsOptions): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, { httpApi, config }) => {
    dispatch(getNewbuildingsRequest());

    const subdomain = getSubdomain(getState());

    try {
      const response = await fetchSearchNewbuildings(httpApi, {
        path,
        jsonQuery,
        subdomain,
      });

      jsonQueryHelper.set(response.jsonQuery as IJsonQuery);
      dispatch(getNewbuildingsSuccess(response));

      trackPageView(getState(), config);
    } catch (error) {
      dispatch(getNewbuildingsFailure());

      throw error;
    }
  };
};
