import * as React from 'react';
import { useSelector } from 'react-redux';

import { SaveSearchProvider as Provider } from '../../../packages/SaveSearchModal';
import { userSelector } from '../../selectors/user';
import { getSaveSearchMicrofrontend } from '../../services/getSaveSearchMicrofrontend';
import { IApplicationState } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';

enum ESource {
  Card = 'card',
  Listing = 'listing',
  Map = 'map',
}

export const SaveSearchModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const context = useApplicationContext();
  const fetchManifest = React.useCallback(async () => getSaveSearchMicrofrontend(context), [context]);

  const user = useSelector(userSelector);
  const title = useSelector<IApplicationState, string | null>(state => state.seoData.h1 || null);

  /* istanbul ignore next */
  const email = React.useMemo(() => (user.isAuthenticated ? user.email : null), [user]);
  const isAuthenticated = user.isAuthenticated;

  const params = {
    title,
    email,
    isAuthenticated,
    source: ESource.Listing,
  };

  return (
    <Provider fetchManifest={fetchManifest} params={params}>
      {children}
    </Provider>
  );
};
