// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface INewbuildingFeatureSchema {
  /** Тип **/
  type: EType;
}

export enum EType {
  /** Наличие 3D тура **/
  'Has3dTour' = 'has3dTour',
  /** Наличие Записи на просмотр **/
  'HasFlatTourBooking' = 'hasFlatTourBooking',
  /** Наличие видео **/
  'HasVideo' = 'hasVideo',
}
