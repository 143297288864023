import { HttpBadStatusCodeError } from '@cian/peperrors';

import { IApplicationContext } from '../../types/application';
import { IV1GetOrCreateChatResponse } from '../../repositories/newbuilding-consultants/entities/get_or_create_chat/V1GetOrCreateChatResponse';
import {
  fetchGetOrCreateChat,
  TGetOrCreateChatResponse,
} from '../../repositories/newbuilding-consultants/v1/get-or-create-chat';

export interface IFetchConsultantChatParameters {
  path: string;
}

export async function fetchConsultantChat(
  context: IApplicationContext,
  { path }: IFetchConsultantChatParameters,
): Promise<IV1GetOrCreateChatResponse> {
  const { httpApi } = context;

  const chatInfoResponse: TGetOrCreateChatResponse = await fetchGetOrCreateChat({
    httpApi,
    parameters: {
      pageUrl: path,
    },
  });

  if (chatInfoResponse.statusCode !== 200) {
    throw new HttpBadStatusCodeError(
      'Не удалось получить информацию для чата',
      chatInfoResponse.statusCode,
      'fetchConsultantChatId',
    );
  }

  return chatInfoResponse.response;
}
