import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { PromoSliderLoadable, PIK_SLIDES } from '../../components/PromoSlider';
import { getSpecialPromoType } from '../../selectors/specialPromo';
import { ESpecialPromoType } from '../../types/specialPromo';
import { getPikDisclaimer } from '../../selectors/pikDisclaimer';

const PROMO_TYPES_MAP = new Map<ESpecialPromoType, FC[]>([[ESpecialPromoType.Pik, PIK_SLIDES]]);

export const SpecialPromoSliderContainer = () => {
  const promoType = useSelector(getSpecialPromoType);
  const slides = promoType && PROMO_TYPES_MAP.get(promoType);
  const disclaimer = useSelector(getPikDisclaimer);

  if (!slides) {
    return null;
  }

  return (
    <PromoSliderLoadable arrows disclaimer={disclaimer}>
      {slides.map((Slide, index) => (
        <Slide key={index} />
      ))}
    </PromoSliderLoadable>
  );
};
