import * as React from 'react';
import { useSelector } from 'react-redux';
import { Section } from '../../components/Section';

import { SeoMarkedList } from '../../components/SeoMarkedList';
import { getMarkedListItems } from '../../selectors/seoData';

export const SeoMarkedListContainer: React.VFC = () => {
  const markedList = useSelector(getMarkedListItems);

  const items = React.useMemo(() => markedList.filter(item => !item.underH1), [markedList]);

  if (items.length === 0) {
    return null;
  }

  return (
    <Section>
      <SeoMarkedList items={items} />
    </Section>
  );
};
