import {
  fetchCreateUpcomingSaleRequest,
  TCreateUpcomingSaleRequestRequest,
  ICreateUpcomingSaleRequestResponse200,
  ICreateUpcomingSaleRequestResponse400,
} from '../../repositories/newbuilding-flat-view-order/v1/create-upcoming-sale-request';
import { TThunkAction } from '../../types/redux';

export function createUpcomingSaleRequest(parameters: TCreateUpcomingSaleRequestRequest): TThunkAction<Promise<void>> {
  return async (dispatch, getState, { httpApi, logger }): Promise<void> => {
    try {
      const response = await fetchCreateUpcomingSaleRequest<
        ICreateUpcomingSaleRequestResponse200,
        ICreateUpcomingSaleRequestResponse400
      >({
        httpApi,
        parameters,
        config: {
          requestConfig: {
            headers: [['Content-Type', 'application/json']],
          },
        },
      });

      if (![200, 204].includes(response.statusCode)) {
        throw (response as ICreateUpcomingSaleRequestResponse400).response.message;
      }
    } catch (error) {
      logger.error(error, {
        domain: 'actions/upcomingSaleRequest/createUpcomingSaleRequest',
      });

      throw error;
    }
  };
}
