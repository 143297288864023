import { IUIRuntime } from '@cian/mf-registry/browser';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';

import {
  fetchGetSaveSearchMicrofrontend,
  TGetSaveSearchMicrofrontendResponse,
  IGetSaveSearchMicrofrontendRequest,
} from '../../repositories/save-search-frontend';
import { IApplicationContext } from '../../types/application';

export type IFetchSaveSearchParameters = IGetSaveSearchMicrofrontendRequest;

export type TGetSaveSearchMicrofrontendResult = {
  manifest: IMicrofrontendManifest;
} | null;

export async function getSaveSearchMicrofrontend(
  context: IApplicationContext,
  parameters: IFetchSaveSearchParameters = {},
): Promise<TGetSaveSearchMicrofrontendResult> {
  const { httpApi, logger, microfrontendsRegistry } = context;

  try {
    const saveSearchData: TGetSaveSearchMicrofrontendResponse = await fetchGetSaveSearchMicrofrontend({
      httpApi,
      parameters,
    });

    const image = microfrontendsRegistry.register(saveSearchData.response.manifest);

    /* istanbul ignore next */
    if (typeof window !== 'undefined') {
      const runtime = image.runtimeStrict<IUIRuntime>(saveSearchData.response.manifest.runtimeName);
      await runtime.ensure();
    }

    return {
      manifest: saveSearchData.response.manifest,
    };
  } catch (ex) {
    logger.error(ex, { domain: 'shared.services.getSaveSearchMicrofrontend' });

    return null;
  }
}
