import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export const trackBannerClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BlackFriday', // Переиспользуем категорию ЧП.
    action: 'click_sopr',
    label: 'listing_banner',
    sc: jsonQueryHelper.dump(),
  });
};
