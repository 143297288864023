import { selectRCById } from './rcs';
import { IApplicationState } from '../../types/redux';
import { IAuthor } from '../../types/author';

export const getAuthor =
  (id: number) =>
  (state: IApplicationState): IAuthor => {
    const rc = selectRCById(state, id);

    return rc.author;
  };
