import * as React from 'react';
import { useSelector } from 'react-redux';

import { Card } from '../../components/Card';
import { selectRCById } from '../../selectors/rcs';
import { BuildingStatusContainer } from '../BuildingStatusContainer';
import { FavoritesContainer } from '../FavoritesContainer';
import { LocationContainer } from '../LocationContainer';
import { NewbuildingFeaturesContainer } from '../NewbuildingFeaturesContainer';
import { PessimizableCarouselContainer } from '../PessimizableCarouselContainer';
import { RCOffersContainer } from '../RCOffersContainer';
import { RCTitleContainer } from '../RCTitleContainer';
import { ShowPhoneButtonContainer } from '../ShowPhoneButton';
import { UpcomingSaleRequestButton } from '../UpcomingSaleRequestButton';

import { trackRCCardClick } from './analytics';
import { ReviewLinkContainer } from '../ReviewLinkContainer';
import { AuthorContainer } from '../AuthorContainer';
import { Labels } from '../Labels';
import { IApplicationState } from '../../types/redux';
import { ActionsContainer } from '../ActionsContainer';

export interface IRCCardContainerProps {
  id: number;
}

/**
 * TODO: При реализации карточки для планшета, `content` стоит
 * сдлеать отдельным контейнером, т.к. в нем появится текст объявки
 * и кнопка "Избранное".
 */
export function RCCardContainer({ id }: IRCCardContainerProps) {
  const { fullUrl, priceLimits, isUpcomingSale, name } = useSelector((state: IApplicationState) =>
    selectRCById(state, id),
  );

  return (
    <Card
      onClick={trackRCCardClick}
      href={fullUrl}
      name={name}
      labels={<Labels id={id} />}
      buildingStatus={<BuildingStatusContainer id={id} />}
      controlButton={isUpcomingSale ? <UpcomingSaleRequestButton id={id} /> : <ActionsContainer id={id} />}
      favorite={<FavoritesContainer id={id} />}
      features={<NewbuildingFeaturesContainer id={id} />}
      gallery={<PessimizableCarouselContainer id={id} />}
      location={<LocationContainer id={id} />}
      priceLimits={priceLimits}
      rcOffers={isUpcomingSale ? null : <RCOffersContainer id={id} />}
      showPhone={isUpcomingSale ? <UpcomingSaleRequestButton id={id} /> : <ShowPhoneButtonContainer id={id} />}
      title={<RCTitleContainer id={id} />}
      reviewLink={<ReviewLinkContainer id={id} />}
      author={<AuthorContainer id={id} />}
    />
  );
}
