import * as React from 'react';

import * as styles from './SeoCrossLinks.css';
import { SeoCrossLinksGroup } from './SeoCrossLinksGroup';
import { ICrossLinks } from '../../types/crossLinks';
import { Section } from '../Section';

interface ISeoCrossLinksProps {
  items: ICrossLinks[];
}

export function SeoCrossLinks({ items }: ISeoCrossLinksProps) {
  return (
    <Section className={styles['section']} data-testid="SeoCrossLinks">
      {items.map((item, index) => {
        const id = `SeoMarkedListItem_${index}`;

        return <SeoCrossLinksGroup id={id} key={id} {...item} />;
      })}
    </Section>
  );
}
