import { IHttpApi } from '@cian/http-api';
import {
  fetchSearchNewbuildingsMobileSite,
  TSearchNewbuildingsMobileSiteResponse,
} from '../../repositories/search-engine/v1/search-newbuildings-mobile-site';
import { assertResponseDataError, assertUnknownError } from './asserts';
import { IJsonQuery } from '../../types/jsonQuery';
import { IResponseDataSchema } from '../../repositories/search-engine/entities/response/ResponseDataSchema';

interface IRequestNewbuildingsParams {
  jsonQuery?: IJsonQuery;
  path?: string;
  subdomain?: string;
}

export const fetchSearchNewbuildings = async (
  httpApi: IHttpApi,
  { jsonQuery, path, subdomain }: IRequestNewbuildingsParams,
): Promise<IResponseDataSchema> => {
  const result: TSearchNewbuildingsMobileSiteResponse = await fetchSearchNewbuildingsMobileSite({
    httpApi,
    parameters: {
      jsonQuery,
      path,
      subdomain,
    },
  });

  assertUnknownError(result);
  assertResponseDataError(result.response.responseData);

  return result.response.responseData;
};
