/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useSelector } from 'react-redux';

import { RCTitle } from '../../components/RCTitle';
import { selectRCById } from '../../selectors/rcs';
import { IApplicationState } from '../../types/redux';

interface IRCTitleContainerProps {
  id: number;
}

export function RCTitleContainer({ id }: IRCTitleContainerProps) {
  const { name, isSalesLeader } = useSelector((state: IApplicationState) => selectRCById(state, id));

  return <RCTitle name={name} isSalesLeader={isSalesLeader} />;
}
