import * as React from 'react';
import { IconMapMetroMsk16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'clsx';

import { ETransportType } from '../../../types/rc/geo';
import { UndergroundTimeToGet } from './UndergroundTimeToGet';

import * as styles from './styles.css';

export interface IUndergroundProps {
  name: string;
  transportType?: ETransportType | null;
  time?: string | null;
  lineColor?: string | null;
  underConstruction: boolean;
}

export function Underground({ lineColor, name, transportType, time, underConstruction }: IUndergroundProps) {
  return (
    <div className={classNames(styles['container'], underConstruction && styles['under-construction'])}>
      {lineColor && (
        <div className={styles['icon']} style={{ color: `#${lineColor}` }}>
          <IconMapMetroMsk16 color="current_color" />
        </div>
      )}
      <span>{name}</span>

      {time && transportType && <UndergroundTimeToGet time={time} transportType={transportType} />}
    </div>
  );
}
