import { ArticleParagraph2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IBuildingStatusProps {
  salesStart?: React.ReactNode | null;
  status: string;
  offerTypes?: string;
}

export function BuildingStatus({ status, salesStart, offerTypes }: IBuildingStatusProps) {
  return (
    <ArticleParagraph2 color="overlay-default">
      {status && <span className={styles['info-block']}>{status}</span>}
      {offerTypes && <span className={styles['info-block']}>{offerTypes}</span>}
      {salesStart && <span className={styles['info-block']}>{salesStart}</span>}
    </ArticleParagraph2>
  );
}
