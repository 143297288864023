import { ESortingType } from '../../types/sorting';
import { ESortValue } from '../../types/jsonQuery';
import { reverseObject } from '../reverseObject';

const SORTING_TO_JSON_QUERY = {
  [ESortingType.Default]: ESortValue.Default,
  [ESortingType.Area]: ESortValue.Area,
  [ESortingType.LandArea]: ESortValue.LandArea,
  [ESortingType.Mkad]: ESortValue.MKAD,
  [ESortingType.Price]: ESortValue.Price,
  [ESortingType.SquarePrice]: ESortValue.SquarePrice,
  [ESortingType.StreetName]: ESortValue.StreetName,
  [ESortingType.WalkingTime]: ESortValue.WalkingTime,
};

const JSON_QUERY_TO_SORTING = reverseObject(SORTING_TO_JSON_QUERY);

export function mapSortingToJsonQuery(sortingType: ESortingType): ESortValue {
  return SORTING_TO_JSON_QUERY[sortingType] || ESortValue.Default;
}

export function mapJsonQueryToSorting(sortValue: ESortValue | null): ESortingType {
  if (sortValue && JSON_QUERY_TO_SORTING[sortValue]) {
    return JSON_QUERY_TO_SORTING[sortValue];
  }

  return ESortingType.Default;
}
