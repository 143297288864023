import { IResponseDataSchema } from '../repositories/search-engine/entities/response/ResponseDataSchema';
import { INewbuildingForMobileSiteSchema } from '../repositories/search-engine/entities/newbuilding/NewbuildingForMobileSiteSchema';
import { IRCs, IRCsById } from '../types/rc/rcs';
import { prepareRC } from './prepareRC';

export const prepareRCs = (data: IResponseDataSchema): IRCs => {
  const byId: IRCsById = {};

  const allIds = data.newbuildings.map((rc: INewbuildingForMobileSiteSchema) => {
    byId[rc.id] = prepareRC(rc);

    return rc.id;
  });

  return {
    byId,
    allIds,
  };
};
