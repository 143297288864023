import * as React from 'react';
import { ESortingType, ISortingOption } from '../../types/sorting';
import { SelectMobileModal } from '@cian/ui-kit/select/components/SelectMobile/SelectMobileModal';
import { SortingButton } from '../SortingButton';
import { TOptionValue } from '@cian/ui-kit';

export interface ISortingSelectProps {
  sortingType: ESortingType;
  options: ISortingOption[];
  areOffersLoading: boolean;
  sortNewbuildings(sortingType: ESortingType): void;
}

export const SortingSelect: React.FC<ISortingSelectProps> = ({
  sortingType,
  sortNewbuildings,
  options,
  areOffersLoading,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleCloseModal = React.useCallback(() => setOpen(false), []);
  const handleOpenModal = React.useCallback(() => setOpen(true), []);

  /* istanbul ignore next */
  const selectedSortingLabel = React.useMemo(
    () => (sortingType !== ESortingType.Default ? options.find(({ key }) => key === sortingType)?.value : undefined),
    [options, sortingType],
  );

  const handleOnChange = React.useCallback(async (value: TOptionValue) => {
    sortNewbuildings(value as ESortingType);
  }, []);

  const sortingOptions = React.useMemo(
    () => options.map(({ value, key }) => ({ label: value, value: key })),
    [options],
  );

  return (
    <>
      <SortingButton onClick={handleOpenModal} text={selectedSortingLabel} disabled={areOffersLoading} />
      <SelectMobileModal
        options={sortingOptions}
        open={open}
        onClose={handleCloseModal}
        value={sortingType}
        onChange={handleOnChange}
        title="Сортировка"
      />
    </>
  );
};
