import { actionGenerator } from '../../utils/actionGenerator';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum EAddFavoritesActionTypes {
  Request = 'ADD_FAVORITE/REQUEST',
  Success = 'ADD_FAVORITE/SUCCESS',
  Failure = 'ADD_FAVORITE/FAILURE',
}

interface IPayload {
  rcId: number;
}

type TAddFavoriteRequest = ITypedReduxAction<EAddFavoritesActionTypes.Request, IPayload>;
type TAddFavoriteSuccess = ITypedReduxAction<EAddFavoritesActionTypes.Success, IPayload>;
type TAddFavoriteFailure = ITypedReduxAction<EAddFavoritesActionTypes.Failure, IPayload>;

export type TAddFavorite = TAddFavoriteRequest | TAddFavoriteSuccess | TAddFavoriteFailure;

export const addFavoriteRequest = actionGenerator<EAddFavoritesActionTypes.Request, IPayload>(
  EAddFavoritesActionTypes.Request,
);

export const addFavoriteSuccess = actionGenerator<EAddFavoritesActionTypes.Success, IPayload>(
  EAddFavoritesActionTypes.Success,
);

export const addFavoriteFailure = actionGenerator<EAddFavoritesActionTypes.Failure, IPayload>(
  EAddFavoritesActionTypes.Failure,
);
