import * as React from 'react';
import { InlineText3 } from '@cian/ui-kit';

import { IconProductHotLeads16 } from '@cian/ui-kit-design-tokens/icons';

import * as styles from './SalesLeader.css';

export function SalesLeader() {
  return (
    <InlineText3 color="text-warning-default" whiteSpace="nowrap">
      <span className={styles['icon']}>
        <IconProductHotLeads16 color="icon-warning-default" />
      </span>
      Лидер продаж
    </InlineText3>
  );
}
