import * as React from 'react';
import { useSelector } from 'react-redux';

import { getIsEnabledConsultantChat } from '../../selectors/consultantChat';
import { useApplicationContext } from '../../utils/applicationContext';
import {
  getFlightBannerUrl,
  getDiscountMortgageBannerEnabled,
  getNewbuildingPromosBannerEnabled,
} from '../../utils/config';

import { FlightBannerContainer } from '../FlightBannerContainer';
import { MortgageSerpBannerContainer } from '../MortgageSerpBanner';
import { NewbuildingConsultBannerContainer } from '../NewbuildingConsultBanner';
import { NewbuildingPromosBannerContainer } from '../NewbuildingPromosBanner';
import { getRandomElement } from './utils';
import { DiscountMortgageBannerContainer } from '../DiscountMortgageBannerContainer';

interface IListingBannerContainerProps {
  position: number;
}

export enum EBannerType {
  NewbuildingPromos = 'newbuildingPromos',
  Mortgage = 'mortgage',
  NewbuildingConsultant = 'newbuildingConsultant',
}

export function ListingBannerContainer({ position }: IListingBannerContainerProps) {
  const [displayBanner, setDisplayBanner] = React.useState<EBannerType>();

  const { config } = useApplicationContext();

  const flightBannerUrl = getFlightBannerUrl(config);
  const isPromosBannerEnabled = getNewbuildingPromosBannerEnabled(config);
  const isDiscountMortgageBannerEnabled = getDiscountMortgageBannerEnabled(config);

  const isChatEnabled = useSelector(getIsEnabledConsultantChat);

  const banners = React.useMemo(
    () =>
      [
        EBannerType.Mortgage,
        isPromosBannerEnabled && EBannerType.NewbuildingPromos,
        isChatEnabled && EBannerType.NewbuildingConsultant,
      ].filter((value): value is EBannerType => !!value),
    [isPromosBannerEnabled, isChatEnabled],
  );

  React.useEffect(() => {
    setDisplayBanner(getRandomElement(banners));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  switch (displayBanner) {
    case EBannerType.NewbuildingConsultant:
      return isDiscountMortgageBannerEnabled ? (
        <DiscountMortgageBannerContainer />
      ) : (
        <NewbuildingConsultBannerContainer />
      );
    case EBannerType.NewbuildingPromos: {
      // Во время флайта заменяем баннер скидок на баннер флайта.
      if (flightBannerUrl) {
        return <FlightBannerContainer url={flightBannerUrl} />;
      }

      return <NewbuildingPromosBannerContainer />;
    }
    case EBannerType.Mortgage:
      return <MortgageSerpBannerContainer position={position} />;
    default:
      return null;
  }
}
