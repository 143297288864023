export enum EErrorNotificationType {
  Sorting = 'sorting',
  SearchApi = 'search-api',
  Pagination = 'pagination',
  Favorites = 'favorites',
}

export interface INotifications {
  errors: EErrorNotificationType[];
}
