import * as React from 'react';
import { IconMapBus16, IconMapWalk16 } from '@cian/ui-kit-design-tokens/icons';

import { ETransportType } from '../../../types/rc/geo';

import * as styles from './styles.css';
import { UIText2 } from '@cian/ui-kit';

interface ITimeToGetProps {
  transportType: ETransportType;
  time: string;
}

export function UndergroundTimeToGet(props: ITimeToGetProps) {
  const { transportType, time } = props;
  const Icon = transportType === ETransportType.Walk ? IconMapWalk16 : IconMapBus16;

  return (
    <>
      <div className={styles['icon']}>
        <Icon />
      </div>

      <UIText2 color="gray60_100" display="inline">
        {time}
      </UIText2>
    </>
  );
}
