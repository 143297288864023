import * as React from 'react';
import classNames from 'clsx';

import { IRCOffers } from '../../types/rc/rcOffers';
import { RCOfferBlock } from './RCOfferBlock';

import * as styles from './styles.css';

interface IRCOffersProps extends IRCOffers {
  onBlockClick(eventLabel: string): void;
  promoColor?: string;
}

export const RCOffers: React.FC<IRCOffersProps> = React.memo(
  ({ flatOffers, totalCount, link, onBlockClick, promoColor }) => {
    return (
      <>
        {flatOffers.map(({ title, priceLimits, count, link, eventLabel }, i) => (
          <a
            href={link}
            className={classNames(styles['container'], styles['rc-offer-container'])}
            onClick={() => onBlockClick(eventLabel)}
            key={title + i}
            target="_blank"
            rel="noreferrer"
          >
            <RCOfferBlock key={title} title={title} subTitle={priceLimits} count={count} promoColor={promoColor} />
          </a>
        ))}

        <a
          href={link}
          className={styles['container']}
          onClick={() => onBlockClick('all-variants')}
          target="_blank"
          rel="noreferrer"
        >
          <RCOfferBlock title="Подходящие квартиры" count={totalCount} promoColor={promoColor} />
        </a>
      </>
    );
  },
);

RCOffers.displayName = 'RCOffers';
