import { ENewbuildingsActionTypes } from '../../actions/newbuildings';
import { EPaginationActionTypes } from '../../actions/pagination';
import { prepareSalesDescription } from '../../mappers';
import { TReduxActions } from '../../types/redux';
import { TSalesDescriptionState } from '../../types/salesDescription';

export function salesDescriptionReducer(
  state: TSalesDescriptionState = null,
  action: TReduxActions,
): TSalesDescriptionState {
  switch (action.type) {
    case EPaginationActionTypes.Success:
    case ENewbuildingsActionTypes.Success:
      return prepareSalesDescription(action.payload);

    default:
      return state;
  }
}
