import { TSubscriberHandler } from '@cian/events-log';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { consumeUser } from '../eventsLogs/consumers';
import { createAuthenticationEventHandler } from '../eventsLogs/handlers';
import { userSelector } from '../selectors/user';
import { IReduxStore } from '../types/redux';

interface IParameters {
  reduxStore: IReduxStore;
}

let unsubscribe: VoidFunction | undefined;

export function useAuthenticationSubscription({ reduxStore }: IParameters) {
  const { isAuthenticated } = useSelector(userSelector);

  React.useEffect(() => {
    if (isAuthenticated) {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = undefined;
      }

      return;
    }

    if (!unsubscribe && !isAuthenticated) {
      const authenticationEventHandler = createAuthenticationEventHandler({ reduxStore });
      unsubscribe = consumeUser(authenticationEventHandler as TSubscriberHandler);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
}
