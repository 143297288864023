import { IAnalyticsUser } from '../../types/analytics';
import { createSelector } from '@reduxjs/toolkit';
import { userSelector } from '../user';

const DEFAULT_USER: IAnalyticsUser = {};

export const getAnalyticsUser = createSelector([userSelector], user => {
  if (!user.isAuthenticated) {
    return DEFAULT_USER;
  }

  return {
    userId: user.userId || undefined,
  };
});
