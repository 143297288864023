import { incrementHeaderFavorites, decrementHeaderFavorites } from './utils/updateHeaderFavorites';
import { TThunkAction } from '../../types/redux';
import { addFavoriteRequest, addFavoriteSuccess, addFavoriteFailure } from './addFavoriteActions';
import { trackAddFavorite } from './analytics';
import { pushErrorNotification } from '../notifications';
import { EErrorNotificationType } from '../../types/notifications';
import { fetchAddFavorite, TAddFavoriteResponse } from '../../repositories/favorites/v1/add-favorite';
import {
  EDealType,
  EEntityType,
} from '../../repositories/favorites/entities/add_favorite/AddFavoriteEntityPublicRequestSchema';

interface IParams {
  rcId: number;
}

export const addFavorite =
  ({ rcId }: IParams): TThunkAction<Promise<void>> =>
  async (dispatch, getState, { logger, httpApi }) => {
    const { rcs } = getState();
    const rc = rcs.byId[rcId];

    dispatch(addFavoriteRequest({ rcId }));
    incrementHeaderFavorites();

    trackAddFavorite(rc.gaLabel);

    try {
      const response: TAddFavoriteResponse = await fetchAddFavorite({
        httpApi,
        parameters: {
          entityId: rc.id,
          dealType: EDealType.Sale,
          entityType: EEntityType.Newbuilding,
        },
      });

      if (response.statusCode === 200 && response.response.isAdded) {
        dispatch(addFavoriteSuccess({ rcId }));

        return;
      }
    } catch (err) {
      logger.error(err, {
        rcId: rc.id,
        domain: 'Serp/shared/actions/favorites#addFavorite()',
      });
    }

    dispatch(pushErrorNotification(EErrorNotificationType.Favorites));
    dispatch(addFavoriteFailure({ rcId }));
    decrementHeaderFavorites();
  };
