import { ISpecialPromoState } from '../../types/specialPromo';

export const defaultSpecialPromoState: ISpecialPromoState = {
  isSpecialPromo: false,
  promoInfo: null,
  specialPromoType: null,
};

export function specialPromoReducer(state: ISpecialPromoState = defaultSpecialPromoState) {
  return state;
}
