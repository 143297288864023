import { ca } from '@cian/analytics';

import { IProduct } from '../../types/analytics';
import { jsonQueryHelper } from '../../utils/jsonQuery/createJsonQuery';

export function trackPhoneButtonClick(label: string, product: IProduct, isTablet?: boolean) {
  const action = isTablet ? 'Open_listing_jk_tablet' : 'Open_listing_jk';

  ca('eventSite', {
    action,
    category: 'Phones',
    label,
    name: 'oldevent',
    products: [product],
    sc: jsonQueryHelper.dump(),
  });
}

export function trackCall(label: string, product: IProduct) {
  ca('eventSite', {
    action: 'Call_listing_jk',
    category: 'Phones',
    label,
    name: 'oldevent',
    products: [product],
    sc: jsonQueryHelper.dump(),
  });
}
