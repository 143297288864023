import * as React from 'react';
import { useSelector } from 'react-redux';
import { parseCookies } from '@cian/utils/lib/shared/cookie';
import { setCookie } from '@cian/utils/lib/browser/cookie';
import { SalesDescription } from '../../components/SalesDescription';
import { getSalesDescription } from '../../selectors/salesDescription';
import { getSalesDescriptionLabel } from './utils';

export function SalesDescriptionContainer() {
  const [isVisible, setVisible] = React.useState(false);

  const description = useSelector(getSalesDescription);

  const cookieKey = description ? `hiddenBanner_${description.salesType}` : null;

  React.useEffect(() => {
    if (cookieKey) {
      const cookies = parseCookies(document.cookie);

      if (!cookies[cookieKey]) {
        setVisible(true);
      }
    }
  }, [cookieKey]);

  const handleClose = React.useCallback(() => {
    setVisible(false);

    setCookie(cookieKey as string, '1', { secure: false });
  }, [cookieKey]);

  if (!isVisible || !description || !description.text) {
    return null;
  }

  return (
    <SalesDescription
      onClose={handleClose}
      text={description.text}
      type={description.salesType}
      label={getSalesDescriptionLabel(description.salesType)}
    />
  );
}
