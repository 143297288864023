import { ca } from '@cian/analytics';
import { jsonQueryHelper } from '../../../utils/jsonQuery/createJsonQuery';

export function trackClickMapButton() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'MAP',
    sc: jsonQueryHelper.dump(),
  });
}
