import { EPageTypes, EPlatformTypes, handleDynamicCalltracking } from '@cian/frontend-newbuilding-call-component';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';

interface IGetDynamicCalltrackingParams {
  siteBlockId?: number | null;
  newbuildingId: number;
  queryString: string;
}

export async function getDynamicCalltrackingPhone(params: IGetDynamicCalltrackingParams): Promise<string | null> {
  if (!params.siteBlockId) {
    return null;
  }

  const response = await handleDynamicCalltracking({
    httpApi: getHttpApi(),
    logger: getLogger(),
    blockId: params.siteBlockId,
    platformType: EPlatformTypes.WebMobile,
    pageType: EPageTypes.NewbuildingList,
    placeType: 'stickyBottom',
    announcementId: params.newbuildingId,
    queryString: params.queryString,
  });

  return response && response.notFormattedPhone ? `+${response.notFormattedPhone}` : null;
}
