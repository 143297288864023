import { fetchConsultantChat } from './fetchConsultantChat';
import { IApplicationContext } from '../../types/application';
import { IConsultantCianChat } from '../../types/consultantCianChat';
import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

export interface IFetchConsultantChatParameters {
  path: string;
}

export async function getConsultantChat(
  context: IApplicationContext,
  { path }: IFetchConsultantChatParameters,
): Promise<IConsultantCianChat | null | undefined> {
  const { logger } = context;

  try {
    const result = await fetchConsultantChat(context, {
      path,
    });

    return result.chat;
  } catch (error) {
    logger.error(error);
    throw new HttpBadStatusCodeError(error);
  }
}
