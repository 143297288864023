import * as React from 'react';
import { ArticleParagraph1 } from '@cian/ui-kit';

import { formatPhone, makePhoneLink } from '../../utils/formatters/phone';
import * as styles from './SpecialPromoFooter.css';

export interface ISpecialPromoFooterProps {
  color?: string;
  linkText?: string;
  linkUrl?: string;
  onLinkClick?(): void;
  onPhoneClick?(): void;
  phone: string;
  workTime?: string;
}

export const SpecialPromoFooter: React.FC<ISpecialPromoFooterProps> = ({
  color,
  linkText,
  linkUrl,
  phone,
  workTime,
  onLinkClick,
  onPhoneClick,
}) => {
  return (
    <div className={styles['footer']} style={{ color }}>
      <a href={makePhoneLink(phone)} className={styles['phone-button']} onClick={onPhoneClick}>
        {formatPhone(phone)}
      </a>
      {workTime && (
        <div className={styles['work-time']}>
          <ArticleParagraph1 color="gray60_100" textAlign="center">
            {workTime}
          </ArticleParagraph1>
        </div>
      )}
      {linkUrl && linkText && (
        <a href={linkUrl} target="_blank" className={styles['link']} onClick={onLinkClick} rel="noopener noreferrer">
          {linkText}
        </a>
      )}
    </div>
  );
};
