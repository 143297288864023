import { INewbuildingForMobileSiteSchema } from '../repositories/search-engine/entities/newbuilding/NewbuildingForMobileSiteSchema';
import { IPhone } from '../types/rc/phone';

export function preparePhones(data: INewbuildingForMobileSiteSchema): IPhone[] {
  const contactsMap = data.contacts.reduce<Map<number, IPhone>>((phonesMap, phone) => {
    if (phone.realtyUserId && !phonesMap.has(phone.realtyUserId)) {
      const { newbuildingDynamicCalltracking } = phone;

      phonesMap.set(phone.realtyUserId, {
        phone: phone.phone,
        name: phone.name,
        realtyUserId: phone.realtyUserId,
        siteBlockId: (newbuildingDynamicCalltracking && newbuildingDynamicCalltracking.siteBlockId) || null,
      });
    }

    return phonesMap;
  }, new Map<number, IPhone>());

  return Array.from(contactsMap.values());
}
