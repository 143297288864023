import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { NewbuildingConsultBanner } from '../../components/NewbuildingConsultBanner';
import { useApplicationContext } from '../../utils/applicationContext';
import { trackBannerClick, trackBannerShow } from './tracking';

const KPN_BANNER_LANDING_LINK_KEY = 'newbuildingConsultant.landingLink';

export function NewbuildingConsultBannerContainer() {
  const { config } = useApplicationContext();
  const landingLink = config.getStrict<string>(KPN_BANNER_LANDING_LINK_KEY);

  return (
    <ActionAfterViewObserver callback={trackBannerShow} percentVisible={0.5} triggerOnce callbackDelay={1000}>
      <NewbuildingConsultBanner onClick={trackBannerClick} url={landingLink} />
    </ActionAfterViewObserver>
  );
}
