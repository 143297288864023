import * as React from 'react';
import { useSelector } from 'react-redux';

import { AboutBuilder } from '../../components/AboutBuilder';
import { Section } from '../../components/Section';
import { getPromoInfo } from '../../selectors/specialPromo';

export function AboutBuilderContainer() {
  const promoInfo = useSelector(getPromoInfo);

  if (!promoInfo || !promoInfo.info) {
    return null;
  }

  return (
    <Section>
      <AboutBuilder title={promoInfo.info.title} content={promoInfo.info.html} />
    </Section>
  );
}
