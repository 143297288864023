import { ITypedReduxAction } from '../../types/redux/actionType';
import { ESortingType } from '../../types/sorting';
import { actionGenerator } from '../../utils/actionGenerator';

export enum ESortingActionTypes {
  SetCurrent = 'SORTING_NEWBUILDINGS/SET_CURRENT',
}

export type TSetCurrentSorting = ITypedReduxAction<ESortingActionTypes.SetCurrent, ESortingType>;

export const setCurrentSorting = actionGenerator<ESortingActionTypes.SetCurrent, ESortingType>(
  ESortingActionTypes.SetCurrent,
);

export type TSortingActionTypes = TSetCurrentSorting;
