import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

export interface IAdfoxCarouselBannerWrapperProps {
  fullWidth?: boolean;
  onClick?(): void;
  children: React.ReactNode;
}

export const AdfoxCarouselBannerWrapper: React.FC<IAdfoxCarouselBannerWrapperProps> = props => {
  const { onClick, fullWidth, children } = props;

  return (
    <li className={cx(styles['container'], fullWidth && styles['container--fullwidth'])} onClick={onClick}>
      {children}
    </li>
  );
};
