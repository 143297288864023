export enum EPaginationStatus {
  Initial,
  Loading,
  Success,
  Failure,
}

export interface IPagination {
  page: number;
  status: EPaginationStatus;
  itemsPerPage: number;
  totalCount: number;
}
